
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocumentPageContainer from '../../Common/DocumentPageContainer'
import styled from 'styled-components'
import CodeOrConductDocument from '../../Document/CodeOrConductDocument'
import { getLocale } from '../../../selectors'
import { getPolicy, normalizeLanguage } from '../../../utils/location'
import { POLICY } from '../../../constants/constants'
import Loading from '../../Common/Loading'
import qs from 'qs'

const DocumentWrapper = styled.div`
  padding-bottom: 100px;
`

class TadaCodeOfConductPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      document: null
    }
  }

  async getCodeOfConductPolicy () {
    const search = qs.parse(this.props.location.search.replace('?', ''))
    const selectedLocale = normalizeLanguage(search.language || this.props.selectedLocale)
    const document = await getPolicy(POLICY.DRIVERS_CODE_OF_CONDUCT, selectedLocale, search.region)

    this.setState({
      document
    })
  }

  async componentDidMount () {
    await this.getCodeOfConductPolicy()
  }

  async componentDidUpdate (prev) {
    if (prev.selectedLocale !== this.props.selectedLocale) {
      this.setState({
        document: ''
      })
      await this.getCodeOfConductPolicy()
    }
  }

  render () {
    return (
      <DocumentPageContainer>
        <DocumentWrapper>
          {!this.state.document ? <Loading /> : <CodeOrConductDocument document={this.state.document} />}
        </DocumentWrapper>
      </DocumentPageContainer>
    )
  }
}

const mapStateToProps = state => ({
  selectedLocale: getLocale(state)
})

export default connect(
  mapStateToProps,
  null
)(TadaCodeOfConductPage)
