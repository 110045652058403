import styled from 'styled-components'

const MvlBtn = styled.a`
  font-weight: bold;
  position: relative;
  display: inline-block;
  height: 44px;
  box-sizing: border-box;
  margin-right: 10px;
  background: ${props => (props.inverse ? 'white' : 'transparent')};
  border: ${props => (props.inverse ? '1px solid #082e3d' : '1px solid white')};
  text-align: center;
  line-height: 44px;
  color: ${props => (props.inverse ? '#082e3d' : 'white')};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: ${props => (props.inverse ? 'rgba(8, 46, 61, 0.1)' : 'rgba(255, 255, 255, 0.2)')};
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`

export default MvlBtn
