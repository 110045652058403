import { combineReducers } from 'redux'

import SettingStateReducer from './modules/settings/SettingState'
import SessionStateReducer from './modules/session/SessionState'
import DriverStateReducer from './modules/driver/DriverState'
import DocumentStateReducer from './modules/driver_document/DriverDocumentState'
import ContactStateReducer from './modules/contact/ContactState'
import HelpCenterReducer from './modules/helpCenter/reducer'
import DeleteAccountReducer from './modules/deleteAccount/reducer'
// ## Generator Reducer Imports

const rootReducer = combineReducers({
  // Counter sample app state. This can be removed in a live application
  // rentals: RentalStateReducer,
  // ## Generator Reducers

  settings: SettingStateReducer,

  driver: DriverStateReducer,
  driverDocument: DocumentStateReducer,
  contact: ContactStateReducer,

  // Navigator states
  session: SessionStateReducer,

  helpCenter: HelpCenterReducer,

  deleteAccount: DeleteAccountReducer
})

export default rootReducer
