import styled from 'styled-components'

import csQuestionIcon from '../../assets/images/cs-question-icon.svg'

const QuestionContainer = styled.div`
  display: flex;
  padding: 0 24px;
  align-items: center;
  gap: 8px;

  > img {
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    margin: auto 0;
  }

  > p {
    margin: 0;
    margin-top: 2px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
`

export function Question({ content }) {
  return (
    <QuestionContainer>
      <img src={csQuestionIcon} alt="question" />
      <p>{content}</p>
    </QuestionContainer>
  )
}
