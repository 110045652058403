import { createSlice } from '@reduxjs/toolkit'

export const helpCenterInitialState = {
  isFetching: false,
  reportInfo: {},
  isStaticTopic: false
}

const helpCenterFailure = {
  prepare: error => ({ payload: error }),
  reducer: state => {
    state.isFetching = false
  }
}

const helpCenterSlice = createSlice({
  name: `helpCenter`,
  initialState: helpCenterInitialState,
  reducers: {
    fetchReportRequest: {
      prepare: data => ({ payload: data }),
      reducer: state => {
        state.isFetching = true
      }
    },
    fetchReportSuccess (state) {
      state.isFetching = false
    },
    fetchReportFailure: helpCenterFailure,

    setReportInfo (state, { payload }) {
      state.reportInfo = payload
    },

    setIsStaticTopic (state, { payload }) {
      state.isStaticTopic = payload
    }
  }
})

export const {
  fetchReportRequest,
  fetchReportSuccess,
  fetchReportFailure,
  setReportInfo,
  setIsStaticTopic
} = helpCenterSlice.actions

export default helpCenterSlice.reducer
