import React, { Component } from 'react'
import styled from 'styled-components'
import A from '../Common/A'
import downloadSprite from '../../assets/images/tada/download.png'
import downloadSprite2x from '../../assets/images/tada/download@2.png'
import downloadApk from '../../assets/images/tada/btn-apkdown.png'
import downloadApk2x from '../../assets/images/tada/btn-apkdown@2x.png'
import autoBind from 'react-autobind'
import { compose } from 'recompose'
import { translate } from 'react-i18next'

export const AppDownloadContainer = styled.div`
  width: 300px;
  height: 106px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1000px) {
    width: 312px;
  }
`

export const AppDownloadTop = styled.div`
  width: 100%;
  line-height: 45px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #0a3142;
`

export const AppDownloadUl = styled.ul`
  display: flex;
  justify-content: space-between;
`

export const AppDownloadLi = styled.li`
  display: inline-flex;
`

export const AppDownloadOutlink = styled(A)`
  position: relative;
  width: 135px;
  height: 40px;
  background-repeat: no-repeat;
  background-image: url(${downloadSprite});
  background-size: 405px 80px;
  background-position: ${props =>
    props.os === 'ios' ? '0' : props.os === 'android' ? '-135px' : props.os === 'huawei' ? '-270px' : ''}
    0;
  &:hover,
  &:active {
    background-position: ${props =>
    props.os === 'ios' ? '0' : props.os === 'android' ? '-135px' : props.os === 'huawei' ? '-270px' : ''} -40px;
  }

  @media (max-width: 1000px) {
    width: 150px;
    height: 43px;
    background-size: 450px 86px;
    background-repeat: no-repeat;
    background-image: url(${downloadSprite2x});
    background-position: ${props =>
    props.os === 'ios' ? '0' : props.os === 'android' ? '-150px' : props.os === 'huawei' ? '-300px' : ''}
      0;
    &:hover,
    &:active {
      background-position: ${props =>
    props.os === 'ios' ? '0' : props.os === 'android' ? '-150px' : props.os === 'huawei' ? '-300px' : ''} -43px;
    }
  }

  &.apk-download {
    background-image: url(${downloadApk});
    background-size: contain;
    background-position: 0 0;

    @media (max-width: 1000px) {
      background-image: url(${downloadApk2x});
      background-position: 0 0;
    }
  }
`

export class AppDownload extends Component {
  constructor (props) {
    super(props)
    autoBind(this)
  }

  alertWaiting (e) {
    const { t, waiting } = this.props
    if (waiting) {
      alert(t('tada.driver.app.waiting')) // eslint-disable-line no-undef
      if (e) {
        e.preventDefault()
      }
    }
  }

  render () {
    const {
      children,
      iosDownloadLink,
      androidDownloadLink,
      huaweiDownloadLink,
      androidApkDownloadLink,
      isDriver
    } = this.props

    return (
      <AppDownloadContainer isDriver={isDriver}>
        <AppDownloadTop>{children}</AppDownloadTop>
        <AppDownloadUl>
          {iosDownloadLink && (
            <AppDownloadLi>
              <AppDownloadOutlink os='ios' href={iosDownloadLink} target='_blank' />
            </AppDownloadLi>
          )}
          {androidDownloadLink && (
            <AppDownloadLi>
              <AppDownloadOutlink os='android' href={androidDownloadLink} target='_blank' />
            </AppDownloadLi>
          )}
          {androidApkDownloadLink && (
            <AppDownloadLi>
              <AppDownloadOutlink className='apk-download' href={androidApkDownloadLink} download />
            </AppDownloadLi>
          )}
        </AppDownloadUl>
        {huaweiDownloadLink ? (
          <AppDownloadUl>
            <AppDownloadLi>
              <AppDownloadOutlink os='huawei' href={huaweiDownloadLink} target='_blank' />
            </AppDownloadLi>
          </AppDownloadUl>
        ) : null}
      </AppDownloadContainer>
    )
  }
}

const enhance = compose(translate())

export default enhance(AppDownload)
