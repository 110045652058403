import React from 'react'
import styled from 'styled-components'
import Spinner from 'react-spinkit'

const LoadingWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const Loading = () => (
  <LoadingWrapper>
    <Spinner name='circle' color='black' fadeIn='none' />
  </LoadingWrapper>
)

export default Loading
