import React, { Component } from 'react'
import DocumentPageContainer from '../../Common/DocumentPageContainer'
import Tab from '../../Tab/index'
import { getPolicy, normalizeLanguage } from '../../../utils/location'
import { POLICY } from '../../../constants/constants'
import Loading from '../../Common/Loading'
import { getLocale } from '../../../selectors'
import { connect } from 'react-redux'
import qs from 'qs'

class TadaTermPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      driverDocument: null,
      riderDocument: null
    }
  }

  async getTermPolicy () {
    const search = qs.parse(this.props.location.search.replace('?', ''))
    const selectedLocale = normalizeLanguage(search.language || this.props.selectedLocale)
    const driverDocument = await getPolicy(POLICY.TADA_TERM_FOR_DRIVER, selectedLocale, search.region)
    const riderDocument = await getPolicy(POLICY.TADA_TERM_FOR_RIDER, selectedLocale, search.region)

    this.setState({
      driverDocument,
      riderDocument
    })
  }

  async componentDidMount () {
    await this.getTermPolicy()
  }

  async componentDidUpdate (prev) {
    if (prev.selectedLocale !== this.props.selectedLocale) {
      this.setState({
        driverDocument: '',
        riderDocument: ''
      })
      await this.getTermPolicy()
    }
  }

  render () {
    const items = [
      {
        title: 'Terms \nfor Drivers\n',
        document: this.state.driverDocument
      },
      {
        title: 'Terms \nfor Riders\n',
        document: this.state.riderDocument
      }
    ]

    return (
      <DocumentPageContainer>
        {!this.state.driverDocument || !this.state.riderDocument ? <Loading /> : <Tab items={items} />}
      </DocumentPageContainer>
    )
  }
}

const mapStateToProps = state => ({
  selectedLocale: getLocale(state)
})

export default connect(
  mapStateToProps,
  null
)(TadaTermPage)
