import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ALL_COUNTRY_CODES from '../../../assets/json/country_data.json'
import ALL_CODE_BY_NAME from '../../../assets/json/names.json'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkAvailableDeleteFailure,
  checkAvailableDeleteRequest,
  checkAvailableDeleteSuccess,
  checkExistsRequest,
  checkExistsSuccess,
  deleteAccountRequest,
  deleteAccountSuccess,
  getUserInfoFailure,
  getUserInfoRequest,
  getUserInfoSuccess,
  requestOTPFailure,
  requestOTPRequest,
  requestOTPSuccess,
  validateOTPFailure,
  validateOTPRequest,
  validateOTPSuccess
} from '../../../modules/deleteAccount/reducer'
import { useActionSubscribe } from '../../../store/middleware/actionSubscribe'
import { phoneSelector, uuidSelector } from '../../../modules/deleteAccount/selector'
import MvlToast from '../../Common/MvlToast'
import { DELETE_ACCOUNT_REASON_MAX_LENGTH, DELETE_ACCOUNT_REASON_MIN_LENGTH, SERVICE_COUNTRY_CODES } from './constants'
import { get } from '../../../utils/api'

const RIDER_COUNTRY_CODES = SERVICE_COUNTRY_CODES.concat(
  Object.entries(ALL_COUNTRY_CODES).reduce((acc, [name, number]) => {
    if (!SERVICE_COUNTRY_CODES.find(country => country.name === name)) {
      const code = Object.entries(ALL_CODE_BY_NAME).find(([_, value]) => value === name)?.[0]

      return acc.concat({
        name: name.slice(0, 13),
        number,
        code
      })
    }

    return acc
  }, [])
)

export const useCountryCode = () => {
  const { userModel } = useParams()

  if (userModel === 'rider') {
    return RIDER_COUNTRY_CODES
  } else if (userModel === 'driver') {
    return SERVICE_COUNTRY_CODES
  }
}

const RECAPTCHA_ACTION_NAME = 'LOGIN'

export const useNumberForm = () => {
  const dispatch = useDispatch()

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [phone, setPhone] = useState({ code: '', number: '' })

  const isValidNumber = new RegExp(/^\+\d{5,}$/).test(`${phone.code}${phone.number}`)

  const handleChangeCode = value => {
    setPhone({ ...phone, code: value })
  }

  const handleChangeNumber = ({ target: { value } }) => {
    const regex = new RegExp(/^\d*$/)
    regex.test(value) && setPhone({ ...phone, number: value })
  }

  const handleClickSendButton = async () => {
    if (!executeRecaptcha) return

    const token = await executeRecaptcha(RECAPTCHA_ACTION_NAME)

    dispatch(
      requestOTPRequest({
        phone,
        brand: 'TADA',
        recaptchaToken: token
      })
    )
  }

  return {
    code: phone.code,
    number: phone.number,
    isValidNumber,
    handleChangeCode,
    handleChangeNumber,
    handleClickSendButton
  }
}

const OTP_REGEX = new RegExp(/^\d{6}$/)

export const useOTPForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { userModel } = useParams()

  const phone = useSelector(phoneSelector)

  const [otp, setOTP] = useState('')
  const [otpThrottleSeconds, setOtpThrottleSeconds] = useState(0)
  const [isValidOTP, setIsValidOTP] = useState(null)
  const second = useCountdown(otpThrottleSeconds)

  const continueButtonDisabled = !(phone !== null && OTP_REGEX.test(otp))

  const handleChangeOTP = ({ target: { value } }) => {
    const regex = new RegExp(/^\d{0,6}$/)
    regex.test(value) && setOTP(value)
  }

  const handleClickContinue = () => {
    dispatch(
      validateOTPRequest({
        phone: `${phone.code}${phone.number}`,
        code: otp
      })
    )
  }

  useActionSubscribe(requestOTPSuccess.type, ({ payload: { otpThrottleSeconds } }) => {
    setOtpThrottleSeconds(otpThrottleSeconds)
  })

  useActionSubscribe(requestOTPFailure.type, ({ payload: { throttleSeconds } }) => {
    setOtpThrottleSeconds(throttleSeconds)
  })

  useActionSubscribe(validateOTPSuccess.type, ({ payload }) => {
    if (payload) {
      dispatch(
        checkExistsRequest({
          identifier: `${phone.code}${phone.number}`,
          type: 'phone',
          userModel: userModel === 'rider' ? 'Rider' : 'Driver'
        })
      )
    }
    setIsValidOTP(payload)
  })

  useActionSubscribe(validateOTPFailure.type, () => {
    setIsValidOTP(false)
  })

  useActionSubscribe(checkExistsSuccess.type, ({ payload }) => {
    if (!payload.exists) {
      MvlToast("The account doesn't exist.", 'error')
    } else {
      dispatch(
        getUserInfoRequest({
          code: otp,
          grant_type: 'otp',
          phone: `${phone.code}${phone.number}`,
          userModel: userModel === 'rider' ? 'Rider' : 'Driver'
        })
      )
    }
  })

  useActionSubscribe(getUserInfoSuccess.type, () => {
    dispatch(checkAvailableDeleteRequest())
  })

  useActionSubscribe(getUserInfoFailure.type, ({ payload }) => {
    MvlToast(payload, 'error')
  })

  useActionSubscribe(checkAvailableDeleteSuccess.type, ({ payload }) => {
    if (payload.isAvailable) {
      history.push('/delete/account/request')
    }
  })

  useActionSubscribe(checkAvailableDeleteFailure.type, () => {
    history.push('/delete/account/error')
  })

  return {
    otp,
    second,
    continueButtonDisabled,
    isValidOTP,
    handleChangeOTP,
    handleClickContinue
  }
}

export const useCountdown = totalSecond => {
  const timeInterval = useRef(null)
  const [second, setSecond] = useState(null)

  useEffect(() => {
    if (totalSecond) {
      setSecond(totalSecond)
      timeInterval.current = setInterval(() => {
        setSecond(prev => prev - 1)
      }, 1000)
    }

    return () => {
      clearInterval(timeInterval.current)
    }
  }, [totalSecond])

  useEffect(() => {
    if (second <= 0) {
      clearInterval(timeInterval.current)
    }
  }, [second])

  return second
}

const REASON_REGEXP = new RegExp(`^.{${DELETE_ACCOUNT_REASON_MIN_LENGTH},${DELETE_ACCOUNT_REASON_MAX_LENGTH}}$`)

export const useDeleteForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const uuid = useSelector(uuidSelector)
  const [reason, setReason] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)

  const isValidReason = REASON_REGEXP.test(reason)

  const handleChangeReason = e => {
    const { value } = e.target
    setReason(value.slice(0, DELETE_ACCOUNT_REASON_MAX_LENGTH))
  }

  const handleOkModal = () => {
    dispatch(
      deleteAccountRequest({
        uuid,
        description: reason
      })
    )
  }

  const toggleModal = () => {
    setIsOpenModal(prev => !prev)
  }

  useActionSubscribe(deleteAccountSuccess.type, () => {
    history.push('/delete/account/success')
  })

  return {
    reason,
    isValidReason,
    isOpenModal,
    handleChangeReason,
    handleOkModal,
    toggleModal
  }
}

export const useRegions = () => {
  const [region, setRegion] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const result = await get(`/ridesvc/v1/regions`)
        setRegion(result.content)
      } catch (err) {
        setRegion(null)
      }
    })()
  }, [])

  return region
}
