import styled from 'styled-components'

const Button = styled.button`
  outline: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
`

export default Button
