import React, { Component } from 'react'
import styled from 'styled-components'
import BrandingPageContainer from '../../Common/BrandingPageContainer'
import titleBackground from '../../../assets/images/tada/banner-bg-movie-copy.png'
import titleBackgroundMobile from '../../../assets/images/tada/m-main-image.png'
// import titleBackgroundMobile from '../../../assets/images/tada/m-main-image@2x.png'
import titleBgVideo from '../../../assets/videos/Background-6266.mp4'
import car1 from '../../../assets/images/tada/car@2x.png'
import car2 from '../../../assets/images/tada/car-copy-2@2x.png'
import car3 from '../../../assets/images/tada/car-copy-3@2x.png'
import like from '../../../assets/images/tada/like@2x.png'
import love from '../../../assets/images/tada/love@2x.png'


import { compose } from 'recompose'
import { translate } from 'react-i18next'
import { Desktop, Mobile } from '../../Common/ByScreen'
import A from '../../Common/A'
import triangle from '../../../assets/images/tada/triangle.png'
import vIconWhite from '../../../assets/images/tada/v_white.png'




export const TadaHomePageTitleContainer = styled.div`
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
  }
`

export const TadaHomePageTitleVideoWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  width: 1380px;
  height: 300px;
  overflow: hidden;
  padding: 0;
  margin: 0 auto;
  z-index: -1;

  @media (max-width: 1380px) {
    width: 950px;
  }
`

export const TadaHomePageTitleVideo = styled.video``

export const TadaHomePageTitle = styled.h1`
  margin: 0 auto;
  padding: 170px 0 0;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  max-width: 3910px;
  width: 100%;
  height: 750px;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  color: #0a3142;
  background-image: url(${titleBackground});
  background-repeat: no-repeat;

  @media (max-width: 1380px) {
    height: 500px;
    padding: 80px 0 0;
  }
`

export const TadaHomePageTitleImg = styled.img`
  @media (max-width: 1000px) {
    width: 100%;
  }
`

export const TadaHomePageContent = styled.div`
  position: relative;
  margin: 250px auto;
  width: 700px;
  white-space: pre-wrap;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.42;
  color: #002c3e;

  @media (max-width: 1000px) {
    margin: 135px auto;
    max-width: 340px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.33;
    color: #002c3e;

    br {
      display: none;
    }

    div > p:last-child br {
      display: block;
    }
  }

  @media (max-width: 390px) {
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }
`

export const TadaHomePageTopCar = styled.div`
  background-image: url(${car1});
  position: absolute;
  background-repeat: no-repeat;
  background-size: 76px 82px;
  left: -100px;
  top: -100px;
  content: ' ';
  width: 76px;
  height: 82px;

  &:before,
  &::before {
    background-image: url(${like});
    background-size: 16px 17px;
    position: absolute;
    width: 16px;
    height: 17px;
    top: -16px;
    left: 30px;
    content: ' ';
  }

  @media (max-width: 1000px) {
    background-image: url(${car3});
    background-size: 68px 60px;
    width: 68px;
    height: 60px;
    left: 23px;
    top: -89px;

    &:before,
    &::before {
      background-image: url(${like});
      background-size: 16px 17px;
      position: absolute;
      width: 16px;
      height: 17px;
      top: -16px;
      left: 30px;
      content: ' ';
    }
  }
`

export const TadaHomePageBottomCar = styled.div`
  background-image: url(${car2});
  background-size: 98px 62px;
  background-repeat: no-repeat;
  position: absolute;
  right: -50px;
  bottom: -100px;
  content: ' ';
  width: 98px;
  height: 62px;

  &:before,
  &::before {
    background-image: url(${love});
    background-size: 15px 14px;
    position: absolute;
    width: 15px;
    height: 14px;
    top: -7px;
    left: 1px;
    content: ' ';
  }

  @media (max-width: 1000px) {
    right: 17px;
    bottom: -92px;
    background-size: 79px 51px;
    width: 79px;
    height: 51px;

    &:before,
    &::before {
      background-image: url(${love});
      background-size: 15px 14px;
      position: absolute;
      width: 15px;
      height: 14px;
      top: -7px;
      left: 1px;
      content: ' ';
    }
  }
`

export const DriveWithTadaDownloadLink = styled(A)`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #0a3142;
  border-radius: 30px;
  border: solid 2px #0a3142;
  &:hover {
    background-color: rgba(0, 0, 0, 0.45);
    border-color: #4a4a4a;
    color: #fff;

    &:after,
    &::after {
      background-image: url(${vIconWhite});
    }
  }
  &:after,
  &::after {
    background-image: url(${triangle});
    content: ' ';
    position: absolute;
    right: 14px;
    top: 20px;
    width: 17px;
    height: 10px;
    transform: rotate(270deg);
  }
  @media (max-width: 1000px) {
    position: absolute;
    border-width: 1px;
    width: 85%;
    left: 8%;
    margin-bottom: 10px;
    top: 500px;

    @media (max-width: 768px) {
      top: 350px;
    }

    @media (max-width: 414px) {
      top: 190px;
    }

    @media (max-width: 375px) {
      top: 160px;
    }

    @media (max-width: 320px) {
      top: 135px;
    }
  }
`

export const RideWithTadaDownloadLink = styled(A)`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #0a3142;
  border-radius: 30px;
  border: solid 2px #0a3142;
  &:hover {
    background-color: rgba(0, 0, 0, 0.45);
    border-color: #4a4a4a;
    color: #fff;
    &:after,
    &::after {
      background-image: url(${vIconWhite});
    }
  }
  &:after,
  &::after {
    background-image: url(${triangle});
    content: ' ';
    position: absolute;
    right: 14px;
    top: 20px;
    width: 17px;
    height: 10px;
    transform: rotate(270deg);
  }
  @media (max-width: 1000px) {
    position: absolute;
    border-width: 1px;
    width: 85%;
    left: 8%;
    margin-bottom: 10px;
    top: 560px;
    @media (max-width: 768px) {
      top: 410px;
    }
    @media (max-width: 414px) {
      top: 250px;
    }
    @media (max-width: 375px) {
      top: 220px;
    }
    @media (max-width: 320px) {
      top: 195px;
    }
  }
`

export class TadaHomePage extends Component {
  render () {
    const { t } = this.props

    return (
      <BrandingPageContainer>
        <TadaHomePageTitleContainer>
          <Desktop>
            <TadaHomePageTitleVideoWrapper>
              <TadaHomePageTitleVideo src={titleBgVideo} type='video/mp4' autoPlay loop muted />
            </TadaHomePageTitleVideoWrapper>
            <TadaHomePageTitle>Zero Commission! Zero Stress!</TadaHomePageTitle>
          </Desktop>
          <Mobile>
            <TadaHomePageTitleImg src={titleBackgroundMobile} alt='titleImage' />
            <DriveWithTadaDownloadLink target='_blank' href={'https://ridehailing.page.link/tada_driver'}>
              {t('tada.driver.driverappdownload')}
            </DriveWithTadaDownloadLink>
            <RideWithTadaDownloadLink target='_blank' href={'https://ridehailing.page.link/tada_rider'}>
              {t('tada.rider.riderappdownload')}
            </RideWithTadaDownloadLink>
          </Mobile>
        </TadaHomePageTitleContainer>
        <TadaHomePageContent>
          <div dangerouslySetInnerHTML={{ __html: t('tada.home') }} />
          <TadaHomePageTopCar />
          <TadaHomePageBottomCar />
        </TadaHomePageContent>
      </BrandingPageContainer>
    )
  }
}

const enhance = compose(translate())

export default enhance(TadaHomePage)
