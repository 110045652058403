import qs from 'qs'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import searchIcon from '../../../../assets/images/icn_search.svg'
import clearIcon from '../../../../assets/images/icn-clear.svg'
import { useSearch } from '../../../../hooks/router'

const FaqSearchContainer = styled.div`
  position: relative;
  display: flex;

  > div {
    display: flex;

    width: 100%;
    margin-bottom: 45px;
    padding: 10px 15px;
    border: 1px solid #979797;
    border-radius: 0;
    background-color: #fff;

    & > input {
      display: flex;
      width: 100%;
      border-radius: 0;
      border: 0;

      &:focus {
        outline: 0;
      }

      &::placeholder {
        font-size: 16px;
        font-weight: 400;
        font-family: Apple SD Gothic Neo;
        line-height: 19.2px;
        color: #9b9b9b;
      }
    }

    & > img {
      cursor: pointer;
    }

    & > .clear-icon {
      margin-right: 10px;
    }
  }

  > ul {
    position: absolute;
    top: 45px;
    left: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #979797;
    border-top: 0;
    z-index: 1;

    > li {
      display: flex;
      width: 100%;
      height: 45px;
      padding: 10px 16px;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid #979797;
      background-color: #fff;
      cursor: pointer;

      &:hover {
        background-color: #e2e2e2;
      }
    }

    > li:last-child {
      border-bottom: 0;
    }
  }
`

function FaqSearch({ inputProps }) {
  const search = useSearch()
  const history = useHistory()
  const [inputValue, setInputValue] = useState(search.keyword || '')
  const [isShowLogs, setIsShowLogs] = useState(false)
  const [searchLogs, setSearchLogs] = useState([])

  const replaceQuestionQuery = keywordData => {
    history.replace({
      search: qs.stringify({ ...search, topicId: null, keyword: keywordData !== undefined ? keywordData : inputValue })
    })
    updateSearchLogs()
    setIsShowLogs(false)
  }

  const updateSearchLogs = () => {
    const logs = [...searchLogs]

    if (logs.includes(inputValue) || !inputValue) {
      return
    }

    // searchLog full 일 때
    if (logs.length === 10) {
      logs.shift()
      logs.push(inputValue)
      setSearchLogs(logs)

      return
    }

    logs.push(inputValue)
    setSearchLogs(logs)
  }

  const handleSearchInput = e => {
    setInputValue(e.target.value)
  }

  const handleKeyDownSearch = e => {
    if (e.keyCode !== 13) {
      return
    }
    replaceQuestionQuery()
    e.preventDefault()
  }

  const handleClickSearch = () => {
    replaceQuestionQuery()
  }

  const handleClickSearchLog = search => {
    setInputValue(search)
    replaceQuestionQuery(search)
    setIsShowLogs(false)
  }

  const handleClickClear = () => {
    setInputValue('')
    replaceQuestionQuery('')
  }

  useEffect(() => {
    const handleClickTarget = e => {
      const target = e.target

      if (!target.closest('.faq-search')) {
        setIsShowLogs(false)
      }
    }

    document.body.addEventListener('click', handleClickTarget)

    return () => document.body.removeEventListener('click', handleClickTarget)
  }, [])

  useEffect(() => {
    if (!search.keyword) {
      setInputValue('')
    }
  }, [search.keyword])

  return (
    <FaqSearchContainer className="faq-search">
      <div>
        <input
          value={inputValue}
          placeholder="Search"
          onChange={handleSearchInput}
          onKeyDown={handleKeyDownSearch}
          onClick={() => setIsShowLogs(true)}
          {...inputProps}
        />
        {inputValue && <img src={clearIcon} className="clear-icon" alt="clear_icn" onClick={handleClickClear} />}
        <img src={searchIcon} alt="search_icn" onClick={handleClickSearch} />
      </div>
      {isShowLogs && !!searchLogs.length && (
        <ul>
          {[...searchLogs].reverse().map((log, idx) => (
            <li key={idx} onClick={() => handleClickSearchLog(log)}>
              {log}
            </li>
          ))}
        </ul>
      )}
    </FaqSearchContainer>
  )
}

export default FaqSearch
