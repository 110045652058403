// !!! Do not edit this file directly.
// !!! 이 파일을 직접 수정하지 말고 sheet 수정 후 script 실행할 것
export default {
  translations: {
    sg_taxi: {
      'ComfortDelgro & Citycab': 'ComfortDelGro & CityCab',
      Premier: 'Premier',
      Prime: 'Prime',
      'SMRT Cab': 'SMRT',
      'Trans-cab': 'Trans-Cab',
      'Yellow-Top': 'Yellow-Top',
      HDT: 'HDT',
    },
    ph_taxi_plate: '出租车车牌号',
    desc_mdt_must: '必须显示出租车 MDT 的照片',
    desc_mdt_name: '司机姓名',
    desc_mdt_plate: '车牌号',
    desc_mdt_date: '最近日期（即照片必须是最近拍的）',
    btn_save_next: '保存并执行下一步',
    btn_later: '稍后',
    repeat_password: '确认密码',
    phone_number: '电话号码',
    digit_pin_number: '4 位 PIN 码',
    refferal_code: '推荐代码',
    singapore: '新加坡',
    country: '国家/地区',
    signup: {
      introduce1: '欢迎注册成为 TADA 司机。请提供以下信息，成为 TADA 的合作司机。请提交所有资料以完成申请。如果您在提交过程中遇到任何问题，请通过',
      email: 'hotro@tada.global',
      introduce2: '联系我们，我们将在 1 个工作日内给您答复。资料可采用图片或 pdf 的格式上传。',
      submit: '提交',
      login_here: '在此登录',
      refferal_code: '推荐代码（可选）',
      pin_number: 'PIN 码',
      mobile_number: '手机号码',
      agree: '我同意',
      my_information: '我的信息',
    },
    driver: {
      available_after_complete: '完成后可用',
      filled_out_zero: '您已填写 0 个（共 {{totalSteps}} 个）字段。',
      my_page: '我的页面',
      application: '司机申请',
      application_taxi: '出租车司机申请',
      application_tuktuk: '嘟嘟车司机申请',
      application_bike: '自行车司机申请',
      application_not_complete: '您的申请未完成',
      filled_out: '您已填写 {{doneSteps}} 个（共 {{totalSteps}} 个）字段。',
      being_reviewed: '您的申请正在审核中',
      information: '个人信息',
      name: '姓名',
      mobile_number: '手机号码',
      refferal_code: '我的推荐代码',
      logout: '退出',
      application_complete: '您的申请已完成。我们给您发了一封确认电子邮件。',
      application_not_succesful: '您的申请未成功。请检查您的电子邮件，并重新提交所述资料。',
    },
    judicial_record: '司法记录',
    vehicle_registration: '车辆登记',
    tuktuk_registration_card: '嘟嘟车登记卡',
    certificate_of_registry: '登记证明书',
    commercial_insurance_certificate: '商业保险凭证',
    contract_car_badge: '合约车标',
    national_id: '身份证号',
    identity_card_number: '身份证号',
    license_plate_number: '车牌号',
    car_make: '汽车品牌',
    car_make_ex: '汽车品牌（如：宝马）',
    car_model_ex: '汽车型号（如：QM3）',
    bike_make: '自行车品牌',
    bike_model: '自行车型号',
    desc_sharp_clear_photo: '必须清晰明确。',
    valid_driving_license: '必须上传有效的驾驶执照（即未过期）。',
    valid_document: '必须上传有效的资料（即未过期）。',
    valid_nric: '必须上传有效的身份证号（即未过期）。',
    valid_registration_card: '必须上传有效的登记卡（即未过期）',
    waring_acra_form: '上传资料的前三页，证明您是个体经营者，并有一家注册公司用于缴纳所得税。',
    waring_bike_form: '车牌号必须与所有资料相符',
    acra_form_skip: '计划租车运营的用户无需执行此步骤。',
    upload_the_backside: '上传含汽车信息的背面。',
    gps_tracking_device: '商用型，带 GPS 跟踪装置。',
    license_plate: '商业保险中的车牌号应与登记的车牌号相符。',
    national_id_uploaded: '必须上传有效的身份证号（即未过期）。',
    upload_driving_license_front: '上传驾驶执照（正面）',
    upload_driving_license_back: '上传驾驶执照（背面）',
    upload_judicial_record: '上传司法记录（正面）',
    upload_vehicle_registration: '上传车辆登记卡（背面）',
    upload_certificate_of_registry: '上传登记证明书（背面）',
    upload_insurance: '上传保险（正面）',
    upload_insurance_certificate: '上传商业保险凭证（背面）',
    upload_nric_front: '上传身份证（正面）',
    upload_nric_back: '上传身份证（背面）',
    upload_file: '上传文件',
    upload_pdvl_front: '上传 PDVL（正面）',
    upload_decal: '私人租车贴纸',
    upload_car_contract_badge: '上传合约车标（正面）',
    upload_national_id: '上传身份证号（正面）',
    upload_identity_card_number_front: '上传身份证号（正面）',
    upload_identity_card_number_back: '上传身份证号（背面）',
    upload_bike_picture_front: '上传自行车全貌图片',
    upload_bike_picture_back: '上传另一张至少显示车牌号的自行车半貌图片',
    uploaded_profile_picture: '上传头像',
    upload_tuktuk_registration_card: '上传嘟嘟车登记卡（正面）',
    upload_profile_picture: '如果跳过这一步，系统会提醒您在开始接受乘车服务之前再次上传头像。',
    no_wearing_of_accessories: '不要佩戴可能遮挡面部特征的饰物（如帽子、太阳镜等）。',
    please_white_background: '请使用白色背景。',
    please_upload_photo: '请上传一张清晰的照片。',
    taxi_license_plate_num: '出租车车牌号',
    section_taxi_details: '出租车详情',
    desc_upload_taxi_front: '上传出租车正面照片',
    desc_upload_mdt: '上传出租车 MDT 的照片',
    reset_password_success: '您帐户 ({{email}}) 的密码已安全更改。',
    change_password: '密码更改',
    forgot_password: '找回密码',
    reset_password: '重置密码',
    error_password_length: '密码长度必须为 6~20 个字符。',
    faq: '常见问题解答',
    login: '登录',
    login_required: '需要登录',
    logout: '退出',
    sign_up: '注册',
    taxi_sign_up: '出租车司机注册',
    my_page: '我的页面',
    email: '电子邮件',
    email_placeholder: '输入电子邮件地址',
    email_used: '电子邮件 {{email}} 已存在',
    password: '密码',
    password_placeholder: '输入您的密码',
    password_confirm: '确认密码',
    password_confirm_placeholder: '再次输入密码',
    new_password_placeholder: '输入新密码',
    verify_mail_title: '检查验证电子邮件',
    verify_mail_desc: '检查收件箱是否有验证电子邮件。({{email}})',
    privacy_policy: '隐私政策',
    terms_and_conditions: '条款和条件',
    button_confirm: '确认',
    button_cancel: '取消',
    locale: {
      en: '英语',
      ko: '한국어',
      zh_hans: '简体中文',
      zh_hant: '繁體中文',
      vi: 'Tiếng Việt',
      km: 'ភាសាខ្មែរ',
      th: 'ภาษาไทย',
      'zh-CN': '简体中文',
      'zh-TW': '繁體中文',
      jp: '日本語',
    },
    btn_save: '保存',
    first_name: '名字',
    last_name: '姓氏',
    phone_placeholder: '电话号码',
    wrong_number_request: '{{driverPhone}} 是错误号码。请输入另一个号码。',
    photo_file_format: '.jpg, .png',
    delete_image: '删除图片',
    upload_image: '上传图片',
    agree_policy_please: '请同意所有的条款和条件。',
    button_resend_email_verify: '重新发送验证电子邮件',
    success_resend_email_verify: '验证电子邮件已发送。',
    optional_brackets: '（可选）',
    error_time_out: '与服务器的连接超时。请重试。',
    error_captcha_not_valid: 'ReCaptcha 无效',
    car_model: '汽车型号',
    car_photo_warn: '汽车正面照片清楚显示车牌号',
    plate_number: '车牌号',
    banking_details: '银行账户详细信息',
    bank_account_holder_name_failed: '请重试。请勿使用特殊字符和数字。',
    bank_account_holder_name: '银行账户持有人姓名',
    bank_select_name: '银行名称',
    bank_account_number: '银行账号',
    bank_branch: '银行分支机构',
    bank_skip_warn: '如果您跳过这一步，当您开始接单并要对收入提现时，系统会再次提醒您提供银行账户详细信息。',
    warning_when_leaving_without_saving: '确定要离开吗？',
    upload_bank_picture: '上传银行对账单照片',
    bank_photo_warn: '请上传银行账户信息（ATM 凭条或银行对账单）照片，显示账户名称和银行账号',
    profile_picture: '头像',
    nric: '身份证',
    driving_license: '驾驶执照',
    acra_bizfile: 'ACRA Bizfile',
    insurance_certificate: '保险凭证',
    error_password_confirm: '密码不匹配',
    phone_used: '该电话号码已被使用',
    send_pin: '发送 PIN 码',
    edit: '编辑',
    tada: {
      home:
        '<p>欢迎来到打车服务的新纪元。欢迎来到TADA。<br></p>\n<p>踏入TADA的新世界 - 在这里，打车服务被重新定义。<br></p>\n<p>司机在没有佣金的情况下蓬勃发展。<br></p>\n<p>乘客享受透明、公平、实惠的价格，随时随地安全出行。<br></p>\n<p>开始一段变革之旅 - 在这里，便利性与公平、可靠性和信任相遇，重新定义您的交通体验。</p>',
      driver: {
        app: {
          name_1: '行程匹配',
          name_2: '上车',
          name_3: '驾驶',
          name_4: '下车和支付',
          waiting: 'iOS 版本的 TADA 司机应用目前正在审核中。我们将确保您能够尽快轻松下载该应用！',
        },
        info: {
          title_1: '首创且唯一:\n司机零佣金',
          content_1: 'TADA 不收取任何佣金。除了借记卡/信用卡交易费外，所有收入都归司机所有。我们将继续秉持这一原则。',
          title_2: '简单司机注册',
          content_2: '为方便起见，我们简化了注册流程。',
          title_3: '高级匹配系统',
          content_3: 'TADA 基于大数据分析司机服务模式，并提供个性化的匹配系统，使更多的乘客可以匹配到司机。',
          title_4: '无缝接单服务',
          content_4: '即将到达上一位乘客的目的地时，会接到下一位乘客的呼叫。这样可以连续零延迟接单。',
          title_5: '轻松访问运营信息',
          content_5: '我们提供了一个仪表板功能，方便您核查信息，如每天和每周的订单历史记录、目前的收入等。',
          title_6: '奖励和激励',
          content_6: '我们提供激励和任务计划，帮助您提高收入。',
        },
        driverappdownload: '司机应用下载',
      },
      rider: {
        app: {
          name_1: '乘车请求！',
          name_2: '司机匹配',
          name_3: '乘车',
          name_4: '支付和反馈',
        },
        info: {
          title_1: '提供乘客\n快速出行',
          content_1: '采用出色的匹配技术，迅速为您安排司机。我们会安排一名最快到达并提供优质服务和安全驾驶的司机。',
          title_2: '更好且实惠的车费',
          content_2: '通过我们的零佣金政策，\n提供经济实惠且可靠的出行服务。',
          title_3: '高峰时段匹配',
          content_3: '无论是高峰时段、雨天还是晴天，\n我们都为您匹配司机。',
          title_4: '服务范围广',
          content_4: '可以选择用现金或借记卡/信用卡支付。选择关联您最常用的借记卡/信用卡，实现无缝支付。',
          title_5: '快捷且简单的支付',
          content_5: '您可以选择用现金或银行卡/信用卡支付。\n您还可以选择绑定您最喜欢的银行卡/信用卡，\n以实现无缝支付。',
          title_6: '审慎评论管理',
          content_6: '您可以给司机留下反馈意见。我们会根据您的反馈意见来管理司机，为您安排可靠的司机。',
        },
        riderappdownload: '乘客应用下载',
      },
      contact: {
        contact_us: '联系我们',
        name: {
          label: '◉ 姓名或昵称（必填）',
          placeholder: '（30 个字符）',
        },
        email: {
          label: '◉ 您的电子邮件地址（必填）',
          example: '（例如：mvler@mvlchain.io）',
          placeholder: {
            name: 'MVLER',
            domain: 'mvlchain.io',
          },
        },
        title: {
          label: '◉ 职位（必填）',
          placeholder: '（30 个字符）',
        },
        content: {
          label: '◉ 说明（必填）',
          placeholder:
            '[注意事项] \\n\\n-最多 2000 个字符\\n-请详细填写\\n-请勿使用污言秽语\\n-请勿上传任何敏感个人信息（ID、密码、联系方式等）\\n-如果我们需要更多信息，我们将与您联系\\n-点击下面的“提交”按钮，即表示您同意发布位置、IP、操作系统等数据 ',
        },
        submit: {
          request: {
            submit: '提交',
            title: '确定要提交吗？',
            desc: '（电子邮件地址：',
            review: '审核',
          },
          confirm: {
            title: '注册已完成',
            desc: '我们将在审核后回复您的询问。',
            confirm: '确认',
          },
        },
      },
    },
    title: {
      about: '关于',
      driver: '司机',
      rider: '乘客',
      contact: '联系方式',
      faq: '常见问题解答',
      powerd_by_mvl: '由 MVL 提供<span>支持</span>',
      terms: '条款',
      privacy: '隐私',
      code_of_contact: '司机行为准则',
    },
    faq_driver_app: '司机应用',
    faq_rider_app: '乘客应用',
    driver_safety_and_accident0_question: '我遇到了事故。',
    driver_safety_and_accident0_answer:
      "<ul>\n<li>立即停车并移至安全区域。</li>\n<li>确保您、乘客和其他相关人员的安全。</li>\n<li>如果有人严重受伤或需要医疗救助，请拨打紧急服务电话。</li>\n<li>如有伤员，请勿移动或转移伤员</li>\n<li>\n<ul>\n收集信息，例如：\n<li>\n事故发生的时间和地点\n</li>\n<li>相关各方的详细信息</li>\n<li>损坏区域和周围的照片。</li>\n</ul>\n</li>\n<li>尽快向有关部门报告事故。</li>\n<li>通过应用报告事故，告知 TADA 事故详情。我们将提供相关帮助。</li>\n</ul>\n<br />\n<div style=\"text-decoration:underline;\">有用信息</div>\n报警：999\n<br /> \n<a href='https://www.police.gov.sg/contact-us' target='_blank'>报警网站</a>\n<br />\n民防部队：995\n<br /> \n<a href='https://www.scdf.gov.sg/home/contact-info' target='_blank'>民防部队网站</a>\n<br />\n<br />\n<br />\n<div style=\"text-decoration:underline;\">安全驾驶须知</div>\n<br />\n<ul>\n<li>遵守所有交通规则。</li>\n<li>开车时要保持警惕和耐心。</li>\n<li>开车时不得使用手机。使用手机之前务必停车。</li>\n<li>避免超速行驶。</li>\n<li>避免疲劳驾驶。必要时休息一下。</li>\n<li>不要酒后驾驶。</li>\n</ul>\n<br />\n安全到达目的地。\n",
    driver_safety_and_accident1_question: '言语和身体虐待乘客',
    driver_safety_and_accident1_answer:
      "每个人都有不顺心的时候。不幸与顾客发生冲突时，避免事态升级，避免与顾客发生攻击性行为。\n<br />\n<br />\n<ul>\n<li>始终保持专业精神。</li>\n<li>缓和事态，集中精力安全到达目的地。</li>\n<li>TADA 社区绝不容忍言语和身体虐待。如果事态变得具攻击性和暴力，不要与顾客正面冲突。</li>\n<li>如果事态升级为身体暴力，请立即与地方当局联系。</li>\n<li>如果发生紧急情况，请立即报警 (999) 或 民防部队 (995)。</li>\n<li>不要拍摄任何未经授权的视频或照片，并上传到社交媒体上。</li>\n<li>行程结束时使用反馈功能向我们的客户服务团队进行报告。TADA 将公平调查所有报告的案件。</li>\n</ul>\n<br />\n<br />\n<div style=\"text-decoration:underline;\">冲突解决技巧</div>\n<br />\n<ul>\n<li>大多数冲突都是因沟通不畅造成的。试着从顾客的角度了解情况，并以积极的态度作出反应。</li>\n<li>面对激动的顾客时，保持冷静和放松的语气</li>\n</ul>\n<br />\n<div style=\"text-decoration:underline;\">有用信息</div>\n报警：999\n<br /> \n<a href='https://www.police.gov.sg/contact-us' target='_blank'>报警网站</a>\n<br />\n民防部队：995\n<br /> \n<a href='https://www.scdf.gov.sg/home/contact-info' target='_blank'>民防部队网站</a>",
    driver_safety_and_accident2_question: '性虐待/性骚扰',
    driver_safety_and_accident2_answer:
      "性虐待和性骚扰在法律上严格禁止。如果您受到顾客骚扰，不要与顾客正面冲突，立即联系相关部门。您也不得在行程结束后因个人原因直接与顾客联系。紧急按钮功能随时可用，以防发生任何紧急情况。\n<br />\n<br />\n 在行程结束后提交一份反馈，包括事故的细节信息，TADA 客户支持团队将与您联系，以便采取后续行动。所有报告都将得到公平的调查\n<br />\n<br />\n<div style=\"text-decoration:underline;\">有用信息</div>\n报警：999\n<br /> \n<a href='https://www.police.gov.sg/contact-us' target='_blank'>报警网站</a>\n<br />\n民防部队：995\n<br /> \n<a href='https://www.scdf.gov.sg/home/contact-info' target='_blank'>民防部队网站</a>",
    driver_safety_and_accident3_question: '汽车故障与保养',
    driver_safety_and_accident3_answer:
      '如果不幸无法安全驾驶，请联系 EMAS (1800-2255 582) 或您的拖车服务车间。取消行程并说明原因，TADA 客户服务团队会跟进。\n<br />\n<br />\nTADA 建议司机每六 (6) 个月进行一次车辆保养。\n<br />\n<br />\n<div style="text-decoration:underline;">为什么定期保养非常有用？</div>\n<br />\n<ul>\n<li>正确保养车辆可以帮助您避免发生意外车辆故障。</li>\n<li>定期保养可提高乘客和您的行车安全。</li>\n<li>保养费用低于大修费用，养成定期保养的习惯会为您节省昂贵的维修费用。</li>\n</ul>',
    driver_safety_and_accident4_question: '新冠肺炎应对措施',
    driver_safety_and_accident4_answer:
      '鉴于新冠肺炎疫情，LTA 发布了一项要求所有合作司机都遵守的建议。\n<br />\n<br />\n<div style="text-decoration:underline;">出行时佩戴口罩</div>\n<br />\n出租车、PHC 司机和乘客必须佩戴口罩。出租车和 PHC 司机应拒绝运载不戴口罩的乘客。\n<br />\n<br />\n居家隔离的乘客。\n<br />\n<br />\n如果出租车或私人租车司机搭载居家隔离人员 (PSHN) 往返出入境检查站、居家隔离地点和指定的新冠肺炎检测点，或者如果司机知道乘客是 PSHN（通过应用预约通知或乘客在乘车时提前告知），司机应采取以下预防措施：\n<br />\n<br />\n<ul>\n<li>在运载此类乘客时，应摇下车窗；且</li>\n<li>在行程结束时对车辆进行清洁和消毒。</li>\n</ul>\n<br />\n<br />\n<div style="text-decoration:underline;">TraceTogether</div>\n<br />\n我们强烈建议出租车司机、PHC 司机和乘客携带 TraceTogether 令牌或在智能手机上打开 TraceTogether 应用，以方便追踪接触者。及早发现与新冠肺炎确诊病例有密切接触的人员，将有助于保护司机、乘客及其亲人，并防止在社区进一步传播。\n<br />\n<br />\n<div style="text-decoration:underline;">健康与安全咨询</div>\n<br />\nLTA 在此提醒出租车及私人租车司机：\n<br />\n<br />\n<ul>\n<li>身体不适，立即就医，不要开车。</li>\n<li>保持良好的个人卫生，避免不必要地触摸脸部。</li>\n<li>运载有流感症状的乘客时，以及每次行程结束后，摇下车窗，促进车内通风。</li>\n<li>经常对车辆进行清洁和消毒。</li>\n<li>运载有流感症状的乘客时，建议乘客戴上口罩。如果乘客没有口罩，为乘客提供口罩（如可以）。</li>\n<li>每天开车前测量体温，其余时间再测量一次。</li>\n</ul>\n',
    rider_safety_and_accident0_question: '我的司机遇到了事故。',
    rider_safety_and_accident0_answer:
      "<ul>\n<li>确保您和相关人员的人身安全。</li>\n<li>如果需要，立即联系紧急服务。</li>\n<li>\n收集信息，例如：\n<ul>\n<li>事故发生的时间和地点</li>\n<li>司机的详细信息</li>\n<li>如有需要，提供证据照片。</li>\n</ul>\n</li>\n<li>如果需要，可以通过司机的汽车保险公司或您的个人保险公司提出索赔。</li>\n</ul>\n<br />\n<br />\n<div style=\"text-decoration:underline;\">有用信息</div>\n报警：999\n<br /> \n<a href='https://www.police.gov.sg/contact-us' target='_blank'>报警网站</a>\n<br />\n民防部队：995\n<br />\n<a href='https://www.scdf.gov.sg/home/contact-info' target='_blank'>民防部队网站</a>\n<br />\n<br />\n<div style=\"text-decoration:underline;\">安全乘车须知</div>\n<br />\n<ul>\n<li>即使坐在后座也要系好安全带。</li>\n<li>确保司机遵守交通规则。</li>\n<li>如果司机没有安全驾驶，请给予提醒。</li>\n<li>向 TADA 报告并反馈所有与安全相关的事故。</li>\n</ul>\n",
    rider_safety_and_accident1_question: '身体/言语虐待',
    rider_safety_and_accident1_answer:
      '司机有义务遵守 TADA 行为准则。若不幸受到我们合作司机的虐待，请立即通知 TADA。\n<br />\n<br />\n<ul>\n<li>TADA 社区绝不容忍言语和身体虐待。如果事态变得具攻击性，避免与司机正面冲突。</li>\n<li>如果事态升级为身体暴力，请立即与地方当局联系。</li>\n<li>如果发生紧急情况，请立即报警 (999) 或 民防部队 (995) 寻求援助</li>\n<li>行程结束时使用反馈功能向我们的客户服务团队进行报告。TADA 将公平调查所有报告的案件。</li>\n</ul>',
    rider_safety_and_accident2_question: '性虐待/性骚扰',
    rider_safety_and_accident2_answer:
      "性虐待和性骚扰在法律上严格禁止。如果您受到司机骚扰，不要与司机正面冲突，立即联系相关部门。紧急按钮功能随时可用，以防发生任何紧急情况。\n<br />\n<br />\n在行程结束后提交一份反馈，包括司机信息和事故细节。TADA 客户支持团队将尽快与您联系，以便采取后续行动。所有报告的案件都将得到公平的调查。\n<br />\n<br />\n<div style=\"text-decoration:underline;\">有用信息</div>\n报警：999\n<br /> \n<a href='https://www.police.gov.sg/contact-us' target='_blank'>报警网站</a>\n<br />\n民防部队：995\n<br /> \n<a href='https://www.scdf.gov.sg/home/contact-info' target='_blank'>民防部队网站</a>",
    rider_safety_and_accident3_question: '不安全驾驶行为',
    rider_safety_and_accident3_answer:
      'TADA 致力于为乘客和司机建立一个安全的网约车社区。如果您发现或遇到 TADA 司机不安全驾驶行为，请与我们联系。\n<br />\n<br />\n如需紧急援助，请与警方 (999) 联系',
    rider_safety_and_accident4_question: '新冠肺炎应对措施',
    rider_safety_and_accident4_answer:
      '出行时佩戴口罩\n<br />\n<br />\nLTA 和 MOH 规定，出租车、PHC 司机和乘客必须戴口罩。\n<br />\n<br />\n上车前\n<br />\n<br />\n如果您发现司机没有戴口罩，可以取消行程。取消后向 TADA 提交反馈。\n<br />\n<br />\n前往目的地途中\n<br />\n<br />\n如果司机途中摘下口罩，请提醒司机重新戴上口罩。立即提交反馈，TADA 客户支持团队将会进行调查。\n<br />\n<br />\n如果您在乘车时遇到任何与健康有关的问题，请通过 support@tada.global 联系 TADA 支持团队。',
    safety_and_accident_title: '安全与事故',
    driver_safety: '司机安全',
    rider_safety: '乘客安全',
    safety: '安全',
    rider_cancellation_table_content:
      '建立一个积极的网约车生态系统需要司机和乘客的共同努力。因此，司机也实行了取消政策，这样分派的司机将对您的预约履行承担更大的责任。',
    th_driver_safety_and_accident0_question: '我遇到了事故。',
    th_driver_safety_and_accident0_answer:
      '<ul><li>立即停车并移至安全区域。</li><li>确保您、乘客和其他相关人员的安全。</li><li>如果有人严重受伤或需要医疗救助，请拨打紧急服务电话。</li><li>如有伤员，请勿移动或转移伤员</li><li>收集信息，例如：<ul><li>事故发生的时间和地点</li><li>相关各方的详细信息</li><li>损坏区域和周围的照片。</li></ul></li><li>尽快向有关部门报告事故。</li><li>通过应用报告事故，告知 TADA 事故详情。我们将提供相关帮助。</li></ul><br /><p>有用信息</p><br /><p>报警：191</p><br /><p>报警网站：<a href="https://www.royalthaipolice.go.th/">https://www.royalthaipolice.go.th/</a></p><br /><p>NIEMS：1669</p><br /><p>NIEMS 网站：<a href="https://www.niems.go.th/1/Home/Main/">https://www.niems.go.th/1/Home/Main/</a></p><br /><p>安全驾驶须知</p><br /><ul><li>遵守所有交通规则。</li><li>开车时要保持警惕和耐心。</li><li>开车时不得使用手机。使用手机之前务必停车。</li><li>避免超速行驶。</li><li>避免疲劳驾驶。必要时休息一下。</li><li>不要酒后驾驶。</li></ul><br /><p>安全到达目的地。</p>',
    th_driver_safety_and_accident1_question: '言语和身体虐待乘客',
    th_driver_safety_and_accident1_answer:
      '<p>每个人都有不顺心的时候。不幸与顾客发生冲突时，避免事态升级，避免与顾客发生攻击性行为。</p><br /><ul><li>始终保持专业精神。</li><li>缓和事态，集中精力安全到达目的地。</li><li>TADA 社区绝不容忍言语和身体虐待。如果事态变得具攻击性和暴力，不要与顾客正面冲突。</li><li>如果事态升级为身体暴力，请立即与地方当局联系。</li><li>如果发生紧急情况，请立即报警 (191) 或 NIEMS (1669)。</li><li>不要拍摄任何未经授权的视频或照片，并上传到社交媒体上。</li><li>行程结束时使用反馈功能向我们的客户服务团队进行报告。TADA 将公平调查所有报告的案件。</li></ul><br /><p>冲突解决技巧</p><br /><ul><li>大多数冲突都是因沟通不畅造成的。试着从顾客的角度了解情况，并以积极的态度作出反应。</li><li>面对激动的顾客时，保持冷静和放松的语气。</li></ul><br /><p>有用信息</p><br /><p>报警：191</p><br /><p>报警网站：<a href="https://www.royalthaipolice.go.th/">https://www.royalthaipolice.go.th/</a></p><br />NIEMS：1669<br /><p>NIEMS 网站：<a href="https://www.niems.go.th/1/Home/Main">https://www.niems.go.th/1/Home/Main/</a></p>',
    th_driver_safety_and_accident2_question: '性虐待/性骚扰',
    th_driver_safety_and_accident2_answer:
      '<p>性虐待和性骚扰在法律上严格禁止。如果您受到顾客骚扰，不要与顾客正面冲突，立即联系相关部门。您也不得在行程结束后因个人原因直接与顾客联系。紧急按钮功能随时可用，以防发生任何紧急情况。</p><br /><p>在行程结束后提交一份反馈，包括事故的细节信息，TADA 客户支持团队将与您联系，以便采取后续行动。所有报告都将得到公平的调查。</p><br /><p>有用信息</p><br /><p>报警：191</p><br /><p>报警网站：<a href="https://www.royalthaipolice.go.th/">https://www.royalthaipolice.go.th/</a></p><br /><p>NIEMS：1669</p><br /><p>NIEMS 网站：<a href="https://www.niems.go.th/1/Home/Main/">https://www.niems.go.th/1/Home/Main/</a></p>',
    th_driver_safety_and_accident3_question: '汽车故障与保养',
    th_driver_safety_and_accident3_answer:
      '<p>如果不幸无法安全驾驶，请联系 HWPD (1193) 或您的拖车服务车间。取消行程并说明原因，TADA 客户服务团队会跟进。</p><br /><p>TADA 建议司机每六 (6) 个月进行一次车辆保养。</p><br /><p>为什么定期保养非常有用？</p><br /><ul><li>正确保养车辆可以帮助您避免发生意外车辆故障。</li><li>定期保养可提高乘客和您的行车安全。</li><li>保养费用低于大修费用，养成定期保养的习惯会为您节省昂贵的维修费用。</li></ul>',
    th_driver_safety_and_accident4_question: '新冠肺炎应对措施',
    th_driver_safety_and_accident4_answer:
      '<p>鉴于新冠肺炎疫情，DLT 发布了一项要求所有合作司机都遵守的建议。</p><br /><p><u>出行时佩戴口罩</u></p><br /><p>出租车、PHC 司机和乘客必须佩戴口罩。出租车和 PHC 司机应建议乘客佩戴防护口罩。</p><br /><p><u>健康与安全咨询</u></p><p>DLT 在此提醒出租车及私人租车司机：</p><br /><ul><li>身体不适，立即就医，不要开车。</li><li>保持良好的个人卫生，避免不必要地触摸脸部。</li><li>运载有流感症状的乘客时，以及每次行程结束后，摇下车窗，促进车内通风。</li><li>经常对车辆进行清洁和消毒。</li><li>运载有流感症状的乘客时，建议乘客戴上口罩。如果乘客没有口罩，为乘客提供口罩（如可以）。</li><li>每天开车前测量体温，其余时间再测量一次。</li></ul>',
    th_rider_safety_and_accident0_question: '我的司机遇到了事故。',
    th_rider_safety_and_accident0_answer:
      '<ul><li>确保您和相关人员的人身安全。</li><li>如果需要，立即联系紧急服务。</li><li>收集信息，例如：<ul><li>事故发生的时间和地点</li><li>司机的详细信息</li><li>如有需要，提供证据照片。</li></ul></li><li>如果需要，可以通过司机的汽车保险公司或您的个人保险公司提出索赔。</li></ul><br /><p>有用信息</p><br /><p>报警：191</p><br /><p>报警网站：<a href="https://www.royalthaipolice.go.th/">https://www.royalthaipolice.go.th/</a></p><br /><p>NIEMS：1669</p><br /><p>NIEMS 网站：<a href="https://www.niems.go.th/1/Home/Main">https://www.niems.go.th/1/Home/Main/</a></p><br /><p>安全乘车须知</p><br /><li>即使坐在后座也要系好安全带。</li><li>确保司机遵守交通规则。</li><li>如果司机没有安全驾驶，请给予提醒。</li><li>向 TADA 报告并反馈所有与安全相关的事故。</li>',
    th_rider_safety_and_accident1_question: '身体/言语虐待',
    th_rider_safety_and_accident1_answer:
      '<p>司机有义务遵守 TADA 行为准则。若不幸受到我们合作司机的虐待，请立即通知 TADA。</p><br /><ul><li>TADA 社区绝不容忍言语和身体虐待。如果事态变得具攻击性，避免与司机正面冲突。</li><li>如果事态升级为身体暴力，请立即与地方当局联系。</li><li>如果发生紧急情况，请立即报警 (191) 或 NIEMS (1669) 寻求援助。</li><li>行程结束时使用反馈功能向我们的客户服务团队进行报告。TADA 将公平调查所有报告的案件。</li></ul>',
    th_rider_safety_and_accident2_question: '性虐待/性骚扰',
    th_rider_safety_and_accident2_answer:
      '<p>性虐待和性骚扰在法律上严格禁止。如果您受到司机骚扰，不要与司机正面冲突，立即联系相关部门。紧急按钮功能随时可用，以防发生任何紧急情况。</p><br /><p>在行程结束后提交一份反馈，包括司机信息和事故细节。TADA 客户支持团队将尽快与您联系，以便采取后续行动。所有报告的案件都将得到公平的调查。</p><br /><p>有用信息</p><br /><p>报警：191</p><br /><p>报警网站：<a href="https://www.royalthaipolice.go.th/">https://www.royalthaipolice.go.th/</a></p><br /><p>NIEMS：1669</p><br /><p>NIEMS 网站：<a href="https://www.niems.go.th/1/Home/Main">https://www.niems.go.th/1/Home/Main/</a></p>',
    th_rider_safety_and_accident3_question: '不安全驾驶行为',
    th_rider_safety_and_accident3_answer:
      '<p>TADA 致力于为乘客和司机建立一个安全的网约车社区。如果您发现或遇到 TADA 司机不安全驾驶行为，请与我们联系。</p><br /><p>如需紧急援助，请与警方 (191) 联系</p>',
    th_rider_safety_and_accident4_question: '新冠肺炎应对措施',
    th_rider_safety_and_accident4_answer:
      '<p>出行时佩戴口罩。</p><br /><p>DLT 和 MOPH 规定，出租车、PHC 司机和乘客必须戴口罩。</p><br /><p>上车前。</p><br /><p>如果您发现司机没有戴口罩，可以取消行程。取消后向 TADA 提交反馈。</p><br /><p>前往目的地途中。</p><br /><p>如果司机途中摘下口罩，请提醒司机重新戴上口罩。立即提交反馈，TADA 客户支持团队将会进行调查。</p><br /><p>如果您在乘车时遇到任何与健康有关的问题，请通过 <a href="mailto:support_th@tada.global">support_th@tada.global</a> 联系 TADA 支持团队。</p>',
    title_otp_request: '请登录您的账户进行验证',
    label_mobile_number: '手机号码',
    label_code_number: '验证码',
    btn_send: '发送',
    btn_continue: '继续',
    error_verification_code: '验证码不正确',
    label_account_deletion: '注销账户',
    label_reason_delete_account: '注销原因',
    hint_min_character: '最少 {{count}} 个字符',
    btn_submit: '提交',
    dialog_account_deletion_desc: '从现在起，您将无法使用 TADA 服务。',
    apply_account_deletion_title: '我们正在删除您的帐户。',
    apply_account_deletion_desc: '帐户删除可能需要几天才能完成。',
    dialog_account_deletion_failure_title: '删除帐户失败',
    content_delete_account_error_balance_remaining: '删除账户前余额必须为零。请在提取余额后再试，或发送邮件至客服中心寻求帮助。',
    content_delete_account_error_exist_rider_penalty_fee:
      '\r\n由于您的账户存在违规行为，我们无法处理您的账户删除请求。请通过电子邮件联系我们的客服团队，我们的团队将协助您完成删除流程。',
    content_delete_account_error_exist_active_ride: '您目前有一个正在进行的行程，只有在完成或取消行程后，您的账户删除流程才能进行处理。',
    label_cs_center: '客户服务中心',
    text_contact_description: '我们业务遍及亚太地区，新加坡、柬埔寨、泰国、越南和香港的办事处随时为您提供服务。您可以从任意地点与我们联系。',
    text_contact_empty_description: '请点击地图上任意地点的标记查看详细信息',
    car_type_economy: '经济型',
    car_type_economy_large: '经济大型',
    car_type_premium: '豪华型',
    car_type_premium_large: '豪华大型',
    car_type_taxi: '标准出租车',
    car_type_taxi_large: '标准大型出租车',
    car_type_premium_taxi: '豪华出租车',
    car_type_premium_taxi_large: '豪华大型出租车',
    car_type_tuktuk: '嘟嘟车',
    car_type_bike: '摩托车',
    car_type_remorque: 'Remorque',
    car_type_delivery_van: '送货车',
    car_type_onion_tuktuk: '电动嘟嘟车',
    car_type_delivery_bike: '送货摩托车',
    product_type_ph: '个人',
    product_type_taxi: '出租车',
    product_type_anytada: 'AnyTADA',
    product_type_ev: 'iONTADA',
    product_seat_count: '{{seat}} 座位',
    economy_cm: '汽车',
    economy_large_cm: 'SUV',
    car_type_bus: '巴士',
    car_type_mini_bus: '小型巴士',
    ride_option_pet_carrier: 'TADA 宠物（宠物包）',
    ride_option_pet_leashed: 'TADA 宠物（牵绳）',
    fare_type_app_metered_fare: '平台计价费',
    fare_type_car_metered_fare: '计价费',
    fare_type_fixed_fare: '固定车费',
    car_type_win_bike: 'Win 摩托车',
    car_type_premium_bike: 'TADA 摩托车 Plus',
    car_junior_type: 'TADA Junior',
    car_junior_large_type: 'TADA Junior Large',
    car_wheelchair_space_type: 'Wheelchair Space',
  },
};
