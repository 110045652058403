import { call, put, select, takeLatest } from 'redux-saga/effects'
import { createActions } from 'reduxsauce'
import _ from 'lodash'

import { clearAuthenticationToken, setAuthenticationToken } from '../../utils/authentication'
import { actionsGenerator } from '../../utils/ReducerUtils'
import { get, post } from '../../utils/api'
import { isAuthed } from '../../selectors'
import { LANGS } from '../../i18n'
import { DocumentActions } from '../driver_document/DriverDocumentState'
import { getConfiguration } from '../../utils/configuration'
import { getStorageLocale } from '../../utils/MyStorage'

// actions
export const { Types: DriverTypes, Creators: DriverActions } = createActions(
  actionsGenerator({
    saveUserInfo: ['params'],
    saveTokens: ['accessToken', 'refreshToken'],
    clearTokens: [],
    checkEmailRequest: ['email'],
    checkPhoneRequest: ['phone'],
    pinNumberRequest: ['phone'],
    verifyPhoneRequest: ['phone', 'code'],
    signUpRequest: [
      'firstName',
      'lastName',
      'email',
      'password',
      'locale',
      'phone',
      'os',
      'pin',
      'referral',
      'driverType',
      'residentialAddress',
      'taxiCompany',
      'regionCode'
    ],
    updateRequest: [
      'firstName',
      'lastName',
      'password',
      'locale',
      'phone',
      'os',
      'pin',
      'residentialAddress',
      'taxiCompany'
    ],
    loginRequest: ['username', 'password', 'regionCode'],
    logoutRequest: [],
    // resendVerifyEmailRequest: ['accessToken'],
    resendVerifyEmailNoAuthRequest: ['email'],
    fetchMeRequest: [],
    fetchTransactionRequest: [],
    forgotPasswordRequest: ['email'],
    checkResetTokenRequest: ['params'],
    resetPasswordRequest: ['password', 'resetToken', 'email'],
    changePasswordRequest: ['password', 'newPassword'],
    changeLocaleRequest: ['locale'],
    subscribeRequest: ['email'],
    bountyCheckRequest: ['category', 'username', 'captcha'],

    riderCheckResetTokenRequest: ['params'],
    riderResetPasswordRequest: ['password', 'resetToken', 'email']
  })
)

// sagas

function * requestCheckEmail ({ email }) {
  try {
    const response = yield call(get, `/drivers/check_login?login=${encodeURIComponent(email)}`)
    yield put(DriverActions.checkEmailSuccess(response))
  } catch (e) {
    yield put(DriverActions.checkEmailFailure(e))
  }
}

function * requestCheckPhone ({ phone }) {
  try {
    const body = {
      phone
    }
    const response = yield call(post, '/drivers/check_phone', body)
    yield put(DriverActions.checkPhoneSuccess(response))
  } catch (e) {
    yield put(DriverActions.checkPhoneFailure(e))
  }
}

function * requestSignUp ({
  firstName,
  lastName,
  email,
  password,
  locale,
  phone,
  os,
  pin,
  referral,
  driverType,
  residentialAddress,
  taxiCompany,
  regionCode
}) {
  const body = {
    firstName,
    lastName,
    email,
    password,
    locale,
    phone,
    os,
    pin,
    type: driverType,
    residentialAddress,
    taxiCompany,
    referralCode: referral,
    regionCode
  }
  try {
    const res = yield call(post, '/drivers', body)

    yield put(DriverActions.signUpSuccess(res))
    // yield put(DriverActions.saveTokens(res.oauth.accessToken, res.oauth.refreshToken));
  } catch (e) {
    yield put(DriverActions.signUpFailure(e))
  }
}

function * requestUpdate ({
  firstName,
  lastName,
  password,
  locale,
  phone,
  os,
  pin,
  residentialAddress,
  taxiCompany,
  regionCode
}) {
  const body = {
    firstName,
    lastName,
    password,
    locale,
    phone,
    os,
    pin,
    residentialAddress,
    taxiCompany,
    regionCode
  }
  try {
    const res = yield call(post, '/driver/me/update', body)

    yield put(DriverActions.updateSuccess(res))
    // yield put(DriverActions.saveTokens(res.oauth.accessToken, res.oauth.refreshToken));
  } catch (e) {
    yield put(DriverActions.updateFailure(e))
  }
}

function * requestLogin ({ username, password, regionCode }) {
  const body = {
    username,
    password,
    grantType: 'password',
    regionCode
  }

  try {
    const res = yield call(post, '/auth/driver_token', body)

    yield put(DriverActions.loginSuccess(res))
    yield put(DriverActions.saveTokens(res.accessToken, res.refreshToken))
  } catch (e) {
    yield put(DriverActions.loginFailure(e))
  }
}

function * requestLogout () {
  try {
    yield put(DriverActions.logoutSuccess())
  } catch (e) {
    yield put(DriverActions.logoutFailure(e))
  }
  yield put(DriverActions.clearTokens())
  yield put(DocumentActions.clearDocument())
}

// function* requestResendVerifyEmail({accessToken}: {accessToken: string}) {
//   try {
//     const body = {
//       accessToken,
//     };
//     const res = yield call(post, '/users/request_verify_email', body);
//
//     yield put(DriverActions.resendVerifyEmailSuccess(res));
//   } catch (e) {
//     yield put(DriverActions.resendVerifyEmailFailure(e));
//   }
// }

function * requestPinNumber ({ phone }) {
  try {
    const body = {
      phone,
      locale: getStorageLocale()
    }
    const res = yield call(post, '/drivers/request_phone_verify', body)

    yield put(DriverActions.pinNumberSuccess(res))
  } catch (e) {
    yield put(DriverActions.pinNumberFailure(e))
  }
}

function * requestVerifyPhone ({ phone, code }) {
  try {
    const body = {
      phone,
      code
    }
    const res = yield call(post, '/drivers/verify_phone', body)

    yield put(DriverActions.verifyPhoneSuccess(res))
  } catch (e) {
    yield put(DriverActions.verifyPhoneFailure(e))
  }
}

function * requestResendVerifyEmailNoAuth ({ email }) {
  try {
    const body = {
      email
    }
    const res = yield call(post, '/users/request_verify_email_noauth', body)

    yield put(DriverActions.resendVerifyEmailNoAuthSuccess(res))
  } catch (e) {
    yield put(DriverActions.resendVerifyEmailNoAuthFailure(e))
  }
}

function * requestFetchMe () {
  try {
    const res = yield call(get, '/driver/me')

    yield put(DriverActions.fetchMeSuccess(res))
  } catch (e) {
    yield put(DriverActions.fetchMeFailure(e))
  }
}

function * requestForgotPassword ({ email }) {
  try {
    const body = {
      email
    }
    const response = yield call(post, '/auth/driver/gen_reset_token', body)
    yield put(DriverActions.forgotPasswordSuccess(response))
  } catch (e) {
    yield put(DriverActions.forgotPasswordFailure(e))
  }
}

function * requestResetPassword ({
  password,
  resetToken,
  email
}) {
  try {
    const body = {
      password,
      resetToken,
      email
    }
    const response = yield call(post, '/auth/driver/reset_password', body)
    yield put(DriverActions.resetPasswordSuccess(response))
    yield put(DriverActions.saveTokens(response.oauth.accessToken, response.oauth.refreshToken))
  } catch (e) {
    yield put(DriverActions.resetPasswordFailure(e))
  }
}

export function * requestCheckResetToken ({ params }) {
  const response = yield call(post, '/auth/driver/check_reset_token', params)
  if (response.ok) {
    yield put(DriverActions.checkResetTokenSuccess(response.data))
  } else {
    yield put(DriverActions.checkResetTokenFailure('signup failed.'))
  }
}

function * requestRiderResetPassword ({
  password,
  resetToken,
  email
}) {
  try {
    const body = {
      password,
      resetToken,
      email
    }
    const response = yield call(post, `${getConfiguration('RIDER_API_ROOT') || ''}/auth/user/reset_password`, body)
    yield put(DriverActions.riderResetPasswordSuccess(response))
  } catch (e) {
    yield put(DriverActions.riderResetPasswordFailure(e))
  }
}

export function * requestRiderCheckResetToken ({ params }) {
  const response = yield call(post, `${getConfiguration('RIDER_API_ROOT') || ''}/auth/user/check_reset_token`, params)
  if (response.ok) {
    yield put(DriverActions.riderCheckResetTokenSuccess(response.data))
  } else {
    yield put(DriverActions.riderCheckResetTokenFailure('signup failed.'))
  }
}

function * requestChangePassword ({ password, newPassword }) {
  try {
    const body = {
      password,
      newPassword
    }
    const response = yield call(post, '/drivers/me/update_password', body)
    yield put(DriverActions.changePasswordSuccess(response))
    yield put(DriverActions.saveTokens(response.oauth.accessToken, response.oauth.refreshToken))
  } catch (e) {
    yield put(DriverActions.changePasswordFailure(e))
  }
}

function * saveTokens ({ accessToken, refreshToken }) {
  try {
    setAuthenticationToken({
      accessToken,
      refreshToken
    })
  } catch (e) {
    // TODO: handle error
    throw e
  }
}

function * clearTokens () {
  try {
    clearAuthenticationToken()
  } catch (e) {
    // TODO: handle error
    throw e
  }
}

function * requestChangeLocale ({ locale }) {
  if (_.includes(LANGS, locale)) {
    try {
      const authenticated = yield select(isAuthed)
      if (authenticated) {
        const response = yield call(post, '/driver/me/update', { locale })
        yield put(DriverActions.changeLocaleSuccess(response))
      } else {
        yield put(DriverActions.changeLocaleSuccess({ locale }))
      }
    } catch (e) {
      yield put(DriverActions.changeLocaleFailure('change locale failed.'))
    }
  }
}

// place functions before yielding watch
export const sagas = [
  takeLatest(DriverTypes.SAVE_TOKENS, saveTokens),
  takeLatest(DriverTypes.CLEAR_TOKENS, clearTokens),
  takeLatest(DriverTypes.CHECK_EMAIL_REQUEST, requestCheckEmail),
  takeLatest(DriverTypes.SIGN_UP_REQUEST, requestSignUp),
  takeLatest(DriverTypes.UPDATE_REQUEST, requestUpdate),
  takeLatest(DriverTypes.LOGIN_REQUEST, requestLogin),
  takeLatest(DriverTypes.LOGOUT_REQUEST, requestLogout),
  // takeLatest(DriverTypes.RESEND_VERIFY_EMAIL_REQUEST, requestResendVerifyEmail),
  takeLatest(DriverTypes.RESEND_VERIFY_EMAIL_NO_AUTH_REQUEST, requestResendVerifyEmailNoAuth),
  takeLatest(DriverTypes.FETCH_ME_REQUEST, requestFetchMe),
  takeLatest(DriverTypes.FORGOT_PASSWORD_REQUEST, requestForgotPassword),
  takeLatest(DriverTypes.CHECK_RESET_TOKEN_REQUEST, requestCheckResetToken),
  takeLatest(DriverTypes.RESET_PASSWORD_REQUEST, requestResetPassword),
  takeLatest(DriverTypes.CHANGE_PASSWORD_REQUEST, requestChangePassword),
  takeLatest(DriverTypes.PIN_NUMBER_REQUEST, requestPinNumber),
  takeLatest(DriverTypes.VERIFY_PHONE_REQUEST, requestVerifyPhone),
  takeLatest(DriverTypes.CHECK_PHONE_REQUEST, requestCheckPhone),
  takeLatest(DriverTypes.CHANGE_LOCALE_REQUEST, requestChangeLocale),

  takeLatest(DriverTypes.RIDER_RESET_PASSWORD_REQUEST, requestRiderResetPassword),
  takeLatest(DriverTypes.RIDER_CHECK_RESET_TOKEN_REQUEST, requestRiderCheckResetToken)
]

const initialState = {
  isAuthed: false,
  isAuthenticating: false,
  email: null,
  createdAt: null,
  os: null,
  firstName: null,
  lastName: null,
  regionCode: null,
  phone: null,
  referralCode: null,
  locale: null
}

export default function DriverStateReducer (state = initialState, action = {}) {
  switch (action.type) {
    case DriverTypes.SAVE_USER_INFO:
      return {
        ...state,
        email: action.params.email,
        createdAt: action.params.createdAt,
        walletFixed: action.params.walletFixed
      }
    // case DriverTypes.LOGIN_REQUEST:
    case DriverTypes.SIGN_UP_REQUEST:
    case DriverTypes.UPDATE_REQUEST:
    case DriverTypes.FETCH_ME_REQUEST:
      return {
        ...state,
        isAuthenticating: true
      }
    case DriverTypes.UPDATE_SUCCESS:
    case DriverTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        createdAt: action.payload.createdAt,
        phone: action.payload.phone,
        referralCode: action.payload.referralCode,
        os: action.payload.os,
        isAuthenticating: false,
        type: action.payload.type,
        residentialAddress: action.payload.residentialAddress,
        taxiCompany: action.payload.taxiCompany,
        regionCode: action.payload.regionCode
      }

    case DriverTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthed: true
      }

    case DriverTypes.FETCH_ME_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthed: true,
        locale: action.payload.locale,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        createdAt: action.payload.createdAt,
        phone: action.payload.phone,
        os: action.payload.os,
        referralCode: action.payload.referralCode,
        type: action.payload.type,
        residentialAddress: action.payload.residentialAddress,
        taxiCompany: action.payload.taxiCompany,
        regionCode: action.payload.regionCode
      }

    case DriverTypes.LOGOUT_REQUEST:
    case DriverTypes.LOGOUT_SUCCESS:
    case DriverTypes.LOGIN_FAILURE:
    case DriverTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        ...initialState
      }

    default:
      return state
  }
}
