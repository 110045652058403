import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import MobileWebViewContainer from '../../Common/MobileWebViewContainer'
import Paginator from './Paginator'
import Slider from 'react-slick'
import RideProductItem from './RideProductItem'
import { useSearch } from '../../../hooks/router'
import { useDispatch } from 'react-redux'
import { DriverActions } from '../../../modules/driver/DriverState'
import { LANGS } from '../../../i18n'
import { getInformationList } from './utils'
import './index.css'

const RideProductBody = styled.div`
  box-sizing: border-box;
  height: 100vh;
  padding-bottom: 56px;
  overflow: hidden;

  & * {
    box-sizing: border-box;
  }
  .tab-carousel {
    height: calc(100vh - 56px);
  }
  .car_info_wrap {
    height: calc(100vh - 56px);
    overflow-y: scroll;
    width: 100vw;
  }

  .ride_product__car_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .ride_product__car_info > .ride_product__car_info_image {
    margin-top: 16px;
    width: 88px;
  }

  .ride_product__car_info > .ride_product__car_info_marketing_image {
    width: 100%;
  }

  .ride_product__car_info > .ride_product__car_info_car_type {
    margin: 0;
    margin-top: 16px;
    font-size: 20px;
  }

  .ride_product__car_info > .ride_product__car_info_person {
    margin: 0;
    margin-top: 4px;
    font-size: 12px;
    color: #747678;
  }

  .ride_product__car_info > .ride_product__car_info_category {
    display: flex;
    align-items: flex-start;
    margin-top: 32px;
    padding: 0px 8px;
    border-radius: 100px;
    background: #0c0c0c80;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  }

  .ride_product__description {
    margin-top: 24px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    overflow: hidden;
  }

  .ride_product__paginator {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 16px;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2), 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    background: #fff;
  }

  .ride_product__paginator > .ride_product__paginator_button {
    background: none;
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
  }

  .ride_product__paginator > .ride_product__paginator_button.hidden {
    visibility: hidden;
  }

  .ride_product__paginator > .ride_product__paginator_button > img {
    width: 100%;
    height: 100%;
  }
`

function RideProduct() {
  const dispatch = useDispatch()

  const search = useSearch()
  const slideRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(null)
  const [items, setItems] = useState([])
  const [options, setOptions] = useState({})

  const handleAfterChange = index => {
    setCurrentIndex(index)
  }

  useEffect(
    () => {
      ;(async () => {
        try {
          const { codes, targetCode, language = 'en' } = search

          const codeArray = JSON.parse(codes)

          const itemList = await getInformationList(codeArray, language)

          const index = itemList.findIndex(item => String(item.code) === String(targetCode))
          const currentIndex = index === -1 ? 0 : index

          setOptions({
            dots: false,
            infinite: false,
            arrows: false,
            accessibility: false,
            centerPadding: 0,
            className: 'tab-carousel',
            initialSlide: currentIndex,
            afterChange: handleAfterChange
          })

          setItems(itemList)
          setCurrentIndex(currentIndex)
        } catch (e) {}
      })()
    },
    [search]
  )

  useEffect(
    () => {
      const { language } = search
      dispatch(DriverActions.changeLocaleRequest(LANGS.includes(language) ? language : 'en'))
    },
    [search.language]
  )

  if (!Object.keys(options).length || !items.length) {
    return null
  }

  return (
    <MobileWebViewContainer isNavigator={false} headerSize={0} isLoading={false}>
      <RideProductBody>
        <Slider {...options} ref={slideRef}>
          {items.map((item, index) => (
            <RideProductItem item={item} key={item.id} index={index} currentIndex={currentIndex} />
          ))}
        </Slider>
        <Paginator slideRef={slideRef} currentIndex={currentIndex} items={items} />
      </RideProductBody>
    </MobileWebViewContainer>
  )
}

export default RideProduct
