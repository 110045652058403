import React, { Component } from 'react'
import styled from 'styled-components'

const ArticleOuter = styled.div`
  padding-top: 210px;
  width: 100%;
  background-color: #f7f7f7;

  @media (max-width: 1000px) {
    padding-top: 105px;
    width: auto;
    overflow: hidden;
  }
`

const ArticleInner = styled.article`
  width: 1060px;
  min-height: 500px;
  padding: 0;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: auto;
    margin: 0 18px;
  }

  @media (max-width: 1260px) and (min-width: 1001px) {
    width: 950px;
  }
`

export default class Article extends Component {
  render() {
    const { children } = this.props

    return (
      <ArticleOuter>
        <ArticleInner>{children}</ArticleInner>
      </ArticleOuter>
    )
  }
}
