import DeleteAccountCol from './parts/DeleteAccountCol'
import DeleteAccountContainer from './parts/DeleteAccountContainer'
import DeleteAccountInput from './parts/DeleteAccountInput'
import DeleteAccountRow from './parts/DeleteAccountRow'
import DeleteAccountButton from './parts/DeleteAccountButton'
import { useDeleteForm } from './hooks'
import { DELETE_ACCOUNT_REASON_MAX_LENGTH, DELETE_ACCOUNT_REASON_MIN_LENGTH } from './constants'
import MobileModal from '../../Common/MobileModal'
import { mobileCheck } from '../../../utils/MyUtils'
import { translate } from 'react-i18next'

function DeleteAccountRequest({ t }) {
  const { reason, isOpenModal, isValidReason, handleChangeReason, handleOkModal, toggleModal } = useDeleteForm()

  return (
    <DeleteAccountContainer title={t('label_account_deletion')} showNavigation>
      <MobileModal
        width={mobileCheck() ? '90%' : '328px'}
        isOpen={isOpenModal}
        title={t('label_account_deletion')}
        content={t('dialog_account_deletion_desc')}
        cancelText={t('button_cancel')}
        okText={t('btn_submit')}
        toggle={toggleModal}
        onOk={handleOkModal}
      />
      <DeleteAccountRow label={t('label_reason_delete_account')}>
        <DeleteAccountCol subLabel={`${reason.length}/${DELETE_ACCOUNT_REASON_MAX_LENGTH}`} grow="1">
          <DeleteAccountInput
            value={reason}
            onChange={handleChangeReason}
            placeholder={t('hint_min_character', { count: DELETE_ACCOUNT_REASON_MIN_LENGTH })}
          />
        </DeleteAccountCol>
      </DeleteAccountRow>
      <DeleteAccountRow>
        <DeleteAccountButton disabled={!isValidReason} onClick={toggleModal}>
          {t('btn_submit')}
        </DeleteAccountButton>
      </DeleteAccountRow>
    </DeleteAccountContainer>
  )
}

export default translate()(DeleteAccountRequest)
