import { APP_AUTHORITY, REGION } from '../../../constants/constants'

export const getRegionFromLocale = locale => {
  switch (locale) {
    case 'en':
      return REGION.SG
    case 'vi':
      return REGION.VN
    case 'km':
      return REGION.KH
    case 'ko':
      return REGION.KR
    case 'th':
      return REGION.TH
    default:
      return REGION.SG
  }
}

export const getAppAuthorityFromSection = section =>
  section === 'rider' ? APP_AUTHORITY.RIDER_APP : APP_AUTHORITY.DRIVER_APP

export const getLocaleContent = (data, locale) => {
  if (!data || !Array.isArray(data.contents)) {
    return {}
  }

  const { contents } = data

  return (
    contents.find(content => content.locale === locale) ||
    contents.find(content => content.locale === 'en') ||
    contents[0]
  )
}
