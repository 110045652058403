import React from 'react'
import LeftIcon from '../../../assets/images/RideProduct/left-circle-black.svg'
import RightIcon from '../../../assets/images/RideProduct/right-circle-black.svg'

function Paginator({ slideRef, currentIndex, items }) {
  const handleClickPrev = () => {
    currentIndex > 0 && slideRef.current.slickGoTo(currentIndex - 1)
  }

  const handleClickNext = () => {
    currentIndex < items.length && slideRef.current.slickGoTo(currentIndex + 1)
  }
  return (
    <div className="ride_product__paginator">
      <button
        className={`ride_product__paginator_button ${currentIndex === 0 ? 'hidden' : ''}`}
        onClick={handleClickPrev}
      >
        <img src={LeftIcon} />
      </button>
      <p className="ride_product__paginator_navigation">
        {currentIndex + 1} / {items.length}
      </p>
      <button
        className={`ride_product__paginator_button ${items.length - 1 <= currentIndex ? 'hidden' : ''}`}
        onClick={handleClickNext}
      >
        <img src={RightIcon} />
      </button>
    </div>
  )
}

export default Paginator
