import { useRef } from 'react'

import _ from 'lodash'
import qs from 'qs'
import { useLocation } from 'react-router'

const initialOptions = {
  ignoreParseFloat: false
}

export function useSearch ({ ignoreParseFloat } = initialOptions) {
  const location = useLocation()
  const ref = useRef()

  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  const search = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    decoder (value) {
      if (/^-?\d+(\.\d+)?$/.test(value) && ignoreParseFloat === false) {
        return parseFloat(value)
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined: undefined
      }
      if (value in keywords) {
        return keywords[value]
      }

      return decodeURIComponent(value)
    }
  })

  if (!_.isEqual(search, ref.current)) {
    ref.current = search
  }

  return ref.current
}
