import React from 'react'
import { POLYGON_MESSAGE_BY_REGION, SUPPORT_REGION_POLYGON_PATHS } from '../../../../constants/constants'
import { PolygonPath as Path } from './PolygonPath'
import styled from 'styled-components'
import { getTopVertex, checkIOS, transformPathDToCoordinate } from '../../../../utils/MyUtils'
import { MessageArrow } from '../../../icon'
import { ALL_POLYGON_PATHS } from '../../../../constants/paths'

const PolygonMapContainer = styled.div`
  width: 576px;
  height: 663px;
  overflow: hidden;
  background: radial-gradient(50% 50% at 50% 50%, rgba(180, 191, 196, 0.6) 0%, rgba(209, 216, 218, 0) 100%);

  @media (max-width: 1000px) {
    width: 100vw;
    height: fit-content;
    margin: -25px 0 0 -18px;
  }
  > svg {
    ${({ $isIOS }) =>
      $isIOS
        ? `
      width: 576px;
      height: 663px;
    `
        : ''}
    scale: calc(1.5);

    @media (min-width: 1001px) {
      width: 576px;
      height: 663px;
      scale: calc(1);
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.5);
      }
    }
  }
`

const PathPolygon = styled(Path)`
  fill-opacity: 0.4;
  cursor: pointer;

  &:hover,
  &.selected {
    fill-opacity: 1;

    ~ foreignObject#${({ $region }) => `polygon-message-${$region}`} {
      display: block;
      width: 1px;
      height: 1px;
    }
  }
`

const ForeignObject = styled.foreignObject`
  display: none;
  overflow: unset;
`

const PolygonMessage = styled.div`
  position: relative;
  display: flex;
  width: 123px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #0a3142;
  box-shadow: 0px 4px 10px 0px rgba(10, 49, 66, 0.3);
  font-size: 14px;
  font-weight: 700;
  color: #000;
  background: #fff;
  transform: translate(-50%, calc(-100% - 12px));
  z-index: 10;

  > svg {
    position: absolute;
    bottom: -14px;
    left: 50%;
    width: 24px;
    height: 24px;
    transform: translateX(-50%);
  }

  > img {
    width: 20px;
    height: 20px;
    margin-right: 3px;
  }
`

export function ContactPolygonMap({ regionId, onChangeRegion }) {
  const isIOS = checkIOS()

  return (
    <PolygonMapContainer $isIOS={isIOS}>
      <svg viewBox="0 0 576 663" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_2975_3878)">
          {ALL_POLYGON_PATHS.map((path, pathIdx) => {
            const supportPolygon = SUPPORT_REGION_POLYGON_PATHS.find(poly => poly.pathIdx === pathIdx)
            return supportPolygon ? (
              <PathPolygon
                d={path}
                fill="#0B3143"
                onClick={() => onChangeRegion(supportPolygon.regionIdx)}
                className={regionId === supportPolygon.regionIdx ? 'selected' : ''}
                $region={supportPolygon?.region}
              />
            ) : (
              <Path d={path} key={pathIdx} />
            )
          })}
          {SUPPORT_REGION_POLYGON_PATHS.map(poly => {
            const topVertex = getTopVertex(transformPathDToCoordinate(ALL_POLYGON_PATHS[poly.pathIdx]))

            return (
              <ForeignObject
                id={`polygon-message-${poly.region}`}
                x={topVertex?.at(0)}
                y={topVertex?.at(1)}
                key={poly.pathIdx}
              >
                <PolygonMessage className="polygon-message">
                  {POLYGON_MESSAGE_BY_REGION[poly.region]}
                  <MessageArrow />
                </PolygonMessage>
              </ForeignObject>
            )
          })}
        </g>
        <defs>
          <filter
            id="filter0_d_2975_3878"
            x="-4.125"
            y="-9.1443"
            width="591.25"
            height="794.148"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="3" dy="3" />
            <feGaussianBlur stdDeviation="6" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.192157 0 0 0 0 0.258824 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2975_3878" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2975_3878" result="shape" />
          </filter>
        </defs>
      </svg>
    </PolygonMapContainer>
  )
}
