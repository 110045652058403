
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import actions from '../actions'

class Logout extends Component {
  componentDidMount () {
    this.props
      .logoutRequest()
      .then(() => {
        this.props.history.replace('/driver_login')
      })
      .catch(() => {
        this.props.history.replace('/driver_login')
      })
  }

  render () {
    return null
  }
}

export default withRouter(
  connect(
    null,
    actions
  )(Logout)
)
