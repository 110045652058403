import { useHistory } from 'react-router-dom'
import arrowBack from './assets/arrow_back.svg'

function DeleteAccountNavigation() {
  const history = useHistory()

  const handleClickBack = () => {
    history.goBack()
  }
  return (
    <div className="delete_account__header">
      <img className="delete_account__header_button" src={arrowBack} alt="Back image" onClick={handleClickBack} />
    </div>
  )
}

export default DeleteAccountNavigation
