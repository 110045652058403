import React from 'react'
import { translate } from 'react-i18next'
import styled from 'styled-components'
import Button from './Button'

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  > .mobile_modal__cover {
    width: 100%;
    height: 100%;

    background: #0c0c0c;
    opacity: 50%;
  }
`

const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${({ width }) => width};
  transform: translateX(-50%) translateY(-50%);
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0px 4px 16px rgba(0, 0, 0, 0.14);
  background: #fff;

  > .mobile_modal_body {
    padding: 16px;
    padding-top: 32px;

    > .mobile_modal_body_title {
      margin: 0;
      font-size: 24px;
    }
    > .mobile_modal_body_content {
      margin: 0;
      margin-top: 24px;
      font-size: 18px;
      white-space: pre-wrap;
    }
  }

  > .mobile_modal_footer {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;

    > button {
      flex: 1;
      height: 48px;
      font-size: 16px;
      color: #000;
      border-radius: 6px;
    }

    > .mobile_modal_footer_cancel {
      background: #edeff1;
    }

    > .mobile_modal_footer_submit {
      background: linear-gradient(135deg, #ffd74f 0%, #ffc400 100%);
    }
  }
`

function MobileModal({
  isOpen,
  isCancelVisible = true,
  title,
  content,
  cancelText = '',
  okText = '',
  width = '90%',
  toggle = () => {},
  onOk = () => {},
  t
}) {
  const handleClickCover = () => {
    isCancelVisible && toggle()
  }

  return (
    <ModalContainer isOpen={isOpen}>
      <div className="mobile_modal__cover" onClick={handleClickCover} />
      <ModalBox width={width}>
        <div className="mobile_modal_body">
          <h3 className="mobile_modal_body_title">{title}</h3>
          <p className="mobile_modal_body_content">{content}</p>
        </div>
        <div className="mobile_modal_footer">
          {isCancelVisible && (
            <Button className="mobile_modal_footer_cancel" onClick={toggle}>
              {cancelText || t('button_cancel')}
            </Button>
          )}
          <Button className="mobile_modal_footer_submit" onClick={onOk}>
            {okText || t('report.button_ok')}
          </Button>
        </div>
      </ModalBox>
    </ModalContainer>
  )
}

export default translate()(MobileModal)
