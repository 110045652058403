import React from 'react'
import { SliderArrow } from './icon'
import styled from 'styled-components'

const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  min-height: 64px;
  padding: 2px 6px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);

  > .slice-arrow-left,
  .slice-arrow-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    cursor: pointer;

    &:hover {
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.2);
    }

    > svg {
      width: 16px;
      height: 16px;
    }
  }

  > .slice-arrow-left {
    transform: rotate(180deg);
  }

  > .slice-blobs {
    display: flex;
    gap: 6px;
  }
`

const SliderItem = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ $active }) => ($active ? '#fff' : 'rgba(255, 255, 255, 0.30)')};
  cursor: pointer;
`

function Slider({ total, current, onChange }) {
  return (
    <SliderContainer className="dotted-slice">
      <div
        className="slice-arrow-left"
        onClick={() => {
          const dot = current === null ? 0 : (current === 0 ? total : current) - 1
          onChange(dot)
        }}
        tabIndex={0}
      >
        <SliderArrow />
      </div>
      <ul className="slice-blobs">
        {new Array(total).fill('').map((_, idx) => (
          <SliderItem
            key={idx}
            tabIndex={0}
            onClick={current === idx ? undefined : () => onChange(idx)}
            $active={current === idx}
          />
        ))}
      </ul>
      <div
        className="slice-arrow-right"
        onClick={() => {
          const dot = current === null ? 0 : current === total - 1 ? 0 : current + 1
          onChange(dot)
        }}
        tabIndex={0}
      >
        <SliderArrow />
      </div>
    </SliderContainer>
  )
}

export default Slider
