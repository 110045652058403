import React, { Component } from 'react'
import Remarkable from 'remarkable'
import styled from 'styled-components'

export const CommunityGuidelineDocumentContainer = styled.div`
  & h2 {
    font-size: 23px;
    line-height: 24px;

    @media (max-width: 1000px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  h3,
  h4,
  p,
  ul,
  ol {
    font-size: 14px;
    line-height: 24px;

    @media (max-width: 1000px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  & ul,
  & ol {
    counter-increment: list;
    font-size: 17px;
    & h3 {
      font-size: 17px;
      font-weight: normal;
    }
    & ol {
      font-size: 14px;
    }
  }
`

export default class CommunityGuidelineDocument extends Component {
  state = {
    value: ''
  }

  constructor (props) {
    super(props)
    const { document } = this.props
    fetch(document) // eslint-disable-line no-undef
      .then(res => {
        return res.text()
      })
      .then(text => {
        this.setState({
          value: text
        })
      })
  }

  render () {
    const { value } = this.state
    const md = new Remarkable()

    return <CommunityGuidelineDocumentContainer dangerouslySetInnerHTML={{ __html: md.render(value) }} />
  }
}
