import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import qs from 'qs'
import { TOKEN_KEYS } from '../../../constants/constants'
import { get } from '../../../utils/api'
import { getUserModelFromSection } from './utils'
import { getRegionFromLocale, getLocaleContent } from '../FaqPage/hooks'
import Loading from '../../Common/Loading'
import { useSelector } from 'react-redux'
import { getLocale } from '../../../selectors'
import { useSearch } from '../../../hooks/router'
import {
  DAtd,
  DescriptionContainer,
  DQOpenedTd,
  DQtd,
  DriverTable,
  DRow,
  Dtr,
  NoResult,
  ResultMessage
} from '../FaqPage/FaqTable'
import DataDescription from './parts/DataDescription'

function SafetyTable({ topicData, currentSection, tabSection }) {
  const search = useSearch()
  const { section } = useParams()

  const selectedLocale = useSelector(getLocale)

  const [questionList, setQuestionList] = useState([])
  const [isQuestionOpen, setIsQuestionOpen] = useState([])
  const [isQuestionFetching, setIsQuestionFetching] = useState(false)

  const handleClickOpen = index => {
    const cloneData = [...isQuestionOpen]

    cloneData[index] = !cloneData[index]
    setIsQuestionOpen(cloneData)
  }

  useEffect(() => {
    if (!topicData) return
    const fetchQuestionList = async () => {
      try {
        setIsQuestionFetching(true)

        const listQuery = {
          model: getUserModelFromSection(section),
          region: search.region || getRegionFromLocale(selectedLocale),
          topicId: !search.keyword ? search.topicId ?? topicData.id : undefined,
          locale: search.language || (topicData ? topicData.contents[0].locale : selectedLocale),
          status: search.status,
          keyword: search.keyword || undefined
        }

        const result = await get(
          `/cssvc/v1/safetyAccident/question/list?${qs.stringify(listQuery)}`,
          undefined,
          TOKEN_KEYS.faq
        )

        const list = result.content
        const questionOpen = Array.from({ length: result.content.length }, () => false)

        setQuestionList(list)

        if (search.questionId !== undefined) {
          document.getElementById(`question_${search.questionId}`) &&
            document.getElementById(`question_${search.questionId}`).scrollIntoView()

          const index = list.findIndex(question => question.id === +search.questionId)
          if (index !== undefined && index !== null) {
            questionOpen[index] = true
          }
        }

        setIsQuestionOpen(questionOpen)
        setIsQuestionFetching(false)
      } catch (e) {
        setQuestionList([])
        setIsQuestionFetching(false)
      }
    }

    if (tabSection === currentSection) {
      fetchQuestionList()
    }
  }, [search.region, search.keyword, topicData])

  if (isQuestionFetching) {
    return <Loading />
  }

  return (
    <DriverTable>
      {search.keyword && (
        <ResultMessage>
          <span>{questionList.length}</span> articles found for keyword <span>"{search.keyword || ''}"</span>
        </ResultMessage>
      )}
      {!search.keyword && !questionList.length && <NoResult>No Results</NoResult>}
      {questionList.map((question, index) => (
        <DRow key={index}>
          <Dtr onClick={() => handleClickOpen(index)}>
            {isQuestionOpen[index] ? (
              <DQOpenedTd isOpenedShow={isQuestionOpen[index]} id={`question_${question.id}`}>
                <div dangerouslySetInnerHTML={{ __html: `Q. ${getLocaleContent(question, selectedLocale).title}` }} />
              </DQOpenedTd>
            ) : (
              <DQtd totalCount={questionList.length} index={index} id={`question_${question.id}`}>
                <div dangerouslySetInnerHTML={{ __html: `Q. ${getLocaleContent(question, selectedLocale).title}` }} />
              </DQtd>
            )}
          </Dtr>
          {isQuestionOpen[index] && (
            <Dtr>
              <DAtd totalCount={questionList.length} index={index}>
                <DataDescription
                  dataType={getLocaleContent(question, selectedLocale).dataType}
                  description={getLocaleContent(question, selectedLocale).description}
                />
              </DAtd>
            </Dtr>
          )}
        </DRow>
      ))}
    </DriverTable>
  )
}

export default SafetyTable
