import { call, put, takeLatest } from 'redux-saga/effects'
import { createActions } from 'reduxsauce'
import { actionsGenerator } from '../../utils/ReducerUtils'
import { post } from '../../utils/api'

// actions

export const { Types: ContactTypes, Creators: ContactActions } = createActions(
  actionsGenerator({
    contactSubmitRequest: ['name', 'email', 'title', 'content', 'captcha']
  })
)

// sagas

function * requestContactSubmit ({ name, email, title, content, captcha }) {
  const body = {
    name,
    email,
    title,
    content,
    captcha,
    from: 'tada'
  }
  try {
    const res = yield call(post, '/contacts', body)

    yield put(ContactActions.contactSubmitSuccess(res))
  } catch (e) {
    yield put(ContactActions.contactSubmitFailure(e))
  }
}

export const sagas = [takeLatest(ContactTypes.CONTACT_SUBMIT_REQUEST, requestContactSubmit)]

const initialState = {}

export default function ContactStateReducer (state = initialState, action = {}) {
  // console.log(action)
  return state
}
