import React, { useState, useEffect, useLayoutEffect } from 'react'
import styled from 'styled-components'

import SuccessIcon from '../../../assets/images/email-verified-success.svg'
import FailIcon from '../../../assets/images/email-verified-fail.svg'
import logoIcon from '../../../assets/images/tadalogo.svg'
import { useSearch } from '../../../hooks/router'
import { get } from '../../../utils/api'

const EmailVerifiedContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .content {
    margin: 160px;
    margin-top: auto;
    margin-bottom: auto;

    @media (max-width: 768px) {
      margin: auto 16px;
    }

    .success-message {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #cacaca;
      padding-bottom: 40px;

      > img {
        width: 64px;
        height: 64px;
        margin: auto;
      }

      > p {
        font-family: 'Poppins';
        font-size: 40px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: 0px;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 5px;

        &.verified {
          color: #3ac445;
        }

        &.notVerified {
          color: #e14832;
        }
      }

      > div {
        max-width: 700px;
        margin: auto;
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: center;

        @media (max-width: 768px) {
          max-width: fit-content;
        }
      }
    }

    .sub-content {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      > div {
        > p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: center;
          color: #afafaf;
          margin-top: 3px;
          margin-bottom: 0;
        }
      }

      > img {
        width: 100px;
        height: 54px;
        margin: auto;
        margin-top: 20px;
      }
    }

    .logo-icon {
      width: 100px;
      height: 54px;
    }
  }
`

const CSEMAIL_BY_USER_MODEL = {
  ['Rider']: 'riderCsEmail',
  ['Driver']: 'phCsEmail'
}

const LINK_BY_USER_MODEL = {
  ['Rider']: 'https://tada-rider.app.link/email-verified',
  ['Driver']: 'https://tada-driver.app.link/email-verified'
}

function EmailVerified() {
  // isVerified: boolean, userModel: string(Rider, Driver), region: string(SG, KH ...)
  const search = useSearch()

  const [csEmail, setCsEmail] = useState('')
  const [address, setAddress] = useState('')

  const VERIFIED_MESSAGE = {
    [true]: {
      title: 'Your email has been verified.',
      content: 'After 1 second, you will be directed to the TADA app.'
    },
    [false]: {
      title: 'Your email has NOT been verified.',
      content: csEmail
        ? 'Please verifiy your email address again. If the verification process continues to fail, contact us via ' +
          csEmail +
          ' and we will gladly assist you.'
        : 'Please verifiy your email address again.'
    }
  }

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const result = await get('/ridesvc/v1/regions')
        if (result.content) {
          const country = result.content.find(res => res.country === search.region)

          setCsEmail(country.meta[CSEMAIL_BY_USER_MODEL[search.userModel]] || '')
          setAddress(country.meta.officeAddress)
        }
      } catch (e) {}
    }

    fetchRegions()
  }, [])

  useLayoutEffect(() => {
    if (!search.isVerified) {
      return
    }

    const redirectLink = () => {
      const link = LINK_BY_USER_MODEL[search.userModel]

      location.href = link
    }

    const timeout = setTimeout(redirectLink, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <EmailVerifiedContainer>
      <div className="content">
        <div className="success-message">
          <img src={search.isVerified ? SuccessIcon : FailIcon} alt="success" />
          <p className={search.isVerified ? 'verified' : 'notVerified'}>{VERIFIED_MESSAGE[search.isVerified].title}</p>
          <div>{VERIFIED_MESSAGE[search.isVerified].content}</div>
        </div>
        <div className="sub-content">
          <div>
            {address && <p>{address}</p>}
            <p>© MVL Foundation Pte. Ltd. All Rights Reserved.</p>
          </div>
          <img className="logo-icon" src={logoIcon} alt="logo" />
        </div>
      </div>
    </EmailVerifiedContainer>
  )
}

export default EmailVerified
