import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Link from '../Common/Link'
import A from '../Common/A'
import TadaLogo from '../../assets/images/tada/tada-footer-logo@2x.png'
import FacebookIcon from '../../assets/images/tada/facebook.png'
import { Desktop, Mobile } from '../Common/ByScreen'
import { translate } from 'react-i18next'
import { DOMAIN_TYPE, FACEBOOK_URL_BY_LOCALE, getDomainType, LOCALE_TYPE } from '../../constants/constants'
import { getContactInfoByRegion } from '../../utils/MyUtils'

const FooterContainer = styled.footer`
  width: 100%;
  box-sizing: border-box;
  height: 250px;
  background-color: #0a3142;
  margin: 0;
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    padding: 61px 0;
    align-items: flex-start;
    flex-direction: column;
  }
`

const FooterInfo = styled.div`
  > p {
    max-width: 960px;
    font-size: 12px;
    font-weight: 500;
    color: #afafaf;
    line-height: normal;
    margin: 3px 0 12px;
    padding: 0;

    @media (max-width: 1000px) {
      margin: 17px 17px 0;
      white-space: pre-wrap;
    }
  }
`

const FooterLogo = styled.img`
  max-width: 100px;
  max-height: 33px;
  margin-bottom: 20px;
  width: auto;
  height: auto;

  @media (max-width: 1000px) {
    max-width: 72px;
    max-height: 24px;
  }
`

const FooterUl = styled.ul`
  display: flex;
  align-items: center;
`

const FooterLi = styled.li`
  display: inline-flex;

  & + & {
    margin-left: 60px;

    @media (max-width: 1260px) {
      margin-left: 15px;
    }
  }
`

const FooterLink = styled(Link)`
  color: #eaba28;
  font-size: 16px;
  font-weight: bold;
  &:hover,
  &:active {
    color: #fff;
  }
`

const FooterFacebookLink = styled(A)`
  width: 40px;
  height: 40px;
  background-position: -40px;
  background-image: url(${FacebookIcon});
  background-repeat: no-repeat;
  &:hover,
  &active {
    background-position: 0;
  }
`

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 17px;
  width: 100%;
  box-sizing: border-box;
`

const HeaderToMVL = styled(A)`
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-right: 30px;
  padding-left: 17px;
  & > span {
    font-size: 11px;
    font-weight: normal;
  }
`

function Footer({ t, i18n: { language: selectedLocale } }) {
  const [contactInfo, setContactInfo] = useState(null)
  const fbUrl = FACEBOOK_URL_BY_LOCALE[selectedLocale] ?? FACEBOOK_URL_BY_LOCALE[LOCALE_TYPE.EN]

  useEffect(() => {
    const getContact = async () => {
      const info = await getContactInfoByRegion(selectedLocale)

      setContactInfo(info)
    }

    getContact()
  }, [selectedLocale])

  return (
    <FooterContainer>
      <Mobile>
        <FooterTop>
          <FooterLogo src={TadaLogo} alt="TadaLogo" />
          <FooterFacebookLink />
        </FooterTop>
        <HeaderToMVL target="_blank" href="https://mvlchain.io">
          <span>Powered by&nbsp;</span>
          MVL
        </HeaderToMVL>
      </Mobile>
      <FooterInfo>
        <Desktop>
          <FooterLogo src={TadaLogo} alt="TadaLogo" />
        </Desktop>
        <p>
          {selectedLocale === 'km' || selectedLocale === 'vi' ? (
            contactInfo?.address
          ) : (
            <div>
              {contactInfo?.address}
              <br />
              {contactInfo?.subAddress}
            </div>
          )}
        </p>
        <p>© {contactInfo?.company} All Rights Reserved.</p>
      </FooterInfo>
      <Desktop>
        <FooterUl>
          <FooterLi>
            <FooterLink to={'/terms/term'}>{t('title.terms')}</FooterLink>
          </FooterLi>
          <FooterLi>
            <FooterLink to={'/terms/privacy_policy'}>{t('title.privacy')}</FooterLink>
          </FooterLi>
          <FooterLi>
            <FooterLink to={'/code_of_conduct'}>{t('title.code_of_contact')}</FooterLink>
          </FooterLi>
          {getDomainType() === DOMAIN_TYPE.TADA_DOMAIN && (
            <FooterLi>
              <FooterLink to={'/community_guideline'}>Community Guideline</FooterLink>
            </FooterLi>
          )}
          <FooterLi>
            <FooterFacebookLink target="_blank" href={fbUrl} />
          </FooterLi>
        </FooterUl>
      </Desktop>
    </FooterContainer>
  )
}

export default translate()(Footer)
