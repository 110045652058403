import React from 'react'
import { DescriptionContainer } from '../../FaqPage/FaqTable'
import { decodeDescription } from '../../../../utils/MyUtils'

// dataType: TEXT | HTML; description: string;
function DataDescription({ dataType, description }) {
  if (dataType === 'TEXT') {
    return (
      <DescriptionContainer>
        <strong>A. </strong>
        {description}
      </DescriptionContainer>
    )
  } else {
    return (
      <DescriptionContainer
        dangerouslySetInnerHTML={{
          __html: `<strong>A</strong>. ${decodeDescription(description)}`
        }}
      />
    )
  }
}

export default DataDescription
