import React, { Component } from 'react'
import Remarkable from 'remarkable'
import styled from 'styled-components'

export const DocumentContainer = styled.div`
  & h2 {
    font-size: 18px;
    line-height: 24px;

    @media (max-width: 1000px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  h3,
  h4,
  p,
  ul,
  ol {
    font-size: 14px;
    line-height: 24px;

    @media (max-width: 1000px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  & ul,
  & ol {
    padding: 0;
    overflow: hidden;
    counter-reset: list;
  }

  & li {
    position: relative;
    padding: 0 0 0 25px;

    &:before {
      position: absolute;
      left: 0;
      content: counter(list) ') ';
      counter-increment: list;
    }

    & ol li {
      &:before {
        content: counter(list) '. ';
        counter-increment: list;
      }
    }
  }

  & a {
    color: #4a90e2;
  }
`

export default class Document extends Component {
  state = {
    value: ''
  }

  constructor (props) {
    super(props)
    const { document } = this.props
    fetch(document) // eslint-disable-line no-undef
      .then(res => {
        return res.text()
      })
      .then(text => {
        this.setState({
          value: text
        })
      })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.document !== this.props.document) {
      fetch(this.props.document) // eslint-disable-line no-undef
        .then(res => {
          return res.text()
        })
        .then(text => {
          this.setState({
            value: text
          })
        })
    }
  }

  render () {
    const { value } = this.state
    const md = new Remarkable()

    return <DocumentContainer dangerouslySetInnerHTML={{ __html: md.render(value) }} />
  }
}
