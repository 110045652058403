import React, { useEffect } from 'react'
import { translate } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { APP_AUTHORITY, HELP_CENTER_STATIC_TOPIC, MOBILE_NAVIGATOR_HEIGHT } from '../../../constants/constants'
import { useSearch } from '../../../hooks/router'
import { LANGS } from '../../../i18n'
import { setIsStaticTopic, setReportInfo } from '../../../modules/helpCenter/reducer'
import { DriverActions } from '../../../modules/driver/DriverState'

import MobileWebViewContainer from '../../Common/MobileWebViewContainer'
import { checkRequiredField, sendExitHelpCenterToApp, useTopicList } from './hooks'
import HelpCenterTopicRow from './parts/HelpCenterTopicRow'
import { getLocale } from '../../../selectors'
import qs from 'qs'

const HelpCenterBody = styled.div`
  padding: 0 24px;
  max-height: calc(100% - ${MOBILE_NAVIGATOR_HEIGHT}px - 53px);
  overflow-y: scroll;
`

function HelpCenter({ t }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const search = useSearch()

  const selectedLocale = useSelector(getLocale)

  const { topicList, isFetching } = useTopicList(search)

  const handleClickBack = () => {
    sendExitHelpCenterToApp()
  }

  useEffect(
    () => {
      const language = search.language
      dispatch(DriverActions.changeLocaleRequest(LANGS.includes(language) ? language : 'en'))

      if (!checkRequiredField(search)) {
        sendExitHelpCenterToApp('An internal error occurred. Please contact our team for support.')
      }

      dispatch(setReportInfo(search))
    },
    [search]
  )

  useEffect(
    () => {
      if (!topicList || !topicList.length) return

      const { topicType, appAuthority } = search

      if (topicType) {
        const topicNames = HELP_CENTER_STATIC_TOPIC[appAuthority === APP_AUTHORITY.RIDER_APP ? 'rider' : 'driver']

        if (topicNames[topicType]) {
          const topic = topicList.find(topic => topic.contents[0].topicName === topicNames[topicType][selectedLocale])

          if (topic) {
            dispatch(setIsStaticTopic(true))
            history.replace({
              pathname: `/help-center/${topic.id}`,
              state: {
                topic: topic
              }
            })
          }
        }
      }
    },
    [topicList, search.topicType]
  )

  return (
    <MobileWebViewContainer title={t('report.label_report_issue')} isLoading={isFetching} onBack={handleClickBack}>
      <HelpCenterBody>
        {topicList.map(topic => (
          <HelpCenterTopicRow topic={topic} />
        ))}
      </HelpCenterBody>
    </MobileWebViewContainer>
  )
}

export default translate()(HelpCenter)
