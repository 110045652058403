import React from 'react'
import styled from 'styled-components'
import HelpCenterQuestionIcon from './HelpCenterQuestionIcon'

const Row = styled.div`
  margin-bottom: 28px;

  > .help_center__question_header {
    display: flex;
    align-items: center;
    gap: 8px;
    > .help_center__question_title {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
    }
  }

  > .help_center__question_body {
    margin-top: 12px;
    padding: 0 5px;

    > .help_center__question_body__text {
      white-space: pre-wrap;
      font-size: 14px;
    }
  }
`

function HelpCenterQuestionRow ({ question }) {
  const [content] = question.contents

  return (
    <Row>
      <div className='help_center__question_header'>
        <HelpCenterQuestionIcon />
        <h3 className='help_center__question_title'>{question.contents[0].title}</h3>
      </div>
      <div className='help_center__question_body'>
        {content.dataType === 'TEXT' ? (
          <div className='help_center__question_body__text'>{content.description}</div>
        ) : (
          <div
            className='help_center__question_body__html'
            dangerouslySetInnerHTML={{ _html: decodeURIComponent(window.atob(content.description)) }}
          />
        )}
      </div>
    </Row>
  )
}

export default HelpCenterQuestionRow
