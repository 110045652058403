import React from 'react'
import styled from 'styled-components'

const Col = styled.div`
  ${({ grow }) => grow && `flex-grow: ${grow};`}
  ${({ width }) => width && `width: ${width};`}

  .error_message {
    margin-top: 4px;
    font-weight: 700;
    font-size: 12px;
    color: #ff3737;
  }

  .sub_label {
    margin-top: 8px;
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    color: #999;
  }
`

function DeleteAccountCol({ subLabel, error, children, ...props }) {
  return (
    <Col {...props}>
      {children}
      {subLabel && <p className="sub_label">{subLabel}</p>}
      {error && <p className="error_message">{error}</p>}
    </Col>
  )
}

export default DeleteAccountCol
