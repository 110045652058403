import { all, fork } from 'redux-saga/effects'
import { sagas as driverSaga } from './modules/driver/DriverState'
import { sagas as documentSaga } from './modules/driver_document/DriverDocumentState'
import { sagas as settingSaga } from './modules/settings/SettingState'
import { sagas as contactSaga } from './modules/contact/ContactState'
import helpCenterSaga from './modules/helpCenter/saga'
import deleteAccountSaga from './modules/deleteAccount/saga'

function* mySaga() {
  yield all([
    ...driverSaga,
    ...documentSaga,
    ...settingSaga,
    ...contactSaga,
    fork(helpCenterSaga),
    fork(deleteAccountSaga)
  ])
}

export default mySaga
