import React from 'react'
import styled from 'styled-components'

export const DELETE_ACCOUNT_BUTTON_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  OUTLINE: 'outline'
}

const getBackgroundByVariant = variant => {
  switch (variant) {
    case DELETE_ACCOUNT_BUTTON_VARIANT.PRIMARY:
      return '#FFC400'
    case DELETE_ACCOUNT_BUTTON_VARIANT.SECONDARY:
      return '#E6E6E6'
    case DELETE_ACCOUNT_BUTTON_VARIANT.OUTLINE:
      return '#fff'
  }
}

const getActiveBackgroundByVariant = variant => {
  switch (variant) {
    case DELETE_ACCOUNT_BUTTON_VARIANT.PRIMARY:
      return '#FFB200'
    case DELETE_ACCOUNT_BUTTON_VARIANT.SECONDARY:
      return '#DEDEDE'
    case DELETE_ACCOUNT_BUTTON_VARIANT.OUTLINE:
      return '#F2F2F2'
  }
}

const Button = styled.button`
  width: 100%;
  height: 48px;
  padding: 0 13px;
  border-radius: 8px;

  border: ${({ variant }) => (variant === DELETE_ACCOUNT_BUTTON_VARIANT.OUTLINE ? '1px solid #000' : 'none')};
  background: ${({ variant }) => getBackgroundByVariant(variant)};

  &:active {
    background: ${({ variant }) => getActiveBackgroundByVariant(variant)};
  }

  &:disabled {
    opacity: 0.7;
  }
`

function DeleteAccountButton({ variant = DELETE_ACCOUNT_BUTTON_VARIANT.PRIMARY, ...props }) {
  return <Button {...props} variant={variant} />
}

export default DeleteAccountButton
