import { call, put, takeLatest } from 'redux-saga/effects'
import { createActions } from 'reduxsauce'
import _ from 'lodash'

import { actionsGenerator } from '../../utils/ReducerUtils'
import { get, post } from '../../utils/api'

const INITIAL_STATE = {
  requiredFields: [],
  optionalFields: [],
  completedFields: [],
  completedOptionalFields: [],
  taxiHiringAgreementDocs: [],
  vehicleOwnershipDocs: [],
  acraBizDocs: [],
  insuranceDocs: [],
  applicationFeedback: {},
  hash: null,
  isAuthed: false,
  driverType: -1,
  taxiCompany: null,
  carModel: null,
  carMaker: null,
  carPlate: null,
  carSeats: 0,
  carYear: 0,
  carType: -1,
  babySeat: null,
  carPhoto: null,
  taxiFront: null,
  taxiMdt: null,
  taxiLicensePlateNumber: null,
  bankBookPhoto: null,
  bankAccountName: null,
  bankName: null,
  bankAccountNumber: null,
  bankBranch: null,
  emergencyContactName: null,
  emergencyContactRelationship: null,
  emergencyContactNumber: null,
  emergencyAddress: null,
  profilePicture: null,
  nricFront: null,
  nricBack: null,
  nric: null,
  regionCode: null,
  residentialAddress: null,
  drivingLicenseFront: null,
  drivingLicenseBack: null,
  judicialRecord: null,
  vehicleRegister: null,
  vehicleOwnership: null,
  pdvl: true,
  pdvlFront: null,
  pdvlBack: null,
  decal: null,
  tdvlFront: null,
  tdvlBack: null,
  status: -1,
  equipments: -1
}

export const { Types: DocumentTypes, Creators: DocumentActions } = createActions(
  actionsGenerator({
    setDocumentInfos: ['field', 'value'],
    setDocumentInfosFromResponse: ['response'],
    fetchDocumentRequest: [],
    upsertDocumentRequest: ['params'],
    loadPhotoRequest: ['keys'],
    clearDocument: []
  })
)

// saga
function * requestFetchDocument () {
  try {
    const res = yield call(get, '/drivers/me/document')
    yield put(DocumentActions.fetchDocumentSuccess(res))
  } catch (e) {
    yield put(DocumentActions.fetchDocumentFailure(e))
  }
}

function * requestUpsertDocument ({ params }) {
  try {
    const res = yield call(post, '/drivers/me/document', params)
    yield put(DocumentActions.upsertDocumentSuccess(res))
  } catch (e) {
    yield put(DocumentActions.upsertDocumentFailure(e))
  }
}

function * requestLoadPhoto ({ keys }) {
  try {
    const res = yield call(
      get,
      `/drivers/me/document/get_photos?keys=${_.castArray(keys)
        .map(k => encodeURIComponent(k))
        .join(',')}`
    )
    yield put(DocumentActions.loadPhotoSuccess(res))
  } catch (e) {
    yield put(DocumentActions.loadPhotoFailure(e))
  }
}

export const sagas = [
  takeLatest(DocumentTypes.FETCH_DOCUMENT_REQUEST, requestFetchDocument),
  takeLatest(DocumentTypes.UPSERT_DOCUMENT_REQUEST, requestUpsertDocument),
  takeLatest(DocumentTypes.LOAD_PHOTO_REQUEST, requestLoadPhoto)
]

export default function DriverDocumentStateReducer (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case DocumentTypes.SET_DOCUMENT_INFOS: {
      return {
        ...state,
        [action.field]: action.value
      }
    }
    case DocumentTypes.FETCH_DOCUMENT_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case DocumentTypes.UPSERT_DOCUMENT_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case DocumentTypes.LOAD_PHOTO_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case DocumentTypes.SET_DOCUMENT_INFOS_FROM_RESPONSE: {
      const res = _.cloneDeep(action.response)
      // if (!res.idtype) {
      //   res.idtype = constants.KYC_PICTURE_TYPE.PASSPORT;
      // }
      // if (res.birthDate) {
      //   const [date, month, year] = _.split(res.birthDate, '/');
      //   res.birthDom = parseInt(date, 10) || date;
      //   res.birthMonth = parseInt(month, 10) || month;
      //   res.birthYear = year;
      // }
      return {
        ...state,
        ...res
      }
    }

    case DocumentTypes.CLEAR_DOCUMENT: {
      return INITIAL_STATE
    }

    default:
      return state
  }
}
