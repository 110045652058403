import { Link } from 'react-router-dom'
import styled from 'styled-components'

const MvlLink = styled(Link)`
  font-weight: bold;
  font-size: 15px;
  color: #f8bc3d;
  cursor: pointer;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`

export default MvlLink
