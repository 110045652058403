import { TOKEN_KEYS } from '../../../constants/constants'
import { get } from '../../../utils/api'

export const fetchFaqTopicByAlias = async (params, option) => {
  try {
    const result = await get(
      `/cssvc/v1/faq/topic/find-by-alias/${params}`,
      undefined,
      TOKEN_KEYS.faq,
      undefined,
      option
    )

    return result
  } catch (e) {
    return null
  }
}

export const fetchFaqQuestionByAlias = async (params, option) => {
  try {
    const result = await get(
      `/cssvc/v1/faq/question/find-by-alias/${params}`,
      undefined,
      TOKEN_KEYS.faq,
      undefined,
      option
    )

    return result
  } catch (e) {
    return null
  }
}
