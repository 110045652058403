//

import React from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import FadeInAndOut from '../../utils/TransitionAnimationFade'

export const MultiLinesMessages = messages => {
  const messageComponents = _.map(messages, message => <div style={{ marginBottom: '5px' }}>{message}</div>)
  return <div>{messageComponents}</div>
}

export default (message, type, duration = 3000) => {
  let func = toast.success
  if (type === 'warn') {
    func = toast.warn
  } else if (type === 'error') {
    func = toast.error
  }

  func(message, {
    transition: FadeInAndOut,
    autoClose: duration
  })
}
