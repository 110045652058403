import { useEffect, useState } from 'react'
import { ALL_HELP_CENTER_REQUIRED_FIELD, TOKEN_KEYS } from '../../../constants/constants'
import { get } from '../../../utils/api'
import qs from 'qs'

export const useTopicList = (search = {}) => {
  const [topicList, setTopicList] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const setTopicData = async query => {
    setIsFetching(true)
    try {
      const result = await get(`/cssvc/v1/report/topic/list?${query}`, undefined, TOKEN_KEYS.faq)

      setTopicList(result.content || [])
    } catch (error) {
      setTopicList([])
    }
    setIsFetching(false)
  }

  useEffect(
    () => {
      const query = sanitizeTopicListQuery(search)

      if (!query.locale || !query.region || !query.appAuthority) {
        return
      }

      setTopicData(qs.stringify(query))
    },
    [search]
  )

  return { topicList, isFetching }
}

export const useQuestionList = (topic = {}) => {
  const [questionList, setQuestionList] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const setQuestionData = async query => {
    setIsFetching(true)
    try {
      const result = await get(`/cssvc/v1/report/question/list?${query}`, undefined, TOKEN_KEYS.faq)

      setQuestionList(result.content || [])
    } catch (error) {
      setQuestionList([])
    }
    setIsFetching(false)
  }

  useEffect(
    () => {
      const query = sanitizeQuestionListQuery(topic)

      if (!query.locale || !query.region || !query.appAuthority || !query.topicId) {
        return
      }

      setQuestionData(qs.stringify(query))
    },
    [topic]
  )

  return { questionList, isFetching }
}

export const sanitizeTopicListQuery = query => {
  return {
    locale: query.language,
    region: query.region,
    appAuthority: query.appAuthority
  }
}

export const sanitizeQuestionListQuery = topic => {
  return {
    locale: topic.contents[0].locale,
    region: topic.region,
    appAuthority: topic.appAuthority,
    topicId: topic.id
  }
}

export const sanitizeReportParam = data => {
  const cloneData = { ...data }

  delete cloneData.language
  delete cloneData.appAuthority
  delete cloneData.region

  return cloneData
}

export const checkRequiredField = search => {
  const searchRequiredFields = Object.entries(search).filter(
    ([key, value]) => value && ALL_HELP_CENTER_REQUIRED_FIELD.includes(key)
  )
  return searchRequiredFields.length === ALL_HELP_CENTER_REQUIRED_FIELD.length
}

export const sendExitHelpCenterToApp = (message = '') => {
  if (window.android && window.android.exitHelpCenter) {
    window.android.exitHelpCenter(message || null)
  }

  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.exitHelpCenter &&
    window.webkit.messageHandlers.exitHelpCenter.postMessage
  ) {
    window.webkit.messageHandlers.exitHelpCenter.postMessage(message || null)
  }
}
