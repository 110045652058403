import React, { useEffect, useRef } from 'react'
import CarInformation from './CarInformation'
import { decodeDescription } from '../../../utils/MyUtils'
import { sendIsScrollTopToApp } from './utils'

function RideProductItem({ item, index, currentIndex }) {
  const bodyRef = useRef()
  const prevIsScrollTop = useRef()

  const handleScroll = e => {
    const { scrollTop } = e.target
    const isScrollTop = !scrollTop

    if (prevIsScrollTop.current !== isScrollTop) {
      prevIsScrollTop.current = isScrollTop
      sendIsScrollTopToApp(isScrollTop)
    }
  }

  useEffect(() => {
    if (currentIndex === index) {
      const { scrollTop } = bodyRef.current
      const isScrollTop = !scrollTop

      prevIsScrollTop.current = isScrollTop
      sendIsScrollTopToApp(isScrollTop)
    }
  }, [currentIndex])

  if (!Object.keys(item).length) {
    return null
  }

  return (
    <div className="car_info_wrap" onScroll={handleScroll} ref={bodyRef}>
      <CarInformation item={item} />
      <div
        className="ride_product__description"
        dangerouslySetInnerHTML={{ __html: decodeDescription(item.content.description) }}
      />
    </div>
  )
}

export default RideProductItem
