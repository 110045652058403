import React, { useEffect, useState } from 'react'
import MobileWebViewContainer from '../../Common/MobileWebViewContainer'
import { useSearch } from '../../../hooks/router'
import { getReferralRewardRules } from './util'
import styled from 'styled-components'
import Loading from '../../Common/Loading'
import { decodeDescription } from '../../../utils/MyUtils'

const TermsContainer = styled.div`
  padding: 24px 0;
`

const ReferralDescription = styled.div`
  overflow-wrap: break-word;

  * > li {
    list-style-position: inside;
    li::marker {
      margin-right: 3px;
    }
  }
`

function TermsAndConditions() {
  const search = useSearch()
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const { inviterType, inviteeType, region, locale, driverType } = search

        const content = await getReferralRewardRules(inviterType, inviteeType, region, locale, driverType)

        setDescription(content?.referralRewardRuleTnC)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [search])

  if (isLoading) {
    return <Loading />
  }

  return (
    <MobileWebViewContainer isNavigator={false} headerSize={0} isLoading={false}>
      <TermsContainer>
        <ReferralDescription dangerouslySetInnerHTML={{ __html: decodeDescription(description) }} />
      </TermsContainer>
    </MobileWebViewContainer>
  )
}

export default TermsAndConditions
