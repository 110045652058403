import React, { Component } from 'react'
import Remarkable from 'remarkable'
import styled from 'styled-components'
import { CommunityGuidelineDocumentContainer } from '../CommunityGuidelineDocument'

export const CodeOrConductDocumentContainer = styled(CommunityGuidelineDocumentContainer)`
  & ul,
  & ol {
    list-style-type: lower-alpha;
  }

  & li {
    position: relative;

    & ol {
      list-style-type: lower-roman;
      counter-increment: list;
    }
    & ol li {
      font-weight: bold;
    }
    & ol li p {
      font-weight: normal;
    }
  }
`


export default class CodeOrConductDocument extends Component {
  state = {
    value: ''
  }

  constructor (props) {
    super(props)
    const { document } = this.props
    fetch(document) // eslint-disable-line no-undef
      .then(res => {
        return res.text()
      })
      .then(text => {
        this.setState({
          value: text
        })
      })
  }

  componentDidUpdate (prevProps) {
    const { document } = this.props
    if (prevProps.document !== document) {
      fetch(document) // eslint-disable-line no-undef
        .then(res => {
          return res.text()
        })
        .then(text => {
          this.setState({
            value: text
          })
        })
    }
  }

  render () {
    const { value } = this.state
    const md = new Remarkable()

    return <CodeOrConductDocumentContainer dangerouslySetInnerHTML={{ __html: md.render(value) }} />
  }
}
