import { call, put, takeLatest } from 'redux-saga/effects'
import { combineReducers } from 'redux'
import _ from 'lodash'
import { createActions } from 'reduxsauce'

import { DriverTypes } from '../driver/DriverState'
import { getStorageLocale, setStorageLocale } from '../../utils/MyStorage'
import { actionsGenerator } from '../../utils/ReducerUtils'
import { get } from '../../utils/api'
import { LANGS } from '../../i18n'
import { DOMAIN_TYPE, getDomainType, LOCALE_TYPE } from '../../constants/constants'

const INITIAL_LOCALE = (() => {
  if (getDomainType() === DOMAIN_TYPE.VN_DOMAIN) {
    return 'vi'
  }

  if (navigator && navigator.language) {
    // eslint-disable-line
    const browserLanguage = navigator.language // eslint-disable-line
    if (browserLanguage.match(/^en(.)*/)) {
      return 'en'
    } else if (browserLanguage === 'zh' || browserLanguage === 'zh-CN') {
      return LOCALE_TYPE.ZH_CN
    } else if (browserLanguage === 'vi') {
      return 'vi'
    } else if (browserLanguage === 'km') {
      return 'km'
    }
  }
  return 'en'
})()

// actions
export const { Types: SettingTypes, Creators: SettingActions } = createActions(
  actionsGenerator({
    fetchPercentRequest: ['round']
  })
)

// sagas

function* requestFetchPercent({ round }) {
  try {
    const response = yield call(get, `/${round}/get_total_progress`)
    yield put(SettingActions.fetchPercentSuccess(response))
  } catch (e) {
    yield put(SettingActions.fetchPercentFailure(e))
  }
}

export const sagas = [takeLatest(SettingTypes.FETCH_PERCENT_REQUEST, requestFetchPercent)]

const selectedLocale = (state = getStorageLocale() || INITIAL_LOCALE, action) => {
  let ret = state
  switch (action.type) {
    case DriverTypes.FETCH_ME_SUCCESS:
    case DriverTypes.CHANGE_LOCALE_SUCCESS:
      if (_.includes(LANGS, action.payload.locale)) {
        ret = action.payload.locale
      }
      break
    default:
      break
  }
  setStorageLocale(ret) // TODO: saga나 다른곳으로 뺄 방법은?
  if (document && document.getElementsByTagName('html')[0]) {
    document.getElementsByTagName('html')[0].lang = ret
  }
  return ret
}

export default combineReducers({
  selectedLocale
})
