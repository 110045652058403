import MvlStorage from './MyStorage'

const AUTHENTICATION_STORAGE_KEY = 'Authentication'

export function getAuthenticationToken () {
  try {
    const token = MvlStorage.get(AUTHENTICATION_STORAGE_KEY)
    if (token) {
      return JSON.parse(token)
    }
    return null
  } catch (e) {
    return null
  }
}

export function setAuthenticationToken (token) {
  return MvlStorage.set(AUTHENTICATION_STORAGE_KEY, JSON.stringify(token), new Date().getTime() + 1000 * 60 * 60 * 3)
}

export function clearAuthenticationToken () {
  return MvlStorage.remove(AUTHENTICATION_STORAGE_KEY)
}

export function isAuthenticated () {
  return !!getAuthenticationToken()
}
