import { put, takeLatest, call } from 'redux-saga/effects'
import { TOKEN_KEYS } from '../../constants/constants'
import { post } from '../../utils/api'

import { fetchReportRequest, fetchReportSuccess, fetchReportFailure } from './reducer'

function * fetchReport ({ payload }) {
  try {
    const response = yield call(post, '/cssvc/v1/report/submit', payload, undefined, TOKEN_KEYS.faq, {
      decamelize: false,
      setLocale: false
    })

    const action = response.ok ? fetchReportSuccess() : fetchReportFailure(response.data.message)

    yield put(action)
  } catch (err) {
    yield put(fetchReportFailure(err.message))
  }
}

export default function * defaultSaga () {
  yield takeLatest(fetchReportRequest.type, fetchReport)
}
