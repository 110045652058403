import React, { Component } from 'react'
import styled from 'styled-components'
import Button from '../Common/Button'
import Slider from 'react-slick'
import Document from '../Document/index'

export const TabContainer = styled.div`
  padding: 60px 0;
`

export const TabContent = styled.div`
  @media (max-width: 1000px) {
    padding: 60px 0;
  }
`

export const TabControlBtn = styled(Button)`
  font-size: 24px;
  font-weight: bold;
  padding: 0;

  @media (max-width: 1000px) {
    margin: 0 auto;
    padding: 0 38% 35px;
    white-space: pre;
    display: inline-flex;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    width: 100%;
    text-align: left;
    width: 100%;
  }

  @media (max-width: 770px) {
    padding: 0 37% 35px;
  }

  @media (max-width: 680px) and (min-width: 560px) {
    padding: 0 35% 35px;
  }

  @media (max-width: 559px) and (min-width: 416px) {
    padding: 0 33% 35px;
  }

  @media (max-width: 415px) {
    padding: 0 26% 35px;
  }

  @media (max-width: 380px) {
    padding: 0 23% 35px;
  }

  @media (max-width: 320px) {
    padding: 0 16% 35px;
  }
`

export const TabController = styled.ul`
  width: 301px;
  position: absolute;
  top: -100px;
  justify-content: space-between;
  padding: 0;
  display: flex;
  border-bottom: solid 1px #000;

  // @media (max-width: 1000px) {
  //   top: -60px;
  // }

  @media (max-width: 1000px) {
    top: -60px;
    width: 100%;
  }

  & > li {
    display: inline-flex;
    @media (max-width: 1000px) {
      width: 50%;
    }

    & > ${TabControlBtn} {
      color: #d5d5d5;
      height: 60px;
      border-bottom: solid 5px transparent;

      @media (max-width: 1000px) {
        height: auto;
      }
    }

    &.slick-active > ${TabControlBtn} {
      color: #4a4a4a;
      border-bottom: solid 5px #000;

      @media (max-width: 1000px) {
        color: #0a3142;
      }
    }
  }
`




export default class Tab extends Component {
  render () {
    const { items } = this.props
    const setting = {
      dots: true,
      arrows: false,
      infinite: false,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dotsClass: '',
      className: 'tab-carousel',
      appendDots: dots => <TabController>{dots}</TabController>,
      customPaging: i => <TabControlBtn>{items[i].title}</TabControlBtn>
    }

    return (
      <TabContainer>
        <Slider {...setting}>
          {items.map(item => (
            <TabContent>
              <Document document={item.document} />
            </TabContent>
          ))}
        </Slider>
      </TabContainer>
    )
  }
}
