import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import arrowBottomSVG from './assets/arrow_bottom.svg'
import arrowTopSVG from './assets/arrow_top.svg'
import { mobileCheck } from '../../../../utils/MyUtils'

const getBorderColor = ({ isOpen, value }) =>
  isOpen ? '#ffc400' : value || typeof value === 'number' ? '#000' : '#b3b3b3'

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  & > .dropdown__input {
    cursor: pointer;
    position: relative;

    box-sizing: border-box;
    padding: 13px 12px;
    height: 48px;
    width: 100%;

    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;

    border: 1px solid ${getBorderColor};

    outline: none;

    &:focus {
      border: 1px solid #ffc400;
    }

    &:not(.mobile)::after {
      position: absolute;
      display: block;

      right: 12px;
      top: 50%;
      transform: translateY(-50%);

      width: 14px;
      height: 8px;

      background-image: url(${({ isOpen }) => (isOpen ? arrowTopSVG : arrowBottomSVG)});
      background-repeat: no-repeat;

      content: '';
    }
  }

  & > .dropdown__option_box {
    position: absolute;
    top: calc(100% + 8px);

    width: 100%;
    max-height: 264px;
    overflow-y: scroll;
    overflow-x: hidden;

    border-radius: 12px;

    border: 1px solid #b3b3b3;

    z-index: 1000;

    &::-webkit-scrollbar {
      display: none;
    }

    & > .dropdown__option_item {
      cursor: pointer;
      display: flex;
      align-items: center;
      box-sizing: border-box;

      width: 100%;
      height: 48px;
      padding: 13px 12px;

      background: #fff;

      font-size: 16px;
      font-weight: 600;

      &:not(:last-child) {
        border-bottom: 1px solid #b3b3b3;
      }

      &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      &:hover {
        background: #f2f2f2;
      }
    }
  }
`
function DeleteAccountDropdown({ options, onChange, value: inputValue }) {
  const DEFAULT_DISPLAY_TEXT = 'Select'

  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(null)

  const handleChange = value => {
    setValue(value)
    setIsOpen(false)
    onChange(value)
  }

  useEffect(() => {
    if (inputValue !== value) {
      setValue(inputValue)
    }
  }, [inputValue])

  if (mobileCheck()) {
    return (
      <Wrapper isOpen={isOpen} value={value}>
        <select className="dropdown__input mobile" onChange={e => handleChange(e.target.value)}>
          {options?.map((option, index) => (
            <option value={option.value} key={`${option.value}-${index}`}>
              {option.text}
            </option>
          ))}
        </select>
      </Wrapper>
    )
  }

  return (
    <Wrapper isOpen={isOpen} value={value}>
      <div className="dropdown__input" onClick={() => setIsOpen(!isOpen)}>
        {value || typeof value === 'number'
          ? options?.find(option => option.value === value).text ?? DEFAULT_DISPLAY_TEXT
          : DEFAULT_DISPLAY_TEXT}
      </div>
      {isOpen && (
        <div className="dropdown__option_box">
          {options?.map((option, index) => (
            <div
              className={`dropdown__option_item${option.disabled ? ' disabled' : ''}`}
              key={`${option.value}-${index}`}
              onClick={() => handleChange(option.value)}
            >
              {option.text}
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  )
}

export default DeleteAccountDropdown
