import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'
import { I18nextProvider } from 'react-i18next'
import ReactGA from 'react-ga'
import { init } from '@sentry/browser'
import { firebaseInitialize, i18nConfig } from 'tada-homepage-component'
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill'
import { setConfiguration } from './utils/configuration'
import configureStore, { sagaMiddleware } from './store/configureStore'
import mySaga from './sagas'
import i18n from './i18n'

import Logout from './components/Logout'

import './assets/styles/normalize.css'
import './assets/styles/App.css'
import './assets/styles/fonts.css'

import TadaHomePage from './components/Pages/HomePage'
import TadaDriverPage from './components/Pages/AppDriverPage'
import TadaRiderPage from './components/Pages/AppRiderPage'
import TadaFaqPage from './components/Pages/FaqPage'
import TadaSafetyPage from './components/Pages/Safety'
import TadaTermPage from './components/Pages/TermPage'
import TadaPrivacyPage from './components/Pages/PrivacyPage'
import TadaCodeOfConductPage from './components/Pages/CodeOfConductPage'
import TadaCommunityGuidelinePage from './components/Pages/CommunityGuidelinePage'
import HelpCenter from './components/Pages/HelpCenter/index'
import HelpCenterDetail from './components/Pages/HelpCenter/Detail'
import HelpCenterReport from './components/Pages/HelpCenter/Report'
import EmailVerified from './components/Pages/EmailVerified'
import RideProduct from './components/Pages/RideProduct'
import RideShow from './components/Pages/RideShow'
import DeleteAccountRequest from './components/Pages/DeleteAccount/Delete'
import DeleteAccountError from './components/Pages/DeleteAccount/Error'
import DeleteAccountRequestOTP from './components/Pages/DeleteAccount/RequestOTP'
import DeleteAccountSuccess from './components/Pages/DeleteAccount/Success'
import SupportPage from './components/Pages/SupportPage'
import ReferralTermsAndCondition from './components/Pages/ReferralPage/TermsAndConditions'
import ReferralDescription from './components/Pages/ReferralPage/Descriptions'
import GlobalStyle from './GlobalStyle'
import Contact from './components/Pages/Contact'
import SWRProvider from './utils/SWRProvider'

const isProd = process.env.NODE_ENV === 'production'

if (isProd) {
  init({
    dsn: 'https://734211724a3f4a4dbd02a921cd321658@sentry.io/1255511',
    release: import.meta.REACT_APP_COMMIT_HASH
  })
}

const store = configureStore()
sagaMiddleware.run(mySaga)
const PUBLIC_URL = process.env.PUBLIC_URL || ''
const history = createHistory({ basename: `${PUBLIC_URL}` })

let apiRoot = import.meta.env.VITE_REACT_APP_API_HOST || 'https://backend.driver.tada-dev.com'
let riderApiRoot = process.env.REACT_APP_RIDER_API_HOST || 'http://127.0.0.1:9000'
if (isProd) {
  riderApiRoot = 'https://backend.tada.global'
  ReactGA.initialize('UA-115107310-2')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

setConfiguration('API_ROOT', apiRoot)
setConfiguration('RIDER_API_ROOT', riderApiRoot)

firebaseInitialize({
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: '1042636826581',
  appId: '1:1042636826581:web:cb14c8a589aa698b5b3f5c',
  measurementId: 'G-TEPPSD6C8E'
})

i18nConfig()

polyfillCountryFlagEmojis()

function Init() {
  console.log(
    '  __  ____     ___                                                                            \n' +
      ' |  \\/  \\ \\   / / |                                                                           \n' +
      ' | |\\/| |\\ \\ / /| |                                                                           \n' +
      ' | |  | | \\ V / | |___                                                                        \n' +
      ' |_|  |_|  \\_/  |_____|                                                                       \n' +
      '  _ _      ___                      _   _                 _                        _      _ _  \n' +
      ' ( | )    |_ _|_ __   ___ ___ _ __ | |_(_)_   _____      | |__   __ _ ___  ___  __| |    ( | )\n' +
      "  V V      | || '_ \\ / __/ _ \\ '_ \\| __| \\ \\ / / _ \\_____| '_ \\ / _` / __|/ _ \\/ _` |     V V \n" +
      '           | || | | | (_|  __/ | | | |_| |\\ V /  __/_____| |_) | (_| \\__ \\  __/ (_| |         \n' +
      '          |___|_| |_|\\___\\___|_| |_|\\__|_| \\_/ \\___|     |_.__/ \\__,_|___/\\___|\\__,_|         \n' +
      '                   ____  _            _        _           _                                  \n' +
      '                  | __ )| | ___   ___| | _____| |__   __ _(_)_ __                             \n' +
      "                  |  _ \\| |/ _ \\ / __| |/ / __| '_ \\ / _` | | '_ \\                            \n" +
      '                  | |_) | | (_) | (__|   < (__| | | | (_| | | | | |                           \n' +
      '                  |____/|_|\\___/_\\___|_|\\_\\___|_| |_|\\__,_|_|_| |_|                           \n' +
      '                  |  \\/  | ___ | |__ (_) (_) |_ _   _                                         \n' +
      "                  | |\\/| |/ _ \\| '_ \\| | | | __| | | |                                        \n" +
      '                  | |  | | (_) | |_) | | | | |_| |_| |                                        \n' +
      '                  |_|  |_|\\___/|_.__/|_|_|_|\\__|\\__, |                                        \n' +
      '                   _____                        |___/                                         \n' +
      '                  | ____|___ ___  ___ _   _ ___| |_ ___ _ __ ___                              \n' +
      "                  |  _| / __/ _ \\/ __| | | / __| __/ _ \\ '_ ` _ \\                             \n" +
      '                  | |__| (_| (_) \\__ \\ |_| \\__ \\ ||  __/ | | | | |                            \n' +
      '                  |_____\\___\\___/|___/\\__, |___/\\__\\___|_| |_| |_|                            \n' +
      '                                      |___/                                                   '
  )
  
  return null
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Init />
    <I18nextProvider i18n={i18n}>
      <SWRProvider>
        <Router history={history} basename={`${PUBLIC_URL}`}>
          <Switch>
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/" component={TadaHomePage} />
            <Route exact path="/app/driver" component={TadaDriverPage} />
            <Route exact path="/app/rider" component={TadaRiderPage} />
            <Route exact path="/contact" component={Contact} />
            <Route
              exact
              path="/faq"
              component={({ TadaFaqPage }) => <Redirect to={{ ...TadaFaqPage, pathname: '/faq/driver' }} />}
            />
            <Route exact path="/faq/:section" component={TadaFaqPage} />
            <Redirect exact from="/safety" to="/safety/driver" />
            <Route exact path="/safety/:section" component={TadaSafetyPage} />
            <Route exact path="/terms/term" component={TadaTermPage} />
            <Route exact path="/terms/privacy_policy" component={TadaPrivacyPage} />
            <Route exact path="/code_of_conduct" component={TadaCodeOfConductPage} />
            <Route exact path="/community_guideline" component={TadaCommunityGuidelinePage} />
            <Route exact path="/help-center/:id/report" component={HelpCenterReport} />
            <Route exact path="/help-center/:id" component={HelpCenterDetail} />
            <Route exact path="/help-center" component={HelpCenter} />
            <Route exact path="/support-page" component={SupportPage} />
            <Route exact path="/email-verified" component={EmailVerified} />
            <Route exact path="/ride-product-info" component={RideProduct} />
            <Route exact path="/share/:id" component={RideShow} />
            <Route exact path="/delete/account/request" component={DeleteAccountRequest} />
            <Route exact path="/delete/account/error" component={DeleteAccountError} />
            <Route exact path="/delete/account/success" component={DeleteAccountSuccess} />
            <Route exact path="/delete/account/:userModel(rider|driver)" component={DeleteAccountRequestOTP} />
            <Route exact path="/referral-terms" component={ReferralTermsAndCondition} />
            <Route exact path="/referral-descriptions" component={ReferralDescription} />
            <Redirect to={'/'} />
          </Switch>
        </Router>
      </SWRProvider>
      <GlobalStyle />
    </I18nextProvider>
  </Provider>
)
