import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import FaqTable from './tabContent'

import { compose } from 'recompose'
import { translate } from 'react-i18next'

const RIDER_SAFETY_AND_ACCIDENT = 'rider_safety_and_accident'
const contents = {
  size: 5,
  name: RIDER_SAFETY_AND_ACCIDENT
}

class TadaRiderTabs extends Component {
  constructor (props) {
    super(props)

    this.renderRiderContents = this.renderRiderContents.bind(this)
  }

  renderRiderContents () {
    const riderDetail = []
    for (let k = 0; k < contents.size; k++) {
      if (this.props.t(`${contents.name}${k}_question`) !== 'N/A') {
        riderDetail.push(`${contents.name}${k}`)
      }
    }

    return riderDetail
  }

  render () {
    return <FaqTable faqTitle={this.renderRiderContents()} />
  }
}

export default compose(translate())(withRouter(TadaRiderTabs))
