// !!! Do not edit this file directly.
// !!! 이 파일을 직접 수정하지 말고 sheet 수정 후 script 실행할 것
export default {
  translations: {
    sg_taxi: {
      'ComfortDelgro & Citycab': 'ComfortDelGro & CityCab',
      Premier: 'Premier',
      Prime: 'Prime',
      'SMRT Cab': 'SMRT',
      'Trans-cab': 'Trans-Cab',
      'Yellow-Top': 'Yellow-Top',
      HDT: 'HDT',
    },
    ph_taxi_plate: '的士車牌號碼',
    desc_mdt_must: '必須顯示的士 MDT 的照片',
    desc_mdt_name: '司機姓名',
    desc_mdt_plate: '車牌號碼',
    desc_mdt_date: '最近日期（即照片必須是最近拍的）',
    btn_save_next: '儲存並執行下一步',
    btn_later: '稍後',
    repeat_password: '確認密碼',
    phone_number: '電話號碼',
    digit_pin_number: '4 位 PIN 碼',
    refferal_code: '推薦碼',
    singapore: '新加坡',
    country: '國家／地區',
    signup: {
      introduce1: '歡迎註冊成為 TADA 司機。請提供以下資料，成為 TADA 的合作司機。請提交所有文件以完成申請。如果您在提交過程中遇到任何問題，請透過',
      email: 'support_hk@tada.global',
      introduce2: '聯絡我們，我們將在 1 個工作日內回覆您。文件可採用圖片或 pdf 的格式上載。',
      submit: '提交',
      login_here: '在此登入',
      refferal_code: '推薦碼（可選）',
      pin_number: 'PIN 碼',
      mobile_number: '手機號碼',
      agree: '我同意',
      my_information: '我的資料',
    },
    driver: {
      available_after_complete: '完成後可用',
      filled_out_zero: '您已填寫 0 個（共 {{totalSteps}} 個）欄位。',
      my_page: '我的頁面',
      application: '司機申請',
      application_taxi: '的士司機申請',
      application_tuktuk: '篤篤車司機申請',
      application_bike: '單車司機申請',
      application_not_complete: '您的申請未完成',
      filled_out: '您已填寫 {{doneSteps}} 個（共 {{totalSteps}} 個）欄位。',
      being_reviewed: '您的申請正在審核中',
      information: '個人資料',
      name: '姓名',
      mobile_number: '手機號碼',
      refferal_code: '我的推薦碼',
      logout: '登出',
      application_complete: '您的申請已完成。我們給您發了一封確認電郵。',
      application_not_succesful: '您的申請未成功。請檢查您的電郵，並重新提交所述文件。',
    },
    judicial_record: '司法記錄',
    vehicle_registration: '車輛登記文件',
    tuktuk_registration_card: '篤篤車登記卡',
    certificate_of_registry: '登記證明書',
    commercial_insurance_certificate: '商業保險憑證',
    contract_car_badge: '合約車標',
    national_id: '身份證號碼',
    identity_card_number: '身份證號碼',
    license_plate_number: '車牌號碼',
    car_make: '汽車品牌',
    car_make_ex: '汽車品牌（如：寶馬）',
    car_model_ex: '汽車型號（如：QM3)',
    bike_make: '單車品牌',
    bike_model: '單車型號',
    desc_sharp_clear_photo: '必須清晰明確。',
    valid_driving_license: '必須上載有效的駕駛執照（即未過期）。',
    valid_document: '必須上載有效的文件（即未過期）。',
    valid_nric: '必須上載有效的身份證號碼（即未過期）。',
    valid_registration_card: '必須上載有效的登記卡（即未過期）',
    waring_acra_form: '上載文件的前三頁，證明您是個體經營者，並有一家註冊公司用於繳納所得稅。',
    waring_bike_form: '車牌號碼必須與所有文件相符',
    acra_form_skip: '計劃租車營運的使用者無需執行此步驟。',
    upload_the_backside: '上載顯示汽車資料的背面。',
    gps_tracking_device: '商用型，附 GPS 追蹤裝置。',
    license_plate: '商業保險中的車牌號碼應與登記的車牌號碼相符。',
    national_id_uploaded: '必須上載有效的身份證號碼（即未過期）。',
    upload_driving_license_front: '上載駕駛執照（正面）',
    upload_driving_license_back: '上載駕駛執照（背面）',
    upload_judicial_record: '上載司法記錄（正面）',
    upload_vehicle_registration: '上載車輛登記文件（背面）',
    upload_certificate_of_registry: '上載登記證明書（背面）',
    upload_insurance: '上載保險（正面）',
    upload_insurance_certificate: '上載商業保險憑證（背面）',
    upload_nric_front: '上載身份證（正面）',
    upload_nric_back: '上載身份證（背面）',
    upload_file: '上載檔案',
    upload_pdvl_front: '上載 PDVL（正面）',
    upload_decal: '私人租車貼紙',
    upload_car_contract_badge: '上載合約車標（正面）',
    upload_national_id: '上載身份證號碼（正面）',
    upload_identity_card_number_front: '上載身份證號碼（正面）',
    upload_identity_card_number_back: '上載身份證號碼（背面）',
    upload_bike_picture_front: '上載單車全貌圖片',
    upload_bike_picture_back: '上載另一張至少顯示車牌號碼的單車半貌圖片',
    uploaded_profile_picture: '上載頭像',
    upload_tuktuk_registration_card: '上載篤篤車登記卡（正面）',
    upload_profile_picture: '如果跳過這一步，系統會提醒您在開始接受乘車服務之前再次上載頭像。',
    no_wearing_of_accessories: '不要配戴可能遮擋面部特徵的飾品（如帽子、太陽眼鏡等）。',
    please_white_background: '請使用白色背景。',
    please_upload_photo: '請上載一張清晰的照片。',
    taxi_license_plate_num: '的士車牌號碼',
    section_taxi_details: '的士詳情',
    desc_upload_taxi_front: '上載的士正面照片',
    desc_upload_mdt: '上載的士 MDT 的照片',
    reset_password_success: '您帳戶 ({{email}}) 的密碼已安全更改。',
    change_password: '密碼更改',
    forgot_password: '找回密碼',
    reset_password: '重設密碼',
    error_password_length: '密碼長度必須為 6~20 個字元。',
    faq: '常見問題',
    login: '登入',
    login_required: '需要登入',
    logout: '登出',
    sign_up: '註冊',
    taxi_sign_up: '的士司機註冊',
    my_page: '我的頁面',
    email: '電郵',
    email_placeholder: '輸入電郵地址',
    email_used: '電郵 {{email}} 已存在',
    password: '密碼',
    password_placeholder: '輸入您的密碼',
    password_confirm: '確認密碼',
    password_confirm_placeholder: '再次輸入密碼',
    new_password_placeholder: '輸入新密碼',
    verify_mail_title: '檢查驗證電郵',
    verify_mail_desc: '檢查收件箱是否有驗證電郵。({{email}})',
    privacy_policy: '私隱政策',
    terms_and_conditions: '條款及細則',
    button_confirm: '確認',
    button_cancel: '取消',
    locale: {
      en: 'English',
      ko: '한국어',
      zh_hans: '简体中文',
      zh_hant: '繁體中文',
      vi: 'Tiếng Việt',
      km: 'ភាសាខ្មែរ',
      th: 'ภาษาไทย',
      'zh-CN': '簡體中文',
      'zh-TW': '繁體中文',
      jp: '日本語',
    },
    btn_save: '儲存',
    first_name: '名字',
    last_name: '姓氏',
    phone_placeholder: '電話號碼',
    wrong_number_request: '{{driverPhone}} 是錯誤號碼。請輸入另一個號碼。',
    photo_file_format: '.jpg, .png',
    delete_image: '刪除圖片',
    upload_image: '上載圖片',
    agree_policy_please: '請同意所有的條款及細則。',
    button_resend_email_verify: '重新傳送驗證電郵',
    success_resend_email_verify: '驗證電郵已傳送。',
    optional_brackets: '（可選）',
    error_time_out: '與伺服器的連接逾時。請重試。',
    error_captcha_not_valid: 'ReCaptcha 無效',
    car_model: '汽車型號',
    car_photo_warn: '汽車正面照片清楚顯示車牌號碼',
    plate_number: '車牌號碼',
    banking_details: '銀行帳戶詳情',
    bank_account_holder_name_failed: '請重試。請請勿使用特殊字元和數字。',
    bank_account_holder_name: '銀行帳戶持有人姓名',
    bank_select_name: '銀行名稱',
    bank_account_number: '銀行帳號',
    bank_branch: '銀行分行',
    bank_skip_warn: '如果您跳過這一步，當您開始接單並要對收入提現時，系統會再次提醒您提供銀行帳戶詳細資料。',
    warning_when_leaving_without_saving: '您確定要退出嗎？',
    upload_bank_picture: '上載銀行結單照片',
    bank_photo_warn: '請上載銀行帳戶資料（ATM 收據或銀行結單）照片，顯示帳戶名稱和銀行帳號',
    profile_picture: '頭像',
    nric: '身份證',
    driving_license: '駕駛執照',
    acra_bizfile: 'ACRA Bizfile',
    insurance_certificate: '保險憑證',
    error_password_confirm: '密碼不相符',
    phone_used: '該電話號碼已被使用',
    send_pin: '傳送 PIN 碼',
    edit: '編輯',
    tada: {
      home:
        '<p>歡迎來到叫車服務的新紀元。歡迎使用 TADA。<br></p>\n<p>踏入TADA的新世界 - 在這裡，叫車服務被重新定義。<br></p>\n<p>司機在沒有佣金的情況下蓬勃發展。<br></p>\n<p>乘客享受透明、公平、實惠的價格，隨時隨地安全出行。<br></p>\n<p>開始一段變革之旅 - 在這裡，便利與公平、可靠和信任相遇，重新定義您的交通體驗。</p>',
      driver: {
        app: {
          name_1: '行程配對',
          name_2: '上車',
          name_3: '駕駛',
          name_4: '下車和支付',
          waiting: 'iOS 版本的 TADA 司機應用程式目前正在審核中。我們將確保您能夠盡快輕鬆下載該應用程式！',
        },
        info: {
          title_1: '首創且唯一： \n司機零佣金',
          content_1: 'TADA 不收取任何佣金。除了扣賬卡／信用卡交易費外，所有收入都歸司機所有。我們將繼續秉持這一原則。',
          title_2: '簡單司機註冊',
          content_2: '為方便起見，我們簡化了註冊流程。',
          title_3: '高級配對系統',
          content_3: 'TADA 基於大數據分析司機服務模式，並提供個人化的配對系統，使更多的乘客可以配對到司機。',
          title_4: '無縫接單服務',
          content_4: '即將到達上一位乘客的目的地時，會接到下一位乘客的預約。這樣可以連續零延遲接單。',
          title_5: '輕鬆存取營運資料',
          content_5: '我們提供了一個儀錶板功能，方便您核查資料， 如每天和每週的訂單歷史記錄、目前的收入等。',
          title_6: '獎賞和獎勵',
          content_6: '我們提供獎勵和任務計劃，幫助您提高收入。',
        },
        driverappdownload: '司機應用程式下載',
      },
      rider: {
        app: {
          name_1: '乘車請求！',
          name_2: '司機配對',
          name_3: '乘車',
          name_4: '支付和意見',
        },
        info: {
          title_1: '提供乘客快速出行',
          content_1: '採用出色的配對技術，迅速為您安排司機。我們會安排一名最快到達並提供優質服務和安全駕駛的司機。',
          title_2: '更好且實惠的車費',
          content_2: '透過我們的零佣金政策，提供經濟實惠且可靠的出行服務。',
          title_3: '高峰時段配對',
          content_3: '無論是高峰時段、雨天還是晴天，我們都會為您配對司機。',
          title_4: '服務範圍廣',
          content_4: '無論您要去哪裡，我們都會為您配對司機將您安全送達。',
          title_5: '快捷且簡單的付款',
          content_5: '您可以選擇用現金或扣賬卡／信用卡支付。\n您還可以選擇綁定您最喜歡的扣賬卡／信用卡， 以實現無縫支付。',
          title_6: '審慎審核管理',
          content_6: '您可以給司機提供意見。我們會根據您的意見來管理司機，為您安排可靠的司機。',
        },
        riderappdownload: '乘客應用程式下載',
      },
      contact: {
        contact_us: '聯絡我們',
        name: {
          label: '◉ 姓名或昵稱（必填）',
          placeholder: '（30 個字元）',
        },
        email: {
          label: '◉ 您的電郵地址（必填）',
          example: '（例如：mvler@mvlchain.io）',
          placeholder: {
            name: 'MVLER',
            domain: 'mvlchain.io',
          },
        },
        title: {
          label: '◉ 職位（必填）',
          placeholder: '（30 個字元）',
        },
        content: {
          label: '◉ 說明（必填）',
          placeholder:
            '[注意事項] \\n\\n-最多 2000 個字元\\n-請詳細填寫\\n-請勿使用污言穢語\\n-請勿上載任何敏感個人資料（ID、密碼、聯絡方式等）\\n-如果我們需要更多資料，我們將與您聯絡\\n-點擊下面的「提交」按鈕，即表示您同意發佈位置、IP、作業系統等資料  ',
        },
        submit: {
          request: {
            submit: '提交',
            title: '確定要提交嗎？',
            desc: '（電郵地址：',
            review: '審核',
          },
          confirm: {
            title: '註冊已完成',
            desc: '我們將在審核後回覆您的查詢。',
            confirm: '確認',
          },
        },
      },
    },
    title: {
      about: '關於',
      driver: '司機',
      rider: '乘客',
      contact: '聯絡方式',
      faq: '常見問題',
      powerd_by_mvl: '由 MVL 提供<span>支援</span>',
      terms: '條款',
      privacy: '私隱',
      code_of_contact: '司機行為準則',
    },
    faq_driver_app: '司機應用程式',
    faq_rider_app: '乘客應用程式',
    driver_safety_and_accident0_question: '我遇到了事故。',
    driver_safety_and_accident0_answer:
      '<ul>\n<li>立即停車並移至安全區域。</li>\n<li>確保您、乘客和其他相關人員的安全。</li>\n<li>如果有人嚴重受傷或需要醫療救助，請撥打緊急服務電話。</li>\n<li>如有傷患，請勿移動或轉移傷患</li>\n<li>\n<ul>\n收集資料，例如：\n<li>\n事故發生的時間和地點\n</li>\n<li>肇事各方的詳情</li>\n<li>損壞區域和周圍的照片。</li>\n</ul>\n</li>\n<li>盡快向有關部門報告事故。</li>\n<li>透過應用程式報告事故，告知 TADA 事故詳情。我們將提供相關幫助。</li>\n</ul>\n<br />\n<div style="text-decoration:underline;">實用資料</div>\n報警：999\n<br />\n<a href=\'https://www.police.gov.hk/ppp_en/contact_us.html\'>警務處網站</a>\n<br />\n<br />\n<div style="text-decoration:underline;">安全駕駛須知</div>\n<br />\n<ul>\n<li>遵守所有交通規則。</li>\n<li>開車時要保持警惕和耐心。</li>\n<li>開車時不得使用手機。使用手機之前務必停車。</li>\n<li>避免超速行駛。</li>\n<li>避免疲勞駕駛。必要時休息一下。</li>\n<li>不要酒後駕駛。</li>\n</ul>\n<br />\n安全到達目的地。\n',
    driver_safety_and_accident1_question: '言語和身體虐待乘客',
    driver_safety_and_accident1_answer:
      '每個人都有不順心的時候。不幸與顧客發生衝突時，避免事態升級，避免與顧客發生攻擊性行為。\n<br />\n<br />\n<ul>\n<li>始終保持專業精神。</li>\n<li>緩和事態，集中精力安全到達目的地。</li>\n<li>TADA 社群絕不容忍言語和身體虐待。如果事態變得具攻擊性和暴力，不要與顧客正面衝突。</li>\n<li>如果事態升級為身體暴力，請立即與地方當局聯絡。</li>\n<li>如果發生緊急情況，請立即報警 (999) 或民防部隊 (995)。</li>\n<li>不要拍攝任何未經授權的影片或照片，並上載到社交媒體上。</li>\n<li>行程結束時使用意見功能向我們的客戶服務團隊報告。TADA 將公平調查所有報告的個案。</li>\n</ul>\n<br />\n<br />\n<div style="text-decoration:underline;">衝突解決技巧</div>\n<br />\n<ul>\n<li>大多數衝突都是因溝通不順造成的。嘗試從顧客的角度了解情況，並以積極的態度作出反應。</li>\n<li>面對激動的顧客時，保持冷靜和放鬆的語氣</li>\n</ul>\n<br />\n<div style="text-decoration:underline;">實用資料</div>\n報警：999\n<br />\n<a href=\'https://www.police.gov.hk/ppp_en/contact_us.html>警務處網站</a>',
    driver_safety_and_accident2_question: '性虐待／性騷擾',
    driver_safety_and_accident2_answer:
      '性虐待和性騷擾在法律上嚴格禁止。如果您受到顧客騷擾，不要與顧客正面衝突，立即聯絡相關部門。您也不得在行程結束後因個人原因直接與顧客聯絡。緊急按鈕功能隨時可用，以防發生任何緊急情況。\n<br />\n<br />\n 在行程結束後提交一份意見，包括事故的細節資料，TADA 客戶支援團隊將與您聯絡，以便採取後續行動。所有報告都將得到公平的調查\n<br />\n<br />\n<div style="text-decoration:underline;">實用資料</div>\n報警：999\n<br />\n<a href=\'https://www.police.gov.hk/ppp_en/contact_us.html>警務處網站</a>\n',
    driver_safety_and_accident3_question: '汽車故障與保養',
    driver_safety_and_accident3_answer:
      '如果不幸無法安全駕駛，請聯絡您的拖車服務維修廠。取消行程並說明原因，TADA 客戶服務團隊會跟進。\n<br />\n<br />\nTADA 建議司機每六 (6) 個月進行一次車輛保養。\n<br />\n<br />\n<div style="text-decoration:underline;">為什麼定期保養非常有用？</div>\n<br />\n<ul>\n<li>正確保養車輛可以幫助您避免發生意外車輛故障。</li>\n<li>定期保養可提高乘客和您的行車安全。</li>\n<li>保養費用低於大修費用，養成定期保養的習慣會為您節省昂貴的維修費用。\n</ul>',
    driver_safety_and_accident4_question: '2019 冠狀病毒病應對措施',
    driver_safety_and_accident4_answer:
      '鑒於 2019 冠狀病毒病疫情，LTA 發佈了一項要求所有合作司機都遵守的建議。\n<br />\n<br />\n<div style="text-decoration:underline;">出行時佩戴口罩</div>\n<br />\n的士、PHC 司機和乘客必須佩戴口罩。的士和 PHC 司機應拒絕運載不戴口罩的乘客。\n<br />\n<br />\n居家隔離的乘客。\n<br />\n<br />\n如果的士或私人租車司機搭載居家隔離人員 (PSHN) 往返出入境檢查站、居家隔離地點和指定的 2019 冠狀病毒病檢測點，或者如果司機知道乘客是 PSHN（透過應用程式預約通知或乘客在乘車時提前告知），司機應採取以下預防措施：\n<br />\n<br />\n<ul>\n<li>在運載此類乘客時，應打開車窗；且</li>\n<li>在行程結束時對車輛進行清潔和消毒。</li>\n</ul>\n<br />\n<br />\n<div style="text-decoration:underline;">TraceTogether</div>\n<br />\n我們強烈建議的士司機、PHC 司機和乘客攜帶 TraceTogether 憑證或在智能手機上打開 TraceTogether 應用程式，以方便追蹤接觸者。及早發現與 2019 冠狀病毒病確診病例有密切接觸的人員，將有助於保護司機、乘客及其親人，並防止在社區進一步傳播。\n<br />\n<br />\n<div style="text-decoration:underline;">健康與安全諮詢</div>\n<br />\nLTA 在此提醒的士及私人租車司機：\n<br />\n<br />\n<ul>\n<li>身體不適，立即就醫，不要開車。</li>\n<li>保持良好的個人衛生，避免不必要地觸摸臉部。</li>\n<li>運載有流感症狀的乘客時，以及每次行程結束後，打開車窗，促進車內通風。</li>\n<li>經常清潔和消毒車輛。</li>\n<li>運載有流感症狀的乘客時，建議乘客戴上口罩。如果乘客沒有口罩，為乘客提供口罩（如可以）。</li>\n<li>每天開車前測量體溫，其餘時間再測量一次。\n</ul>\n',
    rider_safety_and_accident0_question: '我的司機遇到了事故。',
    rider_safety_and_accident0_answer:
      '<ul>\n<li>確保您和相關人員的人身安全。</li>\n<li>如果需要，立即聯絡緊急服務。</li>\n<li>\n收集資料，例如：\n<ul>\n<li>事故發生的時間和地點</li>\n<li>司機的詳細資料</li>\n<li>如有需要，提供證據照片。</li>\n</ul>\n</li>\n<li>如果需要，可以透過司機的汽車保險公司或您的個人保險公司提出索償。</li>\n</ul>\n<br />\n<br />\n<div style="text-decoration:underline;">實用資料</div>\n報警：999\n<br />\n<a href=\'https://www.police.gov.hk/ppp_en/contact_us.html>警務處網站</a>\n<br />\n<br />\n<div style="text-decoration:underline;">安全乘車須知</div>\n<br />\n<ul>\n<li>即使坐在後座也要繫好安全帶。</li>\n<li>確保司機遵守交通規則。</li>\n<li>如果司機沒有安全駕駛，請給予提醒。</li>\n<li>向 TADA 報告並反饋所有與安全相關的事故。</li>\n</ul>\n',
    rider_safety_and_accident1_question: '身體／言語虐待',
    rider_safety_and_accident1_answer:
      '司機有義務遵守 TADA 行為準則。若不幸受到我們合作司機的虐待，請立即通知 TADA。\n<br />\n<br />\n<ul>\n<li>TADA 社群絕不容忍言語和身體虐待。如果事態變得具攻擊性，避免與司機正面衝突。</li>\n<li>如果事態升級為身體暴力，請立即與地方當局聯絡。</li>\n<li>如果發生緊急情況，請立即報警 (999) 尋求援助</li>\n<li>行程結束時使用意見功能向我們的客戶服務團隊報告。TADA 將公平調查所有報告的個案。</li>\n</ul>',
    rider_safety_and_accident2_question: '性虐待／性騷擾',
    rider_safety_and_accident2_answer:
      '性虐待和性騷擾在法律上嚴格禁止。如果您受到司機騷擾，不要與司機正面衝突，立即聯絡相關部門。緊急按鈕功能隨時可用，以防發生任何緊急情況。\n<br />\n<br />\n在行程結束後提交一份意見，包括司機資料和事故細節。TADA 客戶支援團隊將盡快與您聯絡，以便採取後續行動。所有報告的個案都將得到公平的調查。\n<br />\n<br />\n<div style="text-decoration:underline;">實用資料</div>\n報警：999\n<br />\n<a href=\'https://www.police.gov.hk/ppp_en/contact_us.html>警務處網站</a>\n',
    rider_safety_and_accident3_question: '不安全駕駛行為',
    rider_safety_and_accident3_answer:
      'TADA 致力於為乘客和司機建立一個安全的網約車社群。如果您發現或遇到 TADA 司機不安全駕駛行為，請與我們聯絡。\n<br />\n<br />\n如需緊急援助，請與警方 (999) 聯絡',
    rider_safety_and_accident4_question: '2019 冠狀病毒病應對措施',
    rider_safety_and_accident4_answer:
      '出行時佩戴口罩\n<br />\n<br />\nLTA 和 MOH 規定，的士、PHC 司機和乘客必須戴口罩。\n<br />\n<br />\n上車前\n<br />\n<br />\n如果您發現司機沒有戴口罩，可以取消行程。取消後向 TADA 提交意見。\n<br />\n<br />\n前往目的地途中\n<br />\n<br />\n如果司機途中摘下口罩，請提醒司機重新戴上口罩。立即提交意見，TADA 客服支援團隊將會進行調查。\n<br />\n<br />\n如果您在乘車時遇到任何與健康有關的問題，請透過 support_hk@tada.global 聯絡 TADA客服支援團隊。',
    safety_and_accident_title: '安全與事故',
    driver_safety: '司機安全',
    rider_safety: '乘客安全',
    safety: '安全',
    rider_cancellation_table_content:
      '建立一個積極的網約車生態系統需要司機和乘客的共同努力。因此，司機也實行了取消政策，這樣分派的司機將對您的預約履行承擔更大的責任。',
    th_driver_safety_and_accident0_question: '我遇到了事故。',
    th_driver_safety_and_accident0_answer:
      '<ul><li>立即停車並移至安全區域。</li><li>確保您、乘客和其他相關人員的安全。</li><li>如果有人嚴重受傷或需要醫療救助，請撥打緊急服務電話。</li><li>如有傷患，請勿移動或轉移傷患</li><li>收集資料，例如：<ul><li>事故發生的時間和地點</li><li>相關各方的詳細資料</li><li>損壞區域和周圍的照片。</li></ul></li><li>盡快向有關部門報告事故。</li><li>透過應用程式報告事故，告知 TADA 事故詳情。我們將提供相關幫助。</li></ul><br /><p>有用資料</p><br /><p>報警：191</p><br /><p>報警網站：<a href="https://www.royalthaipolice.go.th/">https://www.royalthaipolice.go.th/</a></p><br /><p>NIEMS：1669</p><br /><p>NIEMS 網站：<a href="https://www.niems.go.th/1/Home/Main/">https://www.niems.go.th/1/Home/Main/</a></p><br /><p>安全駕駛須知</p><br /><ul><li>遵守所有交通規則。</li>開車時要保持警惕和耐心。</li><li>開車時不得使用手機。使用手機之前務必停車。</li><li>避免超速行駛。</li><li>避免疲勞駕駛。必要時休息一下。</li><li>不要酒後駕駛。</li></ul><br /><p>安全到達目的地。</p>',
    th_driver_safety_and_accident1_question: '言語和身體虐待乘客',
    th_driver_safety_and_accident1_answer:
      '<p>每個人都有不順心的時候。不幸與顧客發生衝突時，避免事態升級，避免與顧客發生攻擊性行為。</p><br /><ul><li>始終保持專業精神。</li><li>緩和事態，集中精力安全到達目的地。</li><li>TADA 社群絕不容忍言語和身體虐待。如果事態變得具攻擊性和暴力，不要與顧客正面衝突。</li><li>如果事態升級為身體暴力，請立即與地方當局聯絡。</li><li>如果發生緊急情況，請立即報警 (191) 或 NIEMS (1669)。</li><li>不要拍攝任何未經授權的影片或照片，並上載到社交媒體上。</li><li>行程結束時使用意見功能向我們的客戶服務團隊報告。TADA 將公平調查所有報告的個案。</li></ul><br /><p>衝突解決技巧</p><br /><ul><li>大多數衝突都是因溝通不順造成的。嘗試從顧客的角度了解情況，並以積極的態度作出反應。</li><li>面對激動的顧客時，保持冷靜和放鬆的語氣。</li></ul><br /><p>實用資料</p><br /><p>報警：191</p><br /><p>報警網站：<a href="https://www.royalthaipolice.go.th/">https://www.royalthaipolice.go.th/</a></p><br />NIEMS：1669<br /><p>NIEMS 網站：<a href="https://www.niems.go.th/1/Home/Main">https://www.niems.go.th/1/Home/Main/</a></p>',
    th_driver_safety_and_accident2_question: '性虐待／性騷擾',
    th_driver_safety_and_accident2_answer:
      '<p>性虐待和性騷擾在法律上嚴格禁止。如果您受到顧客騷擾，不要與顧客正面衝突，立即聯絡相關部門。您也不得在行程結束後因個人原因直接與顧客聯絡。緊急按鈕功能隨時可用，以防發生任何緊急情況。</p><br /><p>在行程結束後提交一份意見，包括事故的細節資料，TADA 客戶支援團隊將與您聯絡，以便採取後續行動。所有報告都將得到公平的調查。</p><br /><p>實用資料</p><br /><p>報警：191</p><br /><p>報警網站：<a href="https://www.royalthaipolice.go.th/">https://www.royalthaipolice.go.th/</a></p><br /><p>NIEMS：1669</p><br /><p>NIEMS 網站：<a href="https://www.niems.go.th/1/Home/Main/">https://www.niems.go.th/1/Home/Main/</a></p>',
    th_driver_safety_and_accident3_question: '汽車故障與保養',
    th_driver_safety_and_accident3_answer:
      '<p>如果不幸無法安全駕駛，請聯絡 HWPD (1193) 或您的拖車服務維修廠。取消行程並說明原因，TADA 客戶服務團隊會跟進。</p><br /><p>TADA 建議司機每六 (6) 個月進行一次車輛保養。女為什麼定期保養非常有用？</p><br /><ul><li>正確保養車輛可以幫助您避免發生意外車輛故障。</li><li>定期保養可提高乘客和您的行車安全。</li><li>保養費用低於大修費用，養成定期保養的習慣會為您節省昂貴的維修費用。</li></ul>',
    th_driver_safety_and_accident4_question: '2019 冠狀病毒病應對措施',
    th_driver_safety_and_accident4_answer:
      '<p>鑒於 2019 冠狀病毒病疫情，DLT 發佈了一項要求所有合作司機都遵守的建議。</p><br /><p><u>出行時佩戴口罩</u></p><br /><p>的士、PHC 司機和乘客必須佩戴口罩。的士和 PHC 司機應建議乘客佩戴防護口罩。</p><br /><p><u>健康與安全諮詢</u></p><p>DLT 在此提醒的士及私人租車司機：</p><br /><ul><li>身體不適，立即就醫，不要開車。</li><li>保持良好的個人衛生，避免不必要地觸摸臉部。</li><li>運載有流感症狀的乘客時，以及每次行程結束後，打開車窗，促進車內通風。</li><li>經常對車輛進行清潔和消毒。</li><li>運載有流感症狀的乘客時，建議乘客戴上口罩。如果乘客沒有口罩，為乘客提供口罩（如可以）。</li><li>每天開車前測量體溫，其餘時間再測量一次。</li></ul>',
    th_rider_safety_and_accident0_question: '我的司機遇到了事故。',
    th_rider_safety_and_accident0_answer:
      '<ul><li>確保您和相關人員的人身安全。</li><li>如果需要，立即聯絡緊急服務。</li><li>收集資料，例如：<ul><li>事故發生的時間和地點</li><li>司機的詳細資料</li><li>如有需要，提供證據照片。</li></ul></li><li>如果需要，可以透過司機的汽車保險公司或您的個人保險公司提出索償。</li></ul><br /><p>實用資料</p><br /><p>報警：191</p><br /><p>報警網站：<a href="https://www.royalthaipolice.go.th/">https://www.royalthaipolice.go.th/</a></p><br /><p>NIEMS：1669</p><br /><p>NIEMS 網站：<a href="https://www.niems.go.th/1/Home/Main">https://www.niems.go.th/1/Home/Main/</a></p><br /><p>安全乘車須知</p><br /><li>即使坐在後座也要繫好安全帶。</li><li>確保司機遵守交通規則。</li><li>如果司機沒有安全駕駛，請給予提醒。</li><li>向 TADA 報告並反饋所有與安全相關的事故。</li>',
    th_rider_safety_and_accident1_question: '身體／言語虐待',
    th_rider_safety_and_accident1_answer:
      '<p>司機有義務遵守 TADA 行為準則。若不幸受到我們合作司機的虐待，請立即通知 TADA。</p><br /><ul><li>TADA 社群絕不容忍言語和身體虐待。如果事態變得具攻擊性，避免與司機正面衝突。</li><li>如果事態升級為身體暴力，請立即與地方當局聯絡。</li><li>如果發生緊急情況，請立即報警 (191) 或 NIEMS (1669) 尋求援助。</li><li>行程結束時使用意見功能向我們的客戶服務團隊報告。TADA 將公平調查所有報告的個案。</li></ul>',
    th_rider_safety_and_accident2_question: '性虐待／性騷擾',
    th_rider_safety_and_accident2_answer:
      '<p>性虐待和性騷擾在法律上嚴格禁止。如果您受到司機騷擾，不要與司機正面衝突，立即聯絡相關部門。緊急按鈕功能隨時可用，以防發生任何緊急情況。</p><br /><p>在行程結束後提交一份意見，包括司機資料和事故細節。TADA 客戶支援團隊將盡快與您聯絡，以便採取後續行動。所有報告的個案都將得到公平的調查。</p><br /><p>實用資料</p><br /><p>報警：191</p><br /><p>報警網站：<a href="https://www.royalthaipolice.go.th/">https://www.royalthaipolice.go.th/</a></p><br /><p>NIEMS：1669</p><br /><p>NIEMS 網站：<a href="https://www.niems.go.th/1/Home/Main">https://www.niems.go.th/1/Home/Main/</a></p>',
    th_rider_safety_and_accident3_question: '不安全駕駛行為',
    th_rider_safety_and_accident3_answer:
      '<p>TADA 致力於為乘客和司機建立一個安全的網約車社群。如果您發現或遇到 TADA 司機不安全駕駛行為，請與我們聯絡。</p><br /><p>如需緊急援助，請與警方 (191) 聯絡</p>',
    th_rider_safety_and_accident4_question: '2019 冠狀病毒病應對措施',
    th_rider_safety_and_accident4_answer:
      '<p>出行時佩戴口罩。</p><br /><p>DLT 和 MOPH 規定，的士、PHC 司機和乘客必須戴口罩。</p><br /><p>上車前。</p><br /><p>如果您發現司機沒有戴口罩，可以取消行程。取消後向 TADA 提交意見。</p><br /><p>前往目的地途中。</p><br /><p>如果司機途中摘下口罩，請提醒司機重新戴上口罩。立即提交意見，TADA 客戶支援團隊將會進行調查。</p><br /><p>如果您在乘車時遇到任何與健康有關的問題，請透過 <a href="mailto:support_th@tada.global">support_th@tada.global</a> 聯絡 TADA 支援團隊。</p>',
    title_otp_request: '請登入您的帳戶進行驗證',
    label_mobile_number: '手機號碼',
    label_code_number: '驗證碼',
    btn_send: '傳送',
    btn_continue: '繼續',
    error_verification_code: '驗證碼不相符',
    label_account_deletion: '刪除帳戶',
    label_reason_delete_account: '刪除帳戶原因',
    hint_min_character: '最少 {{count}} 個字元',
    btn_submit: '提交',
    dialog_account_deletion_desc: '從現在起，您將無法使用 TADA 服務。',
    apply_account_deletion_title: '我們正在刪除您的帳戶。',
    apply_account_deletion_desc: '完成帳戶刪除程序可能需要幾天時間。',
    dialog_account_deletion_failure_title: '刪除帳戶失敗',
    content_delete_account_error_balance_remaining: '刪除帳戶前餘額必須為零。請在提取餘額後再試，或傳送電郵至客戶服務中心尋求幫助。',
    content_delete_account_error_exist_rider_penalty_fee:
      '由於您的帳戶存在罰款，我們無法處理您的帳戶刪除。請透過電郵聯絡我們的客戶服務團隊，我們的團隊將協助您完成刪除過程。',
    content_delete_account_error_exist_active_ride: '您目前有一個正在進行的預約，只有在完成或取消目前預約後，才能處理您的帳戶刪除。',
    label_cs_center: '客戶服務中心',
    label_notice: '通知',
    label_get_rewarded: '賺取獎勵',
    label_for_me: '我賺取的獎勵',
    label_for_my_friend: '我的好友賺取的獎勵',
    label_invite_rider: '邀請乘客並獲得獎勵',
    text_contact_description: '我們業務遍及亞太地區，我們的新加坡、柬埔寨、泰國、越南和香港辦事處隨時準備為您提供服務，歡迎在上述地區聯絡我們',
    text_contact_empty_description: '請點擊地圖上任意地點的標記以查看詳細信息',
    car_type_economy: '經濟型',
    car_type_economy_large: '經濟大型',
    car_type_premium: '豪華型',
    car_type_premium_large: '豪華大型',
    car_type_taxi: '標準的士',
    car_type_taxi_large: '標準大型的士',
    car_type_premium_taxi: '豪華的士',
    car_type_premium_taxi_large: '豪華大型的士',
    car_type_tuktuk: '篤篤車',
    car_type_bike: '單車',
    car_type_remorque: '拖車',
    car_type_delivery_van: '送貨車',
    car_type_onion_tuktuk: '電動篤篤車',
    car_type_delivery_bike: '送貨電單車',
    product_type_ph: '個人',
    product_type_taxi: '的士',
    product_type_anytada: 'AnyTADA',
    product_type_ev: 'iONTADA',
    product_seat_count: '{{seat}} 座位',
    economy_cm: '汽車',
    economy_large_cm: 'SUV',
    car_type_bus: '巴士',
    car_type_mini_bus: '小巴',
    ride_option_pet_carrier: 'TADA 寵物（寵物袋）',
    ride_option_pet_leashed: 'TADA 寵物（牽繩）',
    fare_type_app_metered_fare: '平台車資',
    fare_type_car_metered_fare: '咪錶車資',
    fare_type_fixed_fare: '定價車資',
    car_type_win_bike: 'Win 單車',
    car_type_premium_bike: 'TADA 單車 Plus',
    car_junior_type: 'TADA Junior',
    car_junior_large_type: 'TADA Junior Large',
    car_wheelchair_space_type: 'Wheelchair Space',
  },
};
