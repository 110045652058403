import React, { Component } from 'react'
import BrandingPageContainer, { BrandingPageContent } from '../../Common/BrandingPageContainer'
import AppInfo from '../../AppInfo/index'
import RiderAppBanner from './RiderAppBanner/index'
import AppDownload from '../../AppDownload/index'
import AppCarousel from '../../AppCarousel/index'

export default class TadaRiderPage extends Component {
  render() {
    // const { t } = this.props

    return (
      <BrandingPageContainer isColored>
        <BrandingPageContent>
          <RiderAppBanner />
          <AppDownload
            androidDownloadLink="https://play.google.com/store/apps/details?id=io.mvlchain.tada"
            iosDownloadLink="https://itunes.apple.com/app/tada-ride-hailing/id1412329684?ls=1&mt=8"
            huaweiDownloadLink="https://appgallery.huawei.com/app/C101817293"
          />
          <AppCarousel type="rider" />
          <AppInfo type="rider" />
        </BrandingPageContent>
      </BrandingPageContainer>
    )
  }
}
