import React, { Component } from 'react'
import styled from 'styled-components'

export const AppBannerContainer = styled.div`
  height: 106px;
  position: relative;
  display: flex;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 15px 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
  }
`

export const AppIcon = styled.img`
  width: 106px;
  height: 106px;
  margin-right: 18px;

  @media (max-width: 1000px) {
    width: 90px;
    height: 90px;
    margin: 0 0 27px;
  }
`

export const AppTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 30px;
  font-weight: normal;
  color: #0a3142;

  @media (max-width: 1000px) {
    font-size: 20px;
    text-align: center;
  }

  & > span {
    font-size: 72px;
    font-weight: 800;
    color: #0a3142;

    @media (max-width: 1000px) {
      font-size: 40px;
      font-weight: 900;
    }
  }
`