import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import createReduxWaitForMiddleware from 'redux-wait-for-action'
import rootReducer from '../reducers'
import { captureException } from '../utils/MyUtils'
import { actionSubscribeMiddleware } from './middleware/actionSubscribe'

export const sagaMiddleware = createSagaMiddleware({
  onError: err => {
    console.error && console.error(err)
    captureException(err)
  }
})

const configureStore = (preloadedState) =>
  createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(createReduxWaitForMiddleware(), thunk, sagaMiddleware, actionSubscribeMiddleware)
  )

export default configureStore
