import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import qs from 'qs'
import triangle from '../../../assets/images/tada/triangle.png'
import { TOKEN_KEYS } from '../../../constants/constants'
import { get } from '../../../utils/api'
import { getAppAuthorityFromSection, getRegionFromLocale, getLocaleContent } from './hooks'
import { decodeDescription } from '../../../utils/MyUtils'
import Loading from '../../Common/Loading'
import { useSelector } from 'react-redux'
import { getLocale } from '../../../selectors'
import { useSearch } from '../../../hooks/router'

export const DescriptionContainer = styled.div`
  width: 100%;
  white-space: pre-line;
  word-break: break-word;
  overflow-x: scroll;
`

export const DriverTable = styled.div`
  width: 100%;
  min-height: 60px;
  margin-top: 50px;
  z-index: 1;
`

export const DRow = styled.div`
  width: 100%;
`

export const DAtd = styled.div`
  padding: 15px;
  border-bottom: ${props => (props.totalCount - 1 === props.index ? '1px solid black' : 'none')};
  font-size: 16px;
  font-family: Roboto;
  word-break: break-all;
  @media (max-width: 1000px) {
    font-size: 15px;
  }

  > div > ul {
    margin-left: 20px;
  }

  .auto-accept {
    margin: 15px 0;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid rgb(233, 233, 231);
      background-color: #fff;
      padding: 10px 15px;
      font-weight: 400;
      text-align: left;
    }
  }
`

export const Dtr = styled.div`
  width: 100%;
`

export const DQtd = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 60px;
  cursor: pointer;
  padding: 10px 0 10px 16px;
  border-top: 1px solid black;
  border-bottom: ${props => (props.totalCount - 1 === props.index ? '1px solid black' : 'none')};
  font-family: Roboto;
  font-size: 16px;
  word-break: break-all;
  position: relative;
  box-sizing: border-box;
  &:after,
  &::after {
    position: absolute;
    width: 19px;
    height: 11px;
    top: 50%;
    margin-top: -5.5px;
    right: 10px;
    content: ' ';
    object-fit: contain;
    background-image: url(${triangle});
  }
  &:hover {
    background-color: rgba(192, 192, 192, 0.1);
  }
  @media (max-width: 1000px) {
    font-size: 15px;
  }

  & > div {
    margin: 0 40px 0 0;
    & > div {
      margin: 0 40px 0 0;
    }
  }
`

export const ResultMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 30px;
  font-size: 16px;
  color: #000000;
  font-weight: 300;
  font-family: Roboto;

  > span {
    font-weight: 500;
    margin: 0 3px;
  }
`

export const NoResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  font-size: 30px;
  color: #4a4a4a;
  font-weight: bold;
`

export const DQOpenedTd = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 60px;
  cursor: pointer;
  padding: 10px 0 10px 16px;
  position: relative;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  border-top: 1px solid black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  &:after,
  &::after {
    position: absolute;
    width: 19px;
    height: 11px;
    top: 50%;
    margin-top: -5.5px;
    right: 10px;
    content: ' ';
    object-fit: contain;
    background-image: url(${triangle});
    background-position: ${props => (props.isOpenedShow ? '-19px' : '0')} 0;
  }
  @media (max-width: 1000px) {
    font-size: 15px;
  }

  & > div {
    margin: 0 40px 0 0;
    & > div {
      margin: 0 40px 0 0;
    }
  }
`

function FaqTable({ topicData, currentSection, tabSection }) {
  const search = useSearch()
  const location = useLocation()
  const { section } = useParams()

  const selectedLocale = useSelector(getLocale)

  const [questionList, setQuestionList] = useState([])
  const [isQuestionOpen, setIsQuestionOpen] = useState([])
  const [isQuestionFetching, setIsQuestionFetching] = useState(false)

  const fetchQuestionList = async () => {
    try {
      setIsQuestionFetching(true)

      const listQuery = {
        appAuthority: getAppAuthorityFromSection(section),
        region: search.region || getRegionFromLocale(selectedLocale),
        topicId: !search.keyword ? search.topicId ?? topicData.id : undefined,
        locale: topicData ? topicData.contents[0].locale : selectedLocale,
        status: search.status,
        keyword: search.keyword || undefined
      }

      const result = await get(`/cssvc/v1/faq/question/list?${qs.stringify(listQuery)}`, undefined, TOKEN_KEYS.faq)

      const list = [...result.content]
      const questionOpen = Array.from({ length: result.content.length }, () => false)

      setQuestionList(list)

      if (search.questionId !== undefined) {
        document.getElementById(`question_${search.questionId}`) &&
          document.getElementById(`question_${search.questionId}`).scrollIntoView()

        const index = list.findIndex(question => question.id === +search.questionId)
        questionOpen[index] = true
      }

      setIsQuestionOpen(questionOpen)
      setIsQuestionFetching(false)
    } catch (e) {
      setQuestionList([])
      setIsQuestionFetching(false)
    }
  }

  const handleClickOpen = index => {
    const cloneData = [...isQuestionOpen]

    cloneData[index] = !cloneData[index]

    setIsQuestionOpen([...cloneData])
  }

  useEffect(() => {
    if (tabSection === currentSection) {
      fetchQuestionList()
    }
  }, [search.region, search.keyword, topicData])

  if (isQuestionFetching) {
    return <Loading />
  }

  return (
    <DriverTable>
      {search.keyword && (
        <ResultMessage>
          <span>{questionList.length}</span> articles found for keyword <span>"{search.keyword || ''}"</span>
        </ResultMessage>
      )}
      {!search.keyword && !questionList.length && <NoResult>No Results</NoResult>}
      {questionList.map((question, index) => (
        <DRow key={index}>
          <Dtr onClick={() => handleClickOpen(index)}>
            {isQuestionOpen[index] ? (
              <DQOpenedTd isOpenedShow={isQuestionOpen[index]} id={`question_${question.id}`}>
                <div dangerouslySetInnerHTML={{ __html: `Q. ${getLocaleContent(question, selectedLocale).title}` }} />
              </DQOpenedTd>
            ) : (
              <DQtd totalCount={questionList.length} index={index} id={`question_${question.id}`}>
                <div dangerouslySetInnerHTML={{ __html: `Q. ${getLocaleContent(question, selectedLocale).title}` }} />
              </DQtd>
            )}
          </Dtr>
          {isQuestionOpen[index] && (
            <Dtr>
              <DAtd totalCount={questionList.length} index={index}>
                {getLocaleContent(question, selectedLocale).dataType === 'HTML' ? (
                  <DescriptionContainer
                    dangerouslySetInnerHTML={{
                      __html: `<strong>A</strong>. ${decodeDescription(
                        getLocaleContent(question, selectedLocale).description
                      )}`
                    }}
                  />
                ) : (
                  <DescriptionContainer>
                    <strong>A. </strong>
                    {getLocaleContent(question, selectedLocale).description}
                  </DescriptionContainer>
                )}
              </DAtd>
            </Dtr>
          )}
        </DRow>
      ))}
    </DriverTable>
  )
}

export default FaqTable
