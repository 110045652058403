import React, { Component } from 'react'
import styled from 'styled-components'
import Button from '../../Common/Button'
import Link from '../../Common/Link'
import A from '../../Common/A'
import autoBind from 'react-autobind'
import hambergerIcon from '../../../assets/images/tada/combined-shape.png'
import xIcon from '../../../assets/images/tada/x-menu.png'
import { Drawer, MuiThemeProvider } from 'material-ui'
import Footer from '../../Footer'
import HeaderDropdown from '../HeaderDropdown/index'
import BackgroundImg from '../../../assets/images/tada/image-banner.png'
import vIconWhite from '../../../assets/images/tada/v_white.png'
import { DOMAIN_TYPE, getDomainType } from '../../../constants/constants'

export const HeaderDrawerMenuContainer = styled.div``

export const HamburgerBtn = styled(Button)`
  width: 25px;
  height: 23px;
  background-repeat: no-repeat;
  background-image: url(${hambergerIcon});
`

export const DrawerMenu = styled(Drawer)``

export const DrawerContainer = styled.div`
  padding: 56px 0 0;
  background-image: url(${BackgroundImg});
`

export const DrawerCloseBtn = styled(Button)`
  position: absolute;
  top: 18px;
  right: 18px;
  background-image: url(${xIcon});
  background-repeat: no-repeat;
  background-size: contain;
  width: 23px;
  height: 23px;
`

export const DrawerTopMenuUl = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0 32px 56px;
`

export const DrawerTopMenuLi = styled.li`
  display: inline-flex;
  & + & {
    margin-top: 30px;
  }
`

export const DrawerTopMenuLink = styled(Link)`
  font-size: 24px;
  font-weight: bold;
  color: #eaba28;
`

export const DrawerBottomMenuUl = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 20px 32px;
`

export const DrawerBottomMenuLi = styled.li`
  display: inline-flex;
  & + & {
    margin-top: 25px;
  }
`

export const DrawerBottomMenuLink = styled(Link)`
  font-size: 16px;
  font-weight: 500;
  color: #fff;
`

export const DrawerBottomMenuOutlink = styled(A)`
  font-size: 16px;
  font-weight: 500;
  color: #fff;
`

export const SeparateLine = styled.hr`
  width: 100%;
  opacity: 0.5;
  margin: 20px 0;
  border-bottom: solid 1px #fff;
`

export const DrawerDriverSignUpLink = styled(Link)`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  border: solid 1px #fff;
  font-size: 18px;
  font-weight: normal;
  line-height: 50px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;

  :after,
  ::after {
    position: absolute;
    width: 17px;
    height: 11px;
    background-image: url(${vIconWhite});
    background-size: contain;
    content: ' ';
    transform: rotate(270deg);
    top: 20px;
    right: 10px;
  }
`

export default class HeaderDrawerMenu extends Component {
  state = {
    isDrawer: false
  }

  constructor (props) {
    super(props)

    autoBind(this)
  }

  handleToggle = () => this.changeDrawerState(!this.state.isDrawer)

  handleClose = () => this.changeDrawerState(false)

  changeDrawerState (isDrawer) {
    this.setState({ isDrawer })
  }

  render () {
    const { isDrawer } = this.state

    return (
      <HeaderDrawerMenuContainer>
        <HamburgerBtn onClick={this.handleToggle} />
        <MuiThemeProvider>
          <DrawerMenu
            docked
            width={'100%'}
            openSecondary
            open={isDrawer}
            containerStyle={{
              background: '#0a3142'
            }}
          >
            <DrawerContainer>
              <DrawerCloseBtn onClick={this.handleClose} />
              <DrawerTopMenuUl>
                <DrawerTopMenuLi>
                  <HeaderDropdown onSelect={this.handleClose} />
                </DrawerTopMenuLi>
                {getDomainType() === DOMAIN_TYPE.TADA_DOMAIN && (
                  <DrawerTopMenuLi>
                    <DrawerTopMenuLink to={'/safety'}>Safety</DrawerTopMenuLink>
                  </DrawerTopMenuLi>
                )}
                <DrawerTopMenuLi>
                  <DrawerTopMenuLink to={'/home'}>About</DrawerTopMenuLink>
                </DrawerTopMenuLi>
                <DrawerTopMenuLi>
                  <DrawerTopMenuLink to={'/app/driver'}>Driver</DrawerTopMenuLink>
                </DrawerTopMenuLi>
                <DrawerTopMenuLi>
                  <DrawerTopMenuLink to={'/app/rider'}>Rider</DrawerTopMenuLink>
                </DrawerTopMenuLi>
                <DrawerTopMenuLi>
                  <DrawerTopMenuLink to={'/contact'}>Contact</DrawerTopMenuLink>
                </DrawerTopMenuLi>
                <DrawerTopMenuLi>
                  <DrawerTopMenuLink to={'./faq'}>FAQ</DrawerTopMenuLink>
                </DrawerTopMenuLi>
              </DrawerTopMenuUl>
              <DrawerBottomMenuUl>
                <DrawerBottomMenuLi>
                  <DrawerBottomMenuOutlink target='_blank' href='https://mvlchain.io'>
                    MVL
                  </DrawerBottomMenuOutlink>
                </DrawerBottomMenuLi>
                <DrawerBottomMenuLi>
                  <DrawerBottomMenuLink to={'/terms/term'}>Terms</DrawerBottomMenuLink>
                </DrawerBottomMenuLi>
                <DrawerBottomMenuLi>
                  <DrawerBottomMenuLink to={'/terms/privacy_policy'}>Privacy</DrawerBottomMenuLink>
                </DrawerBottomMenuLi>
                <DrawerBottomMenuLi>
                  <DrawerBottomMenuLink to={'/code_of_conduct'} style={{ marginBottom: '30px' }}>
                    Driver's Code of Conduct
                  </DrawerBottomMenuLink>
                </DrawerBottomMenuLi>
                <DrawerBottomMenuLi>
                  <DrawerBottomMenuLink to={'/community_guideline'} style={{ marginBottom: '30px' }}>
                    Community Guideline
                  </DrawerBottomMenuLink>
                </DrawerBottomMenuLi>
              </DrawerBottomMenuUl>
            </DrawerContainer>
            <Footer />
          </DrawerMenu>
        </MuiThemeProvider>
      </HeaderDrawerMenuContainer>
    )
  }
}
