import { LANGS } from '../../../i18n'

export const localeSetToLanguage = localeSet => {
  if (!localeSet) {
    return localeSet
  }
  const regex = new RegExp(`^(${LANGS.join('|')})-[A-Z]{2}$`)
  const result = localeSet.match(regex)
  return (result && result[1]) || localeSet
}
