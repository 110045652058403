import React from 'react'
import DriverTabs from './driverTabs'
import RiderTabs from './riderTabs'

export const SAFETY_PAGE_ITEMS = [
  {
    title: 'driver_safety',
    document: <DriverTabs />,
    section: 'driver',
    id: 0
  },
  {
    title: 'rider_safety',
    document: <RiderTabs />,
    section: 'rider',
    id: 1
  }
]
