import React, { useEffect, useState } from 'react'

import { translate } from 'react-i18next'
import styled from 'styled-components'

import MobileWebViewContainer from '../../Common/MobileWebViewContainer'
import { useSearch } from '../../../hooks/router'
import { getReferralRewardRules } from './util'
import Loading from '../../Common/Loading'
import heartIcon from '../../../assets/images/icn-heart.svg'
import { useToggle } from '../../../hooks/toggle'
import { decodeDescription } from '../../../utils/MyUtils'
import { USER_MODEL } from '../../../constants/constants'
import { DriverActions } from '../../../modules/driver/DriverState'
import { useDispatch } from 'react-redux'
import { LANGS } from '../../../i18n'

const DescriptionContainer = styled.div`
  margin: 0 24px;
  padding: 24px 0;
`

const ReferralRiderDescription = styled.div`
  padding: 0 16px;
  overflow-wrap: break-word;
  * > li {
    list-style-position: inside;
    li::marker {
      margin-right: 3px;
    }
  }
`

const ReferralDriverDescription = styled.div`
  overflow-wrap: break-word;
  * > li {
    list-style-position: inside;
    li::marker {
      margin-right: 3px;
    }
  }
`

const RewardTitle = styled.div`
  margin-bottom: 16px;
  text-align: center;
  color: #000;
  font-family: 'Apple SD Gothic Neo';
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
`
const RewardDriverTitle = styled.div`
  margin-bottom: 16px;
  color: #000;
  font-family: 'Apple SD Gothic Neo';
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  overflow-wrap: break-word;
`

const ForMeContainer = styled.div`
  padding: 20px 16px;
  border-radius: 16px;
  background-color: #ffc400;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  > h3 {
    margin: 0;
    margin-bottom: 8px;
    text-align: center;
    color: #000;
    font-family: 'Apple SD Gothic Neo';
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
  }
`

const ForFriendContainer = styled.div`
  padding: 20px 16px;
  border-radius: 16px;
  background-color: #edeff1;
  > h3 {
    margin: 0;
    margin-bottom: 8px;
    text-align: center;
    color: #000;
    font-family: 'Apple SD Gothic Neo';
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
  }
`

const HeartIcon = styled.img`
  display: flex;
  width: 24px;
  height: 24px;
  margin: 12px auto;
`

function Descriptions({ t }) {
  const search = useSearch()
  const dispatch = useDispatch()
  const [inviterDescription, setInviterDescription] = useState('')
  const [inviteeDescription, setInviteeDescription] = useState('')
  const [isLoading, toggle] = useToggle()

  useEffect(() => {
    const { locale } = search
    dispatch(DriverActions.changeLocaleRequest(LANGS.includes(locale) ? locale : 'en'))
  }, [search.locale])

  useEffect(() => {
    ;(async () => {
      try {
        toggle()
        const { inviterType, inviteeType, region, locale, driverType } = search

        const content = await getReferralRewardRules(inviterType, inviteeType, region, locale, driverType)

        setInviterDescription(content?.referralRewardRuleInviterDescription)
        setInviteeDescription(content?.referralRewardRuleInviteeDescription)
      } finally {
        toggle()
      }
    })()
  }, [search])

  if (isLoading) {
    return <Loading />
  }

  return (
    <MobileWebViewContainer isNavigator={false} headerSize={0} isLoading={false}>
      {search.inviterType === USER_MODEL.Rider ? (
        <DescriptionContainer>
          <RewardTitle>{t('label_get_rewarded')}</RewardTitle>
          <ForMeContainer>
            <h3>{t('label_for_me')}</h3>
            <ReferralRiderDescription dangerouslySetInnerHTML={{ __html: decodeDescription(inviterDescription) }} />
          </ForMeContainer>
          <HeartIcon src={heartIcon} alt="heartIcon" />
          <ForFriendContainer>
            <h3>{t('label_for_my_friend')}</h3>
            <ReferralRiderDescription dangerouslySetInnerHTML={{ __html: decodeDescription(inviteeDescription) }} />
          </ForFriendContainer>
        </DescriptionContainer>
      ) : (
        <DescriptionContainer>
          <RewardDriverTitle>{t('label_invite_rider')}</RewardDriverTitle>
          <ReferralDriverDescription dangerouslySetInnerHTML={{ __html: decodeDescription(inviterDescription) }} />
        </DescriptionContainer>
      )}
    </MobileWebViewContainer>
  )
}

export default translate()(Descriptions)
