import { useEffect } from 'react'

const store = {
  subscribes: [],
  addSubscribe (action, callback) {
    const item = {
      action,
      callback
    }

    this.subscribes.push(item)

    return () => {
      const index = this.subscribes.indexOf(item)

      this.subscribes.splice(index, 1)
    }
  }
}

export const useActionSubscribe = (action, callback = () => {}) => {
  useEffect(
    () => {
      // success면 payload, fail이면 action을 넘겨줌
      const unsubscribe = store.addSubscribe(action, callback)

      return unsubscribe
    },
    [action, callback]
  )
}

export const actionSubscribeMiddleware = () => next => anyAction => {
  next(anyAction)

  const { type: actionType } = anyAction

  store.subscribes.forEach(({ action, callback: actionCallback }) => {
    if (action === actionType) {
      actionCallback(anyAction)
    }
  })
}
