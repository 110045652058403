import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import styled from 'styled-components'
import Slider from '../../../Slider'
import {
  POLYGON_MESSAGE_BY_REGION,
  SUPPORT_REGION_POLYGON_PATHS,
  SUPPORT_REGIONS
} from '../../../../constants/constants'
import { Desktop, Mobile } from '../../../Common/ByScreen'
import { useSwitchInAndOut } from '../../../../hooks/useSwitchInAndOut'
import { useIsMobile } from '../../../../hooks/useIsMobile'

const ContactInfoContainer = styled.div`
  position: relative;
  display: flex;
  width: min-content;
  min-width: 420px;
  max-height: 663px;
  margin-left: 21px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 30px;
  background: #0a3142;
  @media (max-width: 1000px) {
    width: fit-content;
    min-width: 355px;
    min-height: ${({ $isMobile }) => ($isMobile ? '600px' : '548px')};
    max-width: none;
    margin: 0 auto;
    justify-content: unset;
  }

  > .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-width: 420px;
    max-height: 663px;
    padding: 30px 30px 0 30px;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    box-sizing: border-box;

    @media (max-width: 1000px) {
      position: relative;
      width: fit-content;
      min-width: 355px;
      width: 355px;
      min-height: 504px;
      height: 504px;
      max-width: none;
      margin: 0 auto;
      padding: 0 10px 10px 10px;
      justify-content: center;
    }

    > .empty {
      display: flex;
      align-items: center;
      height: 100%;
      text-align: center;
      font-size: 18px;
      color: #fff;

      @media (max-width: 1000px) {
        margin: auto 0;
        padding: 0 20px;
      }
    }

    > .region-selector {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;

      @media (max-width: 1000px) {
        padding-top: 10px;
        height: 78px;
      }

      > div {
        display: flex;
        min-width: 105px;
        min-height: 36px;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        box-shadow: 0px 4px 10px 0px rgba(10, 49, 66, 0.3);
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
        color: #fff;
        background: #3b5a68;

        > img {
          width: 20px;
          height: 20px;
          margin-right: 3px;
        }
      }
    }
  }

  > .slider-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ $isMobile }) => ($isMobile ? '10px 10px 0 10px' : '0 10px 10px 10px')};
    box-sizing: border-box;
    > .dotted-slice {
      margin: ${({ $isMobile }) => ($isMobile ? '0 0 auto 0' : 'auto 0 0 0')};
    }
  }
`

const ContactContent = styled.div`
  position: absolute;
  top: ${({ $isMobile }) => ($isMobile ? '0px' : '30px')};
  left: 30px;
  display: flex;
  width: calc(100% - 60px);
  height: calc(100% - 109px);
  flex-direction: column;
  justify-content: start;
  gap: ${({ $isMobile }) => ($isMobile ? '14px' : '20px')};
  color: #fff;
  z-index: ${({ $cursor }) => `${$cursor}`};

  @media (max-width: 1000px) {
    height: 100%;
    min-height: 522px;
    margin-top: 20px;
  }

  > .region {
    > h4 {
      margin: 0;
      font-size: ${({ $isMobile }) => ($isMobile ? '26px' : '30px')};
      font-weight: 700;
    }
  }

  > hr {
    width: 100%;
    height: 1px;
    margin: 0;
    border: 0;
    background: rgba(255, 255, 255, 0.2);
  }

  > .section {
    &.first {
      margin-top: 10px;
    }

    > .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    > .label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    > .description {
      margin-top: 12px;
      font-size: ${({ $isMobile }) => ($isMobile ? '10px' : '12px')};
      font-weight: 400;
      line-height: 16px;
      white-space: pre-line;
    }
  }

  > .sns-list {
    width: 100%;
    margin-top: auto;
    min-height: 63px;
    overflow: visible;

    @media (max-width: 1000px) {
      margin: auto 0 20px 0;
    }

    > div {
      display: flex;
      width: 100%;
      flex-direction: row;
      gap: 14px;

      > a {
        display: flex;
        width: 26px;
        height: 26px;
        justify-content: center;
        padding-bottom: 5px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          border-bottom: 3px solid rgba(255, 255, 255, 0.4);
        }

        > i {
          width: 26px;
          height: 26px;
          display: flex;
          justify-content: center;
          font-size: 26px;
          color: #fff;
          box-sizing: border-box;

          &.fa-facebook {
            font-size: 24px;
          }

          &.fa-tiktok {
            font-size: 23px;
          }
        }
      }
    }
  }
`

function ContactInfo({ t, data, regionId, isLoading, onChangeRegion }) {
  const [contactInfos, setContactInfos] = useState('')

  const { refs, cursor, applySwitchInElements, applySwitchOutAnimation, increaseCursor } = useSwitchInAndOut()
  const isMobile = useIsMobile()

  const contactInfo = data?.contents.at(0)
  const cursorValue = cursor.current === 0 ? 1 : 0

  useEffect(() => {
    if (!contactInfo?.description) {
      return
    }

    if (contactInfos) {
      // 현재 cursor가 아닌 Contact에 out animation 처리
      applySwitchOutAnimation(refs[cursor.current].current)
    }

    increaseCursor()

    const currentElements = applySwitchInElements(decodeURIComponent(window.atob(contactInfo.description)))
    setContactInfos(currentElements)
    refs[cursor.current].current.innerHTML = currentElements
  }, [contactInfo, regionId])

  return (
    <ContactInfoContainer $isMobile={isMobile}>
      <Mobile>
        {regionId !== null && (
          <div className="slider-wrapper">
            <Slider total={SUPPORT_REGIONS.length} current={regionId} onChange={onChangeRegion} />
          </div>
        )}
      </Mobile>
      <div className="content">
        <Desktop>
          {!isLoading && !contactInfo && <div className="empty">{t('text_contact_empty_description')}</div>}
        </Desktop>
        <Mobile>
          {regionId === null && (
            <div className="region-selector">
              {SUPPORT_REGION_POLYGON_PATHS.map((poly, regionIdx) => (
                <div onClick={() => onChangeRegion(regionIdx)} key={regionIdx}>
                  {POLYGON_MESSAGE_BY_REGION[poly.region]}
                </div>
              ))}
            </div>
          )}
          {!isLoading && !contactInfo && <div className="empty">{t('text_contact_empty_description')}</div>}
        </Mobile>
        {contactInfo && (
          <>
            <ContactContent ref={refs[0]} $cursor={cursorValue} $isMobile={isMobile} />
            <ContactContent ref={refs[1]} $cursor={1 - cursorValue} $isMobile={isMobile} />
          </>
        )}
      </div>
      <Desktop>
        <div className="slider-wrapper">
          <Slider total={SUPPORT_REGIONS.length} current={regionId} onChange={onChangeRegion} />
        </div>
      </Desktop>
    </ContactInfoContainer>
  )
}

export default translate()(ContactInfo)
