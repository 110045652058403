import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import createReduxWaitForMiddleware from 'redux-wait-for-action'
import rootReducer from '../reducers'
import { actionSubscribeMiddleware } from './middleware/actionSubscribe'

export const sagaMiddleware = createSagaMiddleware()

const middlewares = [createReduxWaitForMiddleware(), thunk, sagaMiddleware, createLogger(), actionSubscribeMiddleware]

const configureStore = (preloadedState) => {
  const composeEnhancers = compose

  const enhancer = composeEnhancers(applyMiddleware(...middlewares))

  const store = createStore(rootReducer, preloadedState, enhancer)

  return store
}

export default configureStore
