export const ALL_POLYGON_PATHS = [
  'M124.389 311.631V303.014L116.929 298.711L109.47 303.014V311.631L116.929 315.934L124.389 311.631Z',
  'M109.47 311.631V303.014L102.01 298.711L94.5504 303.014V311.631L102.01 315.934L109.47 311.631Z',
  'M94.5503 311.631V303.014L87.0907 298.711L79.6311 303.014V311.631L87.0907 315.934L94.5503 311.631Z',
  'M79.6312 311.631V303.014L72.1716 298.711L64.712 303.014V311.631L72.1716 315.934L79.6312 311.631Z',
  'M116.929 324.691V316.074L109.47 311.772L102.01 316.074V324.691L109.47 329.006L116.929 324.691Z',
  'M102.01 324.691V316.074L94.5503 311.772L87.0907 316.074V324.691L94.5503 329.006L102.01 324.691Z',
  'M87.0906 324.691V316.074L79.631 311.772L72.1714 316.074V324.691L79.631 329.006L87.0906 324.691Z',
  'M109.47 337.635V329.018L102.01 324.703L94.5504 329.018V337.635L102.01 341.937L109.47 337.635Z',
  'M94.5503 337.635V329.018L87.0907 324.703L79.6311 329.018V337.635L87.0907 341.937L94.5503 337.635Z',
  'M79.6312 337.635V329.018L72.1716 324.703L64.712 329.018V337.635L72.1716 341.937L79.6312 337.635Z',
  'M116.929 350.59V341.973L109.47 337.67L102.01 341.973V350.59L109.47 354.892L116.929 350.59Z',
  'M102.01 350.59V341.973L94.5503 337.67L87.0907 341.973V350.59L94.5503 354.892L102.01 350.59Z',
  'M109.47 363.638V355.021L102.01 350.707L94.5504 355.021V363.638L102.01 367.941L109.47 363.638Z',
  'M94.5503 363.638V355.021L87.0907 350.707L79.6311 355.021V363.638L87.0907 367.941L94.5503 363.638Z',
  'M102.01 376.476V367.871L94.5503 363.556L87.0907 367.871V376.476L94.5503 380.791L102.01 376.476Z',
  'M87.0906 376.476V367.871L79.631 363.556L72.1714 367.871V376.476L79.631 380.791L87.0906 376.476Z',
  'M109.47 389.641V381.024L102.01 376.71L94.5504 381.024V389.641L102.01 393.944L109.47 389.641Z',
  'M94.5503 389.641V381.024L87.0907 376.71L79.6311 381.024V389.641L87.0907 393.944L94.5503 389.641Z',
  'M102.01 402.374V393.757L94.5503 389.455L87.0907 393.757V402.374L94.5503 406.689L102.01 402.374Z',
  'M87.0906 350.59V341.973L79.631 337.67L72.1714 341.973V350.59L79.631 354.892L87.0906 350.59Z',
  'M72.1714 324.691V316.074L64.7118 311.772L57.2405 316.074V324.691L64.7118 329.006L72.1714 324.691Z',
  'M64.7122 311.631V303.014L57.2409 298.711L49.7812 303.014V311.631L57.2409 315.934L64.7122 311.631Z',
  'M228.847 311.631V303.014L221.387 298.711L213.927 303.014V311.631L221.387 315.934L228.847 311.631Z',
  'M243.766 311.631V303.014L236.306 298.711L228.846 303.014V311.631L236.306 315.934L243.766 311.631Z',
  'M258.697 311.631V303.014L251.225 298.711L243.766 303.014V311.631L251.225 315.934L258.697 311.631Z',
  'M236.306 324.551V315.934L228.847 311.62L221.387 315.934V324.551L228.847 328.854L236.306 324.551Z',
  'M213.928 311.631V303.014L206.468 298.711L199.008 303.014V311.631L206.468 315.934L213.928 311.631Z',
  'M199.008 311.631V303.014L191.548 298.711L184.077 303.014V311.631L191.548 315.934L199.008 311.631Z',
  'M184.077 311.631V303.014L176.618 298.711L169.158 303.014V311.631L176.618 315.934L184.077 311.631Z',
  'M221.387 324.691V316.074L213.927 311.772L206.468 316.074V324.691L213.927 329.006L221.387 324.691Z',
  'M243.766 337.611V328.994L236.306 324.691L228.846 328.994V337.611L236.306 341.914L243.766 337.611Z',
  'M206.468 324.691V316.074L199.008 311.772L191.549 316.074V324.691L199.008 329.006L206.468 324.691Z',
  'M191.549 324.691V316.074L184.077 311.772L176.618 316.074V324.691L184.077 329.006L191.549 324.691Z',
  'M213.928 337.635V329.018L206.468 324.703L199.008 329.018V337.635L206.468 341.937L213.928 337.635Z',
  'M228.847 337.635V329.018L221.387 324.703L213.927 329.018V337.635L221.387 341.937L228.847 337.635Z',
  'M199.008 337.635V329.018L191.548 324.703L184.077 329.018V337.635L191.548 341.937L199.008 337.635Z',
  'M221.387 350.566V341.949L213.927 337.647L206.468 341.949V350.566L213.927 354.881L221.387 350.566Z',
  'M236.306 350.566V341.949L228.847 337.647L221.387 341.949V350.566L228.847 354.881L236.306 350.566Z',
  'M251.225 350.566V341.949L243.766 337.647L236.306 341.949V350.566L243.766 354.881L251.225 350.566Z',
  'M206.468 350.566V341.949L199.008 337.647L191.549 341.949V350.566L199.008 354.881L206.468 350.566Z',
  'M228.847 363.638V355.021L221.387 350.707L213.927 355.021V363.638L221.387 367.941L228.847 363.638Z',
  'M243.766 363.638V355.021L236.306 350.707L228.846 355.021V363.638L236.306 367.941L243.766 363.638Z',
  'M258.697 363.638V355.021L251.225 350.707L243.766 355.021V363.638L251.225 367.941L258.697 363.638Z',
  'M251.225 376.476V367.871L243.766 363.556L236.306 367.871V376.476L243.766 380.791L251.225 376.476Z',
  'M236.306 376.476V367.871L228.847 363.556L221.387 367.871V376.476L228.847 380.791L236.306 376.476Z',
  'M266.156 376.476V367.871L258.697 363.556L251.226 367.871V376.476L258.697 380.791L266.156 376.476Z',
  'M251.225 324.551V315.934L243.766 311.62L236.306 315.934V324.551L243.766 328.854L251.225 324.551Z',
  'M266.133 324.551V315.934L258.673 311.62L251.214 315.934V324.551L258.673 328.854L266.133 324.551Z',
  'M258.697 337.611V328.994L251.225 324.691L243.766 328.994V337.611L251.225 341.914L258.697 337.611Z',
  'M273.616 337.611V328.994L266.156 324.691L258.697 328.994V337.611L266.156 341.914L273.616 337.611Z',
  'M266.156 350.566V341.949L258.697 337.647L251.226 341.949V350.566L258.697 354.881L266.156 350.566Z',
  'M281.064 350.566V341.949L273.604 337.647L266.133 341.949V350.566L273.604 354.881L281.064 350.566Z',
  'M273.616 363.638V355.021L266.156 350.707L258.697 355.021V363.638L266.156 367.941L273.616 363.638Z',
  'M258.697 389.641V381.024L251.225 376.71L243.766 381.024V389.641L251.225 393.944L258.697 389.641Z',
  'M213.928 363.638V355.021L206.468 350.707L199.008 355.021V363.638L206.468 367.941L213.928 363.638Z',
  'M199.008 363.638V355.021L191.548 350.707L184.077 355.021V363.638L191.548 367.941L199.008 363.638Z',
  'M206.468 376.441V367.824L199.008 363.521L191.549 367.824V376.441L199.008 380.755L206.468 376.441Z',
  'M199.008 389.63V381.024L191.548 376.71L184.077 381.024V389.63L191.548 393.944L199.008 389.63Z',
  'M206.468 402.316V393.699L199.008 389.396L191.549 393.699V402.316L199.008 406.619L206.468 402.316Z',
  'M213.928 415.259V406.642L206.468 402.339L199.008 406.642V415.259L206.468 419.574L213.928 415.259Z',
  'M199.008 415.259V406.642L191.548 402.339L184.077 406.642V415.259L191.548 419.574L199.008 415.259Z',
  'M184.077 415.259V406.642L176.618 402.339L169.158 406.642V415.259L176.618 419.574L184.077 415.259Z',
  'M236.306 428.401V419.784L228.847 415.47L221.387 419.784V428.401L228.847 432.704L236.306 428.401Z',
  'M221.387 428.05V419.433L213.927 415.131L206.468 419.433V428.05L213.927 432.365L221.387 428.05Z',
  'M243.766 441.204V432.587L236.306 428.273L228.846 432.587V441.204L236.306 445.507L243.766 441.204Z',
  'M243.766 466.892V458.275L236.306 453.972L228.846 458.275V466.892L236.306 471.194L243.766 466.892Z',
  'M228.847 466.892V458.275L221.387 453.972L213.927 458.275V466.892L221.387 471.194L228.847 466.892Z',
  'M213.928 466.892V458.275L206.468 453.972L199.008 458.275V466.892L206.468 471.194L213.928 466.892Z',
  'M251.225 479.765V471.159L243.766 466.845L236.306 471.159V479.765L243.766 484.079L251.225 479.765Z',
  'M236.306 479.765V471.159L228.847 466.845L221.387 471.159V479.765L228.847 484.079L236.306 479.765Z',
  'M221.387 479.765V471.159L213.927 466.845L206.468 471.159V479.765L213.927 484.079L221.387 479.765Z',
  'M258.697 492.579V483.962L251.225 479.66L243.766 483.962V492.579L251.225 496.894L258.697 492.579Z',
  'M273.616 492.579V483.962L266.156 479.66L258.697 483.962V492.579L266.156 496.894L273.616 492.579Z',
  'M243.766 492.579V483.962L236.306 479.66L228.846 483.962V492.579L236.306 496.894L243.766 492.579Z',
  'M281.076 505.488V496.87L273.616 492.568L266.156 496.87V505.488L273.616 509.802L281.076 505.488Z',
  'M266.156 505.488V496.87L258.697 492.568L251.226 496.87V505.488L258.697 509.802L266.156 505.488Z',
  'M310.914 505.488V496.87L303.454 492.568L295.995 496.87V505.488L303.454 509.802L310.914 505.488Z',
  'M295.995 505.488V496.87L288.535 492.568L281.076 496.87V505.488L288.535 509.802L295.995 505.488Z',
  'M340.764 505.488V496.87L333.304 492.568L325.845 496.87V505.488L333.304 509.802L340.764 505.488Z',
  'M325.844 505.488V496.87L318.385 492.568L310.914 496.87V505.488L318.385 509.802L325.844 505.488Z',
  'M370.602 505.488V496.87L363.143 492.568L355.683 496.87V505.488L363.143 509.802L370.602 505.488Z',
  'M355.683 505.488V496.87L348.224 492.568L340.764 496.87V505.488L348.224 509.802L355.683 505.488Z',
  'M363.119 492.579V483.962L355.66 479.66L348.188 483.962V492.579L355.66 496.894L363.119 492.579Z',
  'M392.958 492.579V483.962L385.498 479.66L378.039 483.962V492.579L385.498 496.894L392.958 492.579Z',
  'M378.039 492.579V483.962L370.579 479.66L363.12 483.962V492.579L370.579 496.894L378.039 492.579Z',
  'M228.847 441.204V432.587L221.387 428.273L213.927 432.587V441.204L221.387 445.507L228.847 441.204Z',
  'M206.468 428.05V419.433L199.008 415.131L191.549 419.433V428.05L199.008 432.365L206.468 428.05Z',
  'M176.618 324.691V316.074L169.158 311.772L161.699 316.074V324.691L169.158 329.006L176.618 324.691Z',
  'M169.158 311.631V303.014L161.698 298.711L154.239 303.014V311.631L161.698 315.934L169.158 311.631Z',
  'M116.929 298.524V289.907L109.47 285.605L102.01 289.907V298.524L109.47 302.827L116.929 298.524Z',
  'M131.86 298.524V289.907L124.389 285.605L116.929 289.907V298.524L124.389 302.827L131.86 298.524Z',
  'M146.779 298.524V289.907L139.319 285.605L131.86 289.907V298.524L139.319 302.827L146.779 298.524Z',
  'M102.01 298.524V289.907L94.5503 285.605L87.0907 289.907V298.524L94.5503 302.827L102.01 298.524Z',
  'M87.0906 298.524V289.907L79.631 285.605L72.1714 289.907V298.524L79.631 302.827L87.0906 298.524Z',
  'M72.1714 298.524V289.907L64.7118 285.605L57.2405 289.907V298.524L64.7118 302.827L72.1714 298.524Z',
  'M57.2406 298.524V289.907L49.781 285.605L42.3214 289.907V298.524L49.781 302.827L57.2406 298.524Z',
  'M42.3213 298.524V289.907L34.8617 285.605L27.4021 289.907V298.524L34.8617 302.827L42.3213 298.524Z',
  'M236.306 298.524V289.907L228.847 285.605L221.387 289.907V298.524L228.847 302.827L236.306 298.524Z',
  'M251.225 298.524V289.907L243.766 285.605L236.306 289.907V298.524L243.766 302.827L251.225 298.524Z',
  'M281.076 272.591V263.974L273.616 259.672L266.156 263.974V272.591L273.616 276.906L281.076 272.591Z',
  'M273.616 285.628V277.011L266.156 272.708L258.697 277.011V285.628L266.156 289.931L273.616 285.628Z',
  'M340.764 324.329V315.712L333.304 311.398L325.845 315.712V324.329L333.304 328.632L340.764 324.329Z',
  'M340.764 298.057V289.44L333.304 285.137L325.845 289.44V298.057L333.304 302.36L340.764 298.057Z',
  'M333.305 337.389V328.772L325.845 324.469L318.385 328.772V337.389L325.845 341.704L333.305 337.389Z',
  'M348.224 337.389V328.772L340.764 324.469L333.304 328.772V337.389L340.764 341.704L348.224 337.389Z',
  'M355.683 350.333V341.715L348.224 337.401L340.764 341.715V350.333L348.224 354.635L355.683 350.333Z',
  'M370.602 350.333V341.715L363.143 337.401L355.683 341.715V350.333L363.143 354.635L370.602 350.333Z',
  'M348.224 363.264V354.647L340.764 350.344L333.304 354.647V363.264L340.764 367.567L348.224 363.264Z',
  'M363.143 363.264V354.647L355.683 350.344L348.224 354.647V363.264L355.683 367.567L363.143 363.264Z',
  'M355.683 376.09V367.473L348.224 363.171L340.764 367.473V376.09L348.224 380.405L355.683 376.09Z',
  'M370.602 376.09V367.473L363.143 363.171L355.683 367.473V376.09L363.143 380.405L370.602 376.09Z',
  'M333.305 311.222V302.605L325.845 298.302L318.385 302.605V311.222L325.845 315.525L333.305 311.222Z',
  'M348.224 311.222V302.605L340.764 298.302L333.304 302.605V311.222L340.764 315.525L348.224 311.222Z',
  'M407.456 77.9284V69.3113L399.997 65.0086L392.525 69.3113V77.9284L399.997 82.2428L407.456 77.9284Z',
  'M392.443 77.9284V69.3113L384.984 65.0086L377.524 69.3113V77.9284L384.984 82.2428L392.443 77.9284Z',
  'M422.375 77.9284V69.3113L414.916 65.0086L407.456 69.3113V77.9284L414.916 82.2428L422.375 77.9284Z',
  'M407.456 51.8431V43.226L399.997 38.9233L392.525 43.226V51.8431L399.997 56.1458L407.456 51.8431Z',
  'M414.916 90.7781V82.1609L407.456 77.8582L399.997 82.1609V90.7781L407.456 95.0925L414.916 90.7781Z',
  'M429.824 90.7781V82.1609L422.364 77.8582L414.904 82.1609V90.7781L422.364 95.0925L429.824 90.7781Z',
  'M422.375 103.756V95.1391L414.916 90.8364L407.456 95.1391V103.756L414.916 108.071L422.375 103.756Z',
  'M407.363 103.756V95.1391L399.903 90.8364L392.444 95.1391V103.756L399.903 108.071L407.363 103.756Z',
  'M437.294 103.756V95.1391L429.835 90.8364L422.375 95.1391V103.756L429.835 108.071L437.294 103.756Z',
  'M414.916 116.828V108.211L407.456 103.908L399.997 108.211V116.828L407.456 121.131L414.916 116.828Z',
  'M429.835 116.828V108.211L422.376 103.908L414.916 108.211V116.828L422.376 121.131L429.835 116.828Z',
  'M407.363 129.701V121.084L399.903 116.77L392.444 121.084V129.701L399.903 134.004L407.363 129.701Z',
  'M399.997 64.9149V56.2977L392.526 51.995L385.066 56.2977V64.9149L392.526 69.2176L399.997 64.9149Z',
  'M414.916 64.9149V56.2977L407.456 51.995L399.997 56.2977V64.9149L407.456 69.2176L414.916 64.9149Z',
  'M221.387 298.524V289.907L213.927 285.605L206.468 289.907V298.524L213.927 302.827L221.387 298.524Z',
  'M206.468 298.524V289.907L199.008 285.605L191.549 289.907V298.524L199.008 302.827L206.468 298.524Z',
  'M191.549 298.524V289.907L184.077 285.605L176.618 289.907V298.524L184.077 302.827L191.549 298.524Z',
  'M176.618 298.524V289.907L169.158 285.605L161.699 289.907V298.524L169.158 302.827L176.618 298.524Z',
  'M161.698 298.524V289.907L154.239 285.605L146.779 289.907V298.524L154.239 302.827L161.698 298.524Z',
  'M109.47 285.628V277.011L102.01 272.708L94.5504 277.011V285.628L102.01 289.931L109.47 285.628Z',
  'M124.389 285.628V277.011L116.929 272.708L109.47 277.011V285.628L116.929 289.931L124.389 285.628Z',
  'M139.32 285.628V277.011L131.86 272.708L124.389 277.011V285.628L131.86 289.931L139.32 285.628Z',
  'M94.5503 285.628V277.011L87.0907 272.708L79.6311 277.011V285.628L87.0907 289.931L94.5503 285.628Z',
  'M79.6312 285.628V277.011L72.1716 272.708L64.712 277.011V285.628L72.1716 289.931L79.6312 285.628Z',
  'M64.7122 285.628V277.011L57.2409 272.708L49.7812 277.011V285.628L57.2409 289.931L64.7122 285.628Z',
  'M49.7811 285.628V277.011L42.3215 272.708L34.8619 277.011V285.628L42.3215 289.931L49.7811 285.628Z',
  'M34.8617 285.628V277.011L27.4021 272.708L19.9425 277.011V285.628L27.4021 289.931L34.8617 285.628Z',
  'M228.847 285.628V277.011L221.387 272.708L213.927 277.011V285.628L221.387 289.931L228.847 285.628Z',
  'M243.766 285.628V277.011L236.306 272.708L228.846 277.011V285.628L236.306 289.931L243.766 285.628Z',
  'M213.928 285.628V277.011L206.468 272.708L199.008 277.011V285.628L206.468 289.931L213.928 285.628Z',
  'M310.914 427.536V418.919L303.454 414.616L295.995 418.919V427.536L303.454 431.85L310.914 427.536Z',
  'M325.844 427.536V418.919L318.385 414.616L310.914 418.919V427.536L318.385 431.85L325.844 427.536Z',
  'M355.859 427.536V418.919L348.399 414.616L340.939 418.919V427.536L348.399 431.839L355.859 427.536Z',
  'M295.995 427.536V418.919L288.535 414.616L281.076 418.919V427.536L288.535 431.85L295.995 427.536Z',
  'M303.454 440.608V431.991L295.995 427.676L288.535 431.991V440.608L295.995 444.91L303.454 440.608Z',
  'M318.385 440.608V431.991L310.914 427.676L303.454 431.991V440.608L310.914 444.91L318.385 440.608Z',
  'M348.224 440.608V431.991L340.764 427.676L333.304 431.991V440.608L340.764 444.91L348.224 440.608Z',
  'M288.535 440.608V431.991L281.076 427.676L273.616 431.991V440.608L281.076 444.91L288.535 440.608Z',
  'M295.995 453.539V444.922L288.535 440.619L281.076 444.922V453.539L288.535 457.854L295.995 453.539Z',
  'M310.914 453.539V444.922L303.454 440.619L295.995 444.922V453.539L303.454 457.854L310.914 453.539Z',
  'M325.844 453.539V444.922L318.385 440.619L310.914 444.922V453.539L318.385 457.854L325.844 453.539Z',
  'M340.764 453.539V444.922L333.304 440.619L325.845 444.922V453.539L333.304 457.854L340.764 453.539Z',
  'M303.454 466.447V457.83L295.995 453.516L288.535 457.83V466.447L295.995 470.75L303.454 466.447Z',
  'M303.454 518.501V509.884L295.995 505.581L288.535 509.884V518.501L295.995 522.804L303.454 518.501Z',
  'M333.305 518.501V509.884L325.845 505.581L318.385 509.884V518.501L325.845 522.804L333.305 518.501Z',
  'M318.385 466.447V457.83L310.914 453.516L303.454 457.83V466.447L310.914 470.75L318.385 466.447Z',
  'M333.305 466.447V457.83L325.845 453.516L318.385 457.83V466.447L325.845 470.75L333.305 466.447Z',
  'M348.224 466.447V457.83L340.764 453.516L333.304 457.83V466.447L340.764 470.75L348.224 466.447Z',
  'M363.143 440.608V431.991L355.683 427.676L348.224 431.991V440.608L355.683 444.91L363.143 440.608Z',
  'M378.062 440.608V431.991L370.603 427.676L363.143 431.991V440.608L370.603 444.91L378.062 440.608Z',
  'M378.062 414.394V405.777L370.603 401.474L363.143 405.777V414.394L370.603 418.697L378.062 414.394Z',
  'M392.993 440.608V431.991L385.533 427.676L378.062 431.991V440.608L385.533 444.91L392.993 440.608Z',
  'M355.683 453.539V444.922L348.224 440.619L340.764 444.922V453.539L348.224 457.854L355.683 453.539Z',
  'M370.602 453.539V444.922L363.143 440.619L355.683 444.922V453.539L363.143 457.854L370.602 453.539Z',
  'M370.602 427.536V418.919L363.143 414.616L355.683 418.919V427.536L363.143 431.839L370.602 427.536Z',
  'M385.335 427.536V418.919L377.875 414.616L370.404 418.919V427.536L377.875 431.839L385.335 427.536Z',
  'M400.254 427.536V418.919L392.794 414.616L385.335 418.919V427.536L392.794 431.839L400.254 427.536Z',
  'M385.533 453.539V444.922L378.062 440.619L370.602 444.922V453.539L378.062 457.854L385.533 453.539Z',
  'M400.452 453.691V445.086L392.993 440.771L385.533 445.086V453.691L392.993 458.006L400.452 453.691Z',
  'M415.442 453.691V445.086L407.971 440.771L400.511 445.086V453.691L407.971 458.006L415.442 453.691Z',
  'M445.222 453.691V445.086L437.75 440.771L430.291 445.086V453.691L437.75 458.006L445.222 453.691Z',
  'M460.141 453.691V445.086L452.682 440.771L445.222 445.086V453.691L452.682 458.006L460.141 453.691Z',
  'M475.06 453.691V445.086L467.601 440.771L460.141 445.086V453.691L467.601 458.006L475.06 453.691Z',
  'M489.979 453.691V445.086L482.52 440.771L475.06 445.086V453.691L482.52 458.006L489.979 453.691Z',
  'M482.52 440.42V431.803L475.06 427.501L467.601 431.803V440.42L475.06 444.723L482.52 440.42Z',
  'M460.141 427.536V418.919L452.682 414.616L445.222 418.919V427.536L452.682 431.85L460.141 427.536Z',
  'M445.222 427.536V418.919L437.75 414.616L430.291 418.919V427.536L437.75 431.85L445.222 427.536Z',
  'M497.439 466.447V457.83L489.98 453.516L482.52 457.83V466.447L489.98 470.75L497.439 466.447Z',
  'M512.37 466.447V457.83L504.899 453.516L497.439 457.83V466.447L504.899 470.75L512.37 466.447Z',
  'M527.289 466.447V457.83L519.83 453.516L512.37 457.83V466.447L519.83 470.75L527.289 466.447Z',
  'M452.682 466.635V458.017L445.222 453.715L437.751 458.017V466.635L445.222 470.937L452.682 466.635Z',
  'M415.325 479.601V470.984L407.865 466.681L400.406 470.984V479.601L407.865 483.904L415.325 479.601Z',
  'M400.452 479.601V470.984L392.993 466.681L385.533 470.984V479.601L392.993 483.904L400.452 479.601Z',
  'M467.601 466.635V458.017L460.141 453.715L452.681 458.017V466.635L460.141 470.937L467.601 466.635Z',
  'M482.52 466.635V458.017L475.06 453.715L467.601 458.017V466.635L475.06 470.937L482.52 466.635Z',
  'M475.06 479.601V470.984L467.601 466.681L460.141 470.984V479.601L467.601 483.904L475.06 479.601Z',
  'M489.979 479.601V470.984L482.52 466.681L475.06 470.984V479.601L482.52 483.904L489.979 479.601Z',
  'M504.899 479.601V470.984L497.439 466.681L489.979 470.984V479.601L497.439 483.904L504.899 479.601Z',
  'M519.83 479.601V470.984L512.37 466.681L504.899 470.984V479.601L512.37 483.904L519.83 479.601Z',
  'M482.52 492.346V483.74L475.06 479.426L467.601 483.74V492.346L475.06 496.66L482.52 492.346Z',
  'M534.749 479.601V470.984L527.289 466.681L519.83 470.984V479.601L527.289 483.904L534.749 479.601Z',
  'M549.668 479.601V470.984L542.209 466.681L534.749 470.984V479.601L542.209 483.904L549.668 479.601Z',
  'M534.749 504.844V496.227L527.289 491.913L519.83 496.227V504.844L527.289 509.147L534.749 504.844Z',
  'M549.668 504.844V496.227L542.209 491.913L534.749 496.227V504.844L542.209 509.147L549.668 504.844Z',
  'M564.587 504.844V496.227L557.128 491.913L549.668 496.227V504.844L557.128 509.147L564.587 504.844Z',
  'M542.208 492.346V483.74L534.749 479.426L527.289 483.74V492.346L534.749 496.66L542.208 492.346Z',
  'M557.128 492.346V483.74L549.668 479.426L542.208 483.74V492.346L549.668 496.66L557.128 492.346Z',
  'M318.385 414.429V405.823L310.914 401.509L303.454 405.823V414.429L310.914 418.743L318.385 414.429Z',
  'M333.305 414.429V405.823L325.845 401.509L318.385 405.823V414.429L325.845 418.743L333.305 414.429Z',
  'M303.454 414.429V405.823L295.995 401.509L288.535 405.823V414.429L295.995 418.743L303.454 414.429Z',
  'M325.844 401.533V392.927L318.385 388.613L310.914 392.927V401.533L318.385 405.847L325.844 401.533Z',
  'M199.008 285.628V277.011L191.548 272.708L184.077 277.011V285.628L191.548 289.931L199.008 285.628Z',
  'M184.077 285.628V277.011L176.618 272.708L169.158 277.011V285.628L176.618 289.931L184.077 285.628Z',
  'M169.158 285.628V277.011L161.698 272.708L154.239 277.011V285.628L161.698 289.931L169.158 285.628Z',
  'M154.239 285.628V277.011L146.779 272.708L139.32 277.011V285.628L146.779 289.931L154.239 285.628Z',
  'M102.01 272.591V263.974L94.5503 259.672L87.0907 263.974V272.591L94.5503 276.906L102.01 272.591Z',
  'M116.929 272.591V263.974L109.47 259.672L102.01 263.974V272.591L109.47 276.906L116.929 272.591Z',
  'M131.86 272.591V263.974L124.389 259.672L116.929 263.974V272.591L124.389 276.906L131.86 272.591Z',
  'M87.0906 272.591V263.974L79.631 259.672L72.1714 263.974V272.591L79.631 276.906L87.0906 272.591Z',
  'M72.1714 272.591V263.974L64.7118 259.672L57.2405 263.974V272.591L64.7118 276.906L72.1714 272.591Z',
  'M57.2406 272.591V263.974L49.781 259.672L42.3214 263.974V272.591L49.781 276.906L57.2406 272.591Z',
  'M42.3213 272.591V263.974L34.8617 259.672L27.4021 263.974V272.591L34.8617 276.906L42.3213 272.591Z',
  'M27.402 272.591V263.974L19.9424 259.672L12.4828 263.974V272.591L19.9424 276.906L27.402 272.591Z',
  'M221.387 272.591V263.974L213.927 259.672L206.468 263.974V272.591L213.927 276.906L221.387 272.591Z',
  'M236.306 272.591V263.974L228.847 259.672L221.387 263.974V272.591L228.847 276.906L236.306 272.591Z',
  'M251.225 272.591V263.974L243.766 259.672L236.306 263.974V272.591L243.766 276.906L251.225 272.591Z',
  'M206.468 272.591V263.974L199.008 259.672L191.549 263.974V272.591L199.008 276.906L206.468 272.591Z',
  'M191.549 272.591V263.974L184.077 259.672L176.618 263.974V272.591L184.077 276.906L191.549 272.591Z',
  'M176.618 272.591V263.974L169.158 259.672L161.699 263.974V272.591L169.158 276.906L176.618 272.591Z',
  'M161.698 272.591V263.974L154.239 259.672L146.779 263.974V272.591L154.239 276.906L161.698 272.591Z',
  'M430.466 661.414V652.797L423.006 648.495L415.547 652.797V661.414L423.006 665.729L430.466 661.414Z',
  'M445.397 661.414V652.797L437.938 648.495L430.466 652.797V661.414L437.938 665.729L445.397 661.414Z',
  'M460.316 661.414V652.797L452.857 648.495L445.397 652.797V661.414L452.857 665.729L460.316 661.414Z',
  'M475.236 661.414V652.797L467.776 648.495L460.316 652.797V661.414L467.776 665.729L475.236 661.414Z',
  'M490.155 661.414V652.797L482.695 648.495L475.236 652.797V661.414L482.695 665.729L490.155 661.414Z',
  'M505.086 661.414V652.797L497.626 648.495L490.155 652.797V661.414L497.626 665.729L505.086 661.414Z',
  'M415.547 661.414V652.797L408.088 648.495L400.628 652.797V661.414L408.088 665.729L415.547 661.414Z',
  'M400.628 661.414V652.797L393.168 648.495L385.708 652.797V661.414L393.168 665.729L400.628 661.414Z',
  'M385.709 661.414V652.797L378.249 648.495L370.79 652.797V661.414L378.249 665.729L385.709 661.414Z',
  'M423.007 674.486V665.869L415.547 661.566L408.088 665.869V674.486L415.547 678.789L423.007 674.486Z',
  'M437.938 674.486V665.869L430.466 661.566L423.007 665.869V674.486L430.466 678.789L437.938 674.486Z',
  'M452.857 674.486V665.869L445.397 661.566L437.938 665.869V674.486L445.397 678.789L452.857 674.486Z',
  'M467.776 674.486V665.869L460.317 661.566L452.857 665.869V674.486L460.317 678.789L467.776 674.486Z',
  'M482.695 674.486V665.869L475.236 661.566L467.776 665.869V674.486L475.236 678.789L482.695 674.486Z',
  'M497.509 674.486V665.869L490.05 661.566L482.59 665.869V674.486L490.05 678.789L497.509 674.486Z',
  'M408.087 674.486V665.869L400.628 661.566L393.168 665.869V674.486L400.628 678.789L408.087 674.486Z',
  'M393.168 674.486V665.869L385.709 661.566L378.249 665.869V674.486L385.709 678.789L393.168 674.486Z',
  'M378.249 674.486V665.869L370.789 661.566L363.318 665.869V674.486L370.789 678.789L378.249 674.486Z',
  'M370.79 661.414V652.797L363.318 648.495L355.859 652.797V661.414L363.318 665.729L370.79 661.414Z',
  'M437.938 648.354V639.737L430.466 635.434L423.007 639.737V648.354L430.466 652.657L437.938 648.354Z',
  'M452.857 648.354V639.737L445.397 635.434L437.938 639.737V648.354L445.397 652.657L452.857 648.354Z',
  'M423.007 648.354V639.737L415.547 635.434L408.088 639.737V648.354L415.547 652.657L423.007 648.354Z',
  'M408.087 648.354V639.737L400.628 635.434L393.168 639.737V648.354L400.628 652.657L408.087 648.354Z',
  'M393.168 648.354V639.737L385.709 635.434L378.249 639.737V648.354L385.709 652.657L393.168 648.354Z',
  'M378.249 648.354V639.737L370.789 635.434L363.318 639.737V648.354L370.789 652.657L378.249 648.354Z',
  'M363.318 648.354V639.737L355.859 635.434L348.399 639.737V648.354L355.859 652.657L363.318 648.354Z',
  'M363.318 674.486V665.869L355.859 661.566L348.399 665.869V674.486L355.859 678.789L363.318 674.486Z',
  'M355.859 661.414V652.797L348.399 648.495L340.939 652.797V661.414L348.399 665.729L355.859 661.414Z',
  'M348.399 648.354V639.737L340.939 635.434L333.48 639.737V648.354L340.939 652.657L348.399 648.354Z',
  'M348.399 674.486V665.869L340.939 661.566L333.48 665.869V674.486L340.939 678.789L348.399 674.486Z',
  'M340.94 661.414V652.797L333.48 648.495L326.021 652.797V661.414L333.48 665.729L340.94 661.414Z',
  'M333.854 674.486V665.869L326.394 661.566L318.935 665.869V674.486L326.394 678.789L333.854 674.486Z',
  'M326.395 661.414V652.797L318.935 648.495L311.475 652.797V661.414L318.935 665.729L326.395 661.414Z',
  'M333.48 648.354V639.737L326.02 635.434L318.561 639.737V648.354L326.02 652.657L333.48 648.354Z',
  'M430.466 635.423V626.817L423.006 622.503L415.547 626.817V635.423L423.006 639.737L430.466 635.423Z',
  'M445.397 635.423V626.817L437.938 622.503L430.466 626.817V635.423L437.938 639.737L445.397 635.423Z',
  'M415.547 635.423V626.817L408.088 622.503L400.628 626.817V635.423L408.088 639.737L415.547 635.423Z',
  'M400.628 635.423V626.817L393.168 622.503L385.708 626.817V635.423L393.168 639.737L400.628 635.423Z',
  'M385.709 635.423V626.817L378.249 622.503L370.79 626.817V635.423L378.249 639.737L385.709 635.423Z',
  'M370.79 635.423V626.817L363.318 622.503L355.859 626.817V635.423L363.318 639.737L370.79 635.423Z',
  'M355.859 635.423V626.817L348.399 622.503L340.939 626.817V635.423L348.399 639.737L355.859 635.423Z',
  'M423.007 622.421V613.804L415.547 609.501L408.088 613.804V622.421L415.547 626.735L423.007 622.421Z',
  'M437.938 622.421V613.804L430.466 609.501L423.007 613.804V622.421L430.466 626.735L437.938 622.421Z',
  'M408.087 622.421V613.804L400.628 609.501L393.168 613.804V622.421L400.628 626.735L408.087 622.421Z',
  'M393.168 622.421V613.804L385.709 609.501L378.249 613.804V622.421L385.709 626.735L393.168 622.421Z',
  'M497.626 648.354V639.737L490.155 635.434L482.695 639.737V648.354L490.155 652.657L497.626 648.354Z',
  'M482.695 648.354V639.737L475.236 635.434L467.776 639.737V648.354L475.236 652.657L482.695 648.354Z',
  'M519.362 64.8798V56.2626L511.902 51.9599L504.443 56.2626V64.8798L511.902 69.1942L519.362 64.8798Z',
  'M511.902 77.9518V69.3347L504.443 65.0203L496.983 69.3347V77.9518L504.443 82.2545L511.902 77.9518Z',
  'M526.716 77.9518V69.3347L519.256 65.0203L511.797 69.3347V77.9518L519.256 82.2545L526.716 77.9518Z',
  'M519.257 90.9417V82.3245L511.797 78.0101L504.338 82.3245V90.9417L511.797 95.2444L519.257 90.9417Z',
  'M496.983 103.873V95.2562L489.523 90.9534L482.064 95.2562V103.873L489.523 108.188L496.983 103.873Z',
  'M511.797 103.873V95.2562L504.337 90.9534L496.866 95.2562V103.873L504.337 108.188L511.797 103.873Z',
  'M474.604 116.945V108.328L467.145 104.014L459.685 108.328V116.945L467.145 121.248L474.604 116.945Z',
  'M489.523 116.945V108.328L482.064 104.014L474.604 108.328V116.945L482.064 121.248L489.523 116.945Z',
  'M504.338 116.945V108.328L496.866 104.014L489.407 108.328V116.945L496.866 121.248L504.338 116.945Z',
  'M467.145 129.888V121.271L459.685 116.969L452.214 121.271V129.888L459.685 134.191L467.145 129.888Z',
  'M482.064 129.888V121.271L474.604 116.969L467.145 121.271V129.888L474.604 134.191L482.064 129.888Z',
  'M474.534 142.621V134.016L467.075 129.701L459.615 134.016V142.621L467.075 146.935L474.534 142.621Z',
  'M452.249 155.728V147.111L444.789 142.808L437.33 147.111V155.728L444.789 160.031L452.249 155.728Z',
  'M459.65 168.472V159.855L452.191 155.541L444.731 159.855V168.472L452.191 172.775L459.65 168.472Z',
  'M444.789 168.472V159.855L437.329 155.541L429.87 159.855V168.472L437.329 172.775L444.789 168.472Z',
  'M511.902 51.8197V43.2026L504.443 38.8882L496.983 43.2026V51.8197L504.443 56.1224L511.902 51.8197Z',
  'M519.362 12.9198V4.30272L511.902 0L504.443 4.30272V12.9198L511.902 17.2226L519.362 12.9198Z',
  'M511.902 25.9916V17.3745L504.443 13.0717L496.983 17.3745V25.9916L504.443 30.2943L511.902 25.9916Z',
  'M526.833 25.9916V17.3745L519.362 13.0717L511.902 17.3745V25.9916L519.362 30.2943L526.833 25.9916Z',
  'M467.776 648.354V639.737L460.317 635.434L452.857 639.737V648.354L460.317 652.657L467.776 648.354Z',
  'M490.155 635.423V626.817L482.695 622.503L475.236 626.817V635.423L482.695 639.737L490.155 635.423Z',
  'M475.236 635.423V626.817L467.776 622.503L460.316 626.817V635.423L467.776 639.737L475.236 635.423Z',
  'M460.316 635.423V626.817L452.857 622.503L445.397 626.817V635.423L452.857 639.737L460.316 635.423Z',
  'M482.695 622.421V613.804L475.236 609.501L467.776 613.804V622.421L475.236 626.735L482.695 622.421Z',
  'M497.626 622.421V613.804L490.155 609.501L482.695 613.804V622.421L490.155 626.735L497.626 622.421Z',
  'M467.776 622.421V613.804L460.317 609.501L452.857 613.804V622.421L460.317 626.735L467.776 622.421Z',
  'M452.857 622.421V613.804L445.397 609.501L437.938 613.804V622.421L445.397 626.735L452.857 622.421Z',
  'M378.249 622.421V613.804L370.789 609.501L363.318 613.804V622.421L370.789 626.735L378.249 622.421Z',
  'M363.318 622.421V613.804L355.859 609.501L348.399 613.804V622.421L355.859 626.735L363.318 622.421Z',
  'M318.935 674.486V665.869L311.475 661.566L304.016 665.869V674.486L311.475 678.789L318.935 674.486Z',
  'M304.016 674.486V665.869L296.544 661.566L289.085 665.869V674.486L296.544 678.789L304.016 674.486Z',
  'M289.085 674.486V665.869L281.625 661.566L274.165 665.869V674.486L281.625 678.789L289.085 674.486Z',
  'M311.475 661.414V652.797L304.016 648.495L296.544 652.797V661.414L304.016 665.729L311.475 661.414Z',
  'M296.544 661.414V652.797L289.085 648.495L281.625 652.797V661.414L289.085 665.729L296.544 661.414Z',
  'M281.625 661.414V652.797L274.165 648.495L266.706 652.797V661.414L274.165 665.729L281.625 661.414Z',
  'M318.935 648.354V639.737L311.475 635.434L304.016 639.737V648.354L311.475 652.657L318.935 648.354Z',
  'M304.016 648.354V639.737L296.544 635.434L289.085 639.737V648.354L296.544 652.657L304.016 648.354Z',
  'M289.085 648.354V639.737L281.625 635.434L274.165 639.737V648.354L281.625 652.657L289.085 648.354Z',
  'M274.166 648.354V639.737L266.706 635.434L259.246 639.737V648.354L266.706 652.657L274.166 648.354Z',
  'M311.475 635.423V626.817L304.016 622.503L296.544 626.817V635.423L304.016 639.737L311.475 635.423Z',
  'M296.544 635.423V626.817L289.085 622.503L281.625 626.817V635.423L289.085 639.737L296.544 635.423Z',
  'M340.94 635.423V626.817L333.48 622.503L326.021 626.817V635.423L333.48 639.737L340.94 635.423Z',
  'M326.02 635.423V626.817L318.561 622.503L311.101 626.817V635.423L318.561 639.737L326.02 635.423Z',
  'M281.625 635.423V626.817L274.165 622.503L266.706 626.817V635.423L274.165 639.737L281.625 635.423Z',
  'M318.783 622.421V613.804L311.323 609.501L303.864 613.804V622.421L311.323 626.735L318.783 622.421Z',
  'M303.864 622.421V613.804L296.404 609.501L288.944 613.804V622.421L296.404 626.735L303.864 622.421Z',
  'M348.247 622.421V613.804L340.788 609.501L333.328 613.804V622.421L340.788 626.735L348.247 622.421Z',
  'M333.328 622.421V613.804L325.868 609.501L318.409 613.804V622.421L325.868 626.735L333.328 622.421Z',
  'M288.944 622.421V613.804L281.485 609.501L274.013 613.804V622.421L281.485 626.735L288.944 622.421Z',
  'M430.361 609.431V600.825L422.902 596.511L415.442 600.825V609.431L422.902 613.745L430.361 609.431Z',
  'M445.281 609.431V600.825L437.821 596.511L430.361 600.825V609.431L437.821 613.745L445.281 609.431Z',
  'M415.442 609.431V600.825L407.971 596.511L400.511 600.825V609.431L407.971 613.745L415.442 609.431Z',
  'M400.511 609.431V600.825L393.052 596.511L385.592 600.825V609.431L393.052 613.745L400.511 609.431Z',
  'M490.05 609.431V600.825L482.59 596.511L475.119 600.825V609.431L482.59 613.745L490.05 609.431Z',
  'M475.119 609.431V600.825L467.659 596.511L460.2 600.825V609.431L467.659 613.745L475.119 609.431Z',
  'M460.2 609.431V600.825L452.74 596.511L445.281 600.825V609.431L452.74 613.745L460.2 609.431Z',
  'M385.592 609.431V600.825L378.132 596.511L370.673 600.825V609.431L378.132 613.745L385.592 609.431Z',
  'M370.673 609.431V600.825L363.213 596.511L355.753 600.825V609.431L363.213 613.745L370.673 609.431Z',
  'M326.137 609.431V600.825L318.666 596.511L311.206 600.825V609.431L318.666 613.745L326.137 609.431Z',
  'M311.206 609.431V600.825L303.746 596.511L296.287 600.825V609.431L303.746 613.745L311.206 609.431Z',
  'M355.601 609.431V600.825L348.142 596.511L340.682 600.825V609.431L348.142 613.745L355.601 609.431Z',
  'M340.682 609.431V600.825L333.223 596.511L325.751 600.825V609.431L333.223 613.745L340.682 609.431Z',
  'M296.287 609.431V600.825L288.828 596.511L281.368 600.825V609.431L288.828 613.745L296.287 609.431Z',
  'M437.821 596.511V587.894L430.361 583.58L422.901 587.894V596.511L430.361 600.814L437.821 596.511Z',
  'M452.74 596.511V587.894L445.28 583.58L437.821 587.894V596.511L445.28 600.814L452.74 596.511Z',
  'M422.901 596.511V587.894L415.442 583.58L407.97 587.894V596.511L415.442 600.814L422.901 596.511Z',
  'M407.97 596.511V587.894L400.511 583.58L393.051 587.894V596.511L400.511 600.814L407.97 596.511Z',
  'M467.659 596.511V587.894L460.199 583.58L452.74 587.894V596.511L460.199 600.814L467.659 596.511Z',
  'M393.051 596.511V587.894L385.591 583.58L378.132 587.894V596.511L385.591 600.814L393.051 596.511Z',
  'M378.132 596.511V587.894L370.673 583.58L363.213 587.894V596.511L370.673 600.814L378.132 596.511Z',
  'M333.597 596.511V587.894L326.137 583.58L318.666 587.894V596.511L326.137 600.814L333.597 596.511Z',
  'M318.666 596.511V587.894L311.206 583.58L303.747 587.894V596.511L311.206 600.814L318.666 596.511Z',
  'M363.061 596.511V587.894L355.601 583.58L348.142 587.894V596.511L355.601 600.814L363.061 596.511Z',
  'M348.142 596.511V587.894L340.682 583.58L333.222 587.894V596.511L340.682 600.814L348.142 596.511Z',
  'M445.281 583.568V574.951L437.821 570.648L430.361 574.951V583.568L437.821 587.871L445.281 583.568Z',
  'M460.2 583.568V574.951L452.74 570.648L445.281 574.951V583.568L452.74 587.871L460.2 583.568Z',
  'M475.013 583.568V574.951L467.554 570.648L460.094 574.951V583.568L467.554 587.871L475.013 583.568Z',
  'M430.361 583.568V574.951L422.902 570.648L415.442 574.951V583.568L422.902 587.871L430.361 583.568Z',
  'M385.592 583.568V574.951L378.132 570.648L370.673 574.951V583.568L378.132 587.871L385.592 583.568Z',
  'M341.056 583.568V574.951L333.597 570.648L326.137 574.951V583.568L333.597 587.871L341.056 583.568Z',
  'M326.137 583.568V574.951L318.666 570.648L311.206 574.951V583.568L318.666 587.871L326.137 583.568Z',
  'M370.52 583.568V574.951L363.061 570.648L355.601 574.951V583.568L363.061 587.871L370.52 583.568Z',
  'M355.601 583.568V574.951L348.142 570.648L340.682 574.951V583.568L348.142 587.871L355.601 583.568Z',
  'M363.318 570.496V561.879L355.859 557.576L348.399 561.879V570.496L355.859 574.799L363.318 570.496Z',
  'M348.399 570.496V561.879L340.939 557.576L333.48 561.879V570.496L340.939 574.799L348.399 570.496Z',
  'M377.875 570.496V561.879L370.403 557.576L362.944 561.879V570.496L370.403 574.799L377.875 570.496Z',
  'M333.48 570.496V561.879L326.02 557.576L318.561 561.879V570.496L326.02 574.799L333.48 570.496Z',
  'M385.522 557.565V548.947L378.062 544.645L370.591 548.947V557.565L378.062 561.867L385.522 557.565Z',
  'M370.591 557.565V548.947L363.131 544.645L355.671 548.947V557.565L363.131 561.867L370.591 557.565Z',
  'M445.163 557.565V548.947L437.704 544.645L430.244 548.947V557.565L437.704 561.867L445.163 557.565Z',
  'M460.129 557.565V548.947L452.67 544.645L445.21 548.947V557.565L452.67 561.867L460.129 557.565Z',
  'M355.672 557.565V548.947L348.212 544.645L340.752 548.947V557.565L348.212 561.867L355.672 557.565Z',
  'M467.554 570.496V561.879L460.094 557.576L452.623 561.879V570.496L460.094 574.799L467.554 570.496Z',
  'M452.623 570.496V561.879L445.164 557.576L437.704 561.879V570.496L445.164 574.799L452.623 570.496Z',
  'M437.704 570.496V561.879L430.244 557.576L422.785 561.879V570.496L430.244 574.799L437.704 570.496Z',
  'M295.983 194.675V186.069L288.523 181.755L281.064 186.069V194.675L288.523 198.989L295.983 194.675Z',
  'M310.902 194.675V186.069L303.443 181.755L295.983 186.069V194.675L303.443 198.989L310.902 194.675Z',
  'M325.822 194.675V186.069L318.362 181.755L310.902 186.069V194.675L318.362 198.989L325.822 194.675Z',
  'M340.752 194.675V186.069L333.293 181.755L325.821 186.069V194.675L333.293 198.989L340.752 194.675Z',
  'M355.672 194.675V186.069L348.212 181.755L340.752 186.069V194.675L348.212 198.989L355.672 194.675Z',
  'M370.591 194.675V186.069L363.131 181.755L355.671 186.069V194.675L363.131 198.989L370.591 194.675Z',
  'M281.064 194.675V186.069L273.604 181.755L266.133 186.069V194.675L273.604 198.989L281.064 194.675Z',
  'M266.133 194.675V186.069L258.673 181.755L251.214 186.069V194.675L258.673 198.989L266.133 194.675Z',
  'M251.214 194.675V186.069L243.754 181.755L236.295 186.069V194.675L243.754 198.989L251.214 194.675Z',
  'M288.523 207.746V199.129L281.064 194.826L273.604 199.129V207.746L281.064 212.049L288.523 207.746Z',
  'M303.443 207.746V199.129L295.983 194.826L288.523 199.129V207.746L295.983 212.049L303.443 207.746Z',
  'M318.362 207.746V199.129L310.902 194.826L303.442 199.129V207.746L310.902 212.049L318.362 207.746Z',
  'M333.293 207.746V199.129L325.821 194.826L318.362 199.129V207.746L325.821 212.049L333.293 207.746Z',
  'M348.212 207.746V199.129L340.752 194.826L333.293 199.129V207.746L340.752 212.049L348.212 207.746Z',
  'M378.039 233.539V224.922L370.579 220.62L363.12 224.922V233.539L370.579 237.842L378.039 233.539Z',
  'M363.014 207.746V199.129L355.555 194.826L348.095 199.129V207.746L355.555 212.049L363.014 207.746Z',
  'M273.604 207.746V199.129L266.133 194.826L258.673 199.129V207.746L266.133 212.049L273.604 207.746Z',
  'M258.673 207.746V199.129L251.214 194.826L243.754 199.129V207.746L251.214 212.049L258.673 207.746Z',
  'M281.064 220.795V212.178L273.604 207.875L266.133 212.178V220.795L273.604 225.098L281.064 220.795Z',
  'M295.983 220.795V212.178L288.523 207.875L281.064 212.178V220.795L288.523 225.098L295.983 220.795Z',
  'M310.902 220.795V212.178L303.443 207.875L295.983 212.178V220.795L303.443 225.098L310.902 220.795Z',
  'M340.752 220.795V212.178L333.293 207.875L325.821 212.178V220.795L333.293 225.098L340.752 220.795Z',
  'M355.554 220.795V212.178L348.095 207.875L340.635 212.178V220.795L348.095 225.098L355.554 220.795Z',
  'M325.822 220.795V212.178L318.362 207.875L310.902 212.178V220.795L318.362 225.098L325.822 220.795Z',
  'M370.579 246.588V237.971L363.119 233.668L355.648 237.971V246.588L363.119 250.891L370.579 246.588Z',
  'M385.382 246.588V237.971L377.922 233.668L370.462 237.971V246.588L377.922 250.891L385.382 246.588Z',
  'M266.133 220.795V212.178L258.673 207.875L251.214 212.178V220.795L258.673 225.098L266.133 220.795Z',
  'M333.293 233.715V225.098L325.821 220.795L318.362 225.098V233.715L325.821 238.018L333.293 233.715Z',
  'M348.212 233.715V225.098L340.752 220.795L333.293 225.098V233.715L340.752 238.018L348.212 233.715Z',
  'M288.523 233.715V225.098L281.064 220.795L273.604 225.098V233.715L281.064 238.018L288.523 233.715Z',
  'M303.443 233.715V225.098L295.983 220.795L288.523 225.098V233.715L295.983 238.018L303.443 233.715Z',
  'M318.362 233.715V225.098L310.902 220.795L303.442 225.098V233.715L310.902 238.018L318.362 233.715Z',
  'M363.119 259.508V250.891L355.648 246.588L348.188 250.891V259.508L355.648 263.811L363.119 259.508Z',
  'M378.039 259.508V250.891L370.579 246.588L363.12 250.891V259.508L370.579 263.811L378.039 259.508Z',
  'M281.064 246.74V238.123L273.604 233.82L266.133 238.123V246.74L273.604 251.043L281.064 246.74Z',
  'M295.983 246.74V238.123L288.523 233.82L281.064 238.123V246.74L288.523 251.043L295.983 246.74Z',
  'M310.902 246.74V238.123L303.443 233.82L295.983 238.123V246.74L303.443 251.043L310.902 246.74Z',
  'M325.822 246.74V238.123L318.362 233.82L310.902 238.123V246.74L318.362 251.043L325.822 246.74Z',
  'M273.604 233.715V225.098L266.133 220.795L258.673 225.098V233.715L266.133 238.018L273.604 233.715Z',
  'M243.754 207.746V199.129L236.295 194.826L228.835 199.129V207.746L236.295 212.049L243.754 207.746Z',
  'M236.294 194.675V186.069L228.835 181.755L221.375 186.069V194.675L228.835 198.989L236.294 194.675Z',
  'M303.443 181.614V172.997L295.983 168.695L288.523 172.997V181.614L295.983 185.917L303.443 181.614Z',
  'M318.362 181.614V172.997L310.902 168.695L303.442 172.997V181.614L310.902 185.917L318.362 181.614Z',
  'M288.523 181.614V172.997L281.064 168.695L273.604 172.997V181.614L281.064 185.917L288.523 181.614Z',
  'M273.604 181.614V172.997L266.133 168.695L258.673 172.997V181.614L266.133 185.917L273.604 181.614Z',
  'M258.673 181.614V172.997L251.214 168.695L243.754 172.997V181.614L251.214 185.917L258.673 181.614Z',
  'M243.754 181.614V172.997L236.295 168.695L228.835 172.997V181.614L236.295 185.917L243.754 181.614Z',
  'M228.835 181.614V172.997L221.375 168.695L213.916 172.997V181.614L221.375 185.917L228.835 181.614Z',
  'M228.835 207.746V199.129L221.375 194.826L213.916 199.129V207.746L221.375 212.049L228.835 207.746Z',
  'M251.214 220.795V212.178L243.754 207.875L236.295 212.178V220.795L243.754 225.098L251.214 220.795Z',
  'M236.294 220.795V212.178L228.835 207.875L221.375 212.178V220.795L228.835 225.098L236.294 220.795Z',
  'M295.983 168.683V160.077L288.523 155.763L281.064 160.077V168.683L288.523 172.997L295.983 168.683Z',
  'M310.902 168.683V160.077L303.443 155.763L295.983 160.077V168.683L303.443 172.997L310.902 168.683Z',
  'M281.064 168.683V160.077L273.604 155.763L266.133 160.077V168.683L273.604 172.997L281.064 168.683Z',
  'M266.133 168.683V160.077L258.673 155.763L251.214 160.077V168.683L258.673 172.997L266.133 168.683Z',
  'M251.214 168.683V160.077L243.754 155.763L236.295 160.077V168.683L243.754 172.997L251.214 168.683Z',
  'M236.294 168.683V160.077L228.835 155.763L221.375 160.077V168.683L228.835 172.997L236.294 168.683Z',
  'M288.523 155.681V147.064L281.064 142.762L273.604 147.064V155.681L281.064 159.996L288.523 155.681Z',
  'M303.443 155.681V147.064L295.983 142.762L288.523 147.064V155.681L295.983 159.996L303.443 155.681Z',
  'M273.604 155.681V147.064L266.133 142.762L258.673 147.064V155.681L266.133 159.996L273.604 155.681Z',
  'M258.673 155.681V147.064L251.214 142.762L243.754 147.064V155.681L251.214 159.996L258.673 155.681Z',
  'M363.131 181.614V172.997L355.672 168.695L348.212 172.997V181.614L355.672 185.917L363.131 181.614Z',
  'M348.212 181.614V172.997L340.752 168.695L333.293 172.997V181.614L340.752 185.917L348.212 181.614Z',
  'M333.293 181.614V172.997L325.821 168.695L318.362 172.997V181.614L325.821 185.917L333.293 181.614Z',
  'M355.672 168.683V160.077L348.212 155.763L340.752 160.077V168.683L348.212 172.997L355.672 168.683Z',
  'M340.752 168.683V160.077L333.293 155.763L325.821 160.077V168.683L333.293 172.997L340.752 168.683Z',
  'M325.822 168.683V160.077L318.362 155.763L310.902 160.077V168.683L318.362 172.997L325.822 168.683Z',
  'M348.212 155.681V147.064L340.752 142.762L333.293 147.064V155.681L340.752 159.996L348.212 155.681Z',
  'M363.131 155.681V147.064L355.672 142.762L348.212 147.064V155.681L355.672 159.996L363.131 155.681Z',
  'M333.293 155.681V147.064L325.821 142.762L318.362 147.064V155.681L325.821 159.996L333.293 155.681Z',
  'M318.362 155.681V147.064L310.902 142.762L303.442 147.064V155.681L310.902 159.996L318.362 155.681Z',
  'M243.754 155.681V147.064L236.295 142.762L228.835 147.064V155.681L236.295 159.996L243.754 155.681Z',
  'M228.835 155.681V147.064L221.375 142.762L213.916 147.064V155.681L221.375 159.996L228.835 155.681Z',
  'M295.866 142.691V134.086L288.406 129.771L280.947 134.086V142.691L288.406 147.006L295.866 142.691Z',
  'M310.785 142.691V134.086L303.326 129.771L295.866 134.086V142.691L303.326 147.006L310.785 142.691Z',
  'M280.947 142.691V134.086L273.487 129.771L266.028 134.086V142.691L273.487 147.006L280.947 142.691Z',
  'M266.028 142.691V134.086L258.568 129.771L251.108 134.086V142.691L258.568 147.006L266.028 142.691Z',
  'M355.554 142.691V134.086L348.095 129.771L340.635 134.086V142.691L348.095 147.006L355.554 142.691Z',
  'M340.636 142.691V134.086L333.176 129.771L325.716 134.086V142.691L333.176 147.006L340.636 142.691Z',
  'M325.716 142.691V134.086L318.257 129.771L310.785 134.086V142.691L318.257 147.006L325.716 142.691Z',
  'M251.108 142.691V134.086L243.637 129.771L236.177 134.086V142.691L243.637 147.006L251.108 142.691Z',
  'M236.178 142.691V134.086L228.718 129.771L221.259 134.086V142.691L228.718 147.006L236.178 142.691Z',
  'M303.325 129.771V121.154L295.866 116.851L288.406 121.154V129.771L295.866 134.074L303.325 129.771Z',
  'M318.257 129.771V121.154L310.785 116.851L303.326 121.154V129.771L310.785 134.074L318.257 129.771Z',
  'M288.407 129.771V121.154L280.947 116.851L273.487 121.154V129.771L280.947 134.074L288.407 129.771Z',
  'M273.487 129.771V121.154L266.028 116.851L258.568 121.154V129.771L266.028 134.074L273.487 129.771Z',
  'M333.176 129.701V121.084L325.716 116.77L318.256 121.084V129.701L325.716 134.004L333.176 129.701Z',
  'M258.568 129.771V121.154L251.109 116.851L243.637 121.154V129.771L251.109 134.074L258.568 129.771Z',
  'M243.637 129.771V121.154L236.178 116.851L228.718 121.154V129.771L236.178 134.074L243.637 129.771Z',
  'M228.566 129.771V121.154L221.106 116.851L213.647 121.154V129.771L221.106 134.074L228.566 129.771Z',
  'M310.785 116.828V108.211L303.326 103.908L295.866 108.211V116.828L303.326 121.131L310.785 116.828Z',
  'M325.716 116.828V108.211L318.257 103.908L310.785 108.211V116.828L318.257 121.131L325.716 116.828Z',
  'M347.931 129.701V121.084L340.472 116.77L333.012 121.084V129.701L340.472 134.004L347.931 129.701Z',
  'M340.471 116.828V108.211L333.012 103.908L325.552 108.211V116.828L333.012 121.131L340.471 116.828Z',
  'M295.866 116.828V108.211L288.406 103.908L280.947 108.211V116.828L288.406 121.131L295.866 116.828Z',
  'M251.108 116.828V108.211L243.637 103.908L236.177 108.211V116.828L243.637 121.131L251.108 116.828Z',
  'M236.037 116.828V108.211L228.566 103.908L221.106 108.211V116.828L228.566 121.131L236.037 116.828Z',
  'M228.835 103.756V95.1391L221.375 90.8364L213.916 95.1391V103.756L221.375 108.071L228.835 103.756Z',
  'M243.38 103.756V95.1391L235.92 90.8364L228.461 95.1391V103.756L235.92 108.071L243.38 103.756Z',
  'M333.059 103.756V95.1391L325.599 90.8364L318.14 95.1391V103.756L325.599 108.071L333.059 103.756Z',
  'M318.14 103.756V95.1391L310.68 90.8364L303.221 95.1391V103.756L310.68 108.071L318.14 103.756Z',
  'M303.22 103.756V95.1391L295.761 90.8364L288.29 95.1391V103.756L295.761 108.071L303.22 103.756Z',
  'M281.006 116.828V108.211L273.546 103.908L266.086 108.211V116.828L273.546 121.131L281.006 116.828Z',
  'M288.348 103.756V95.1391L280.888 90.8364L273.429 95.1391V103.756L280.888 108.071L288.348 103.756Z',
  'M266.086 116.828V108.211L258.627 103.908L251.155 108.211V116.828L258.627 121.131L266.086 116.828Z',
  'M273.429 103.756V95.1391L265.969 90.8364L258.51 95.1391V103.756L265.969 108.071L273.429 103.756Z',
  'M258.521 103.756V95.1391L251.062 90.8364L243.59 95.1391V103.756L251.062 108.071L258.521 103.756Z',
  'M146.779 272.591V263.974L139.319 259.672L131.86 263.974V272.591L139.319 276.906L146.779 272.591Z',
  'M124.389 259.625V251.008L116.929 246.705L109.47 251.008V259.625L116.929 263.939L124.389 259.625Z',
  'M139.32 259.625V251.008L131.86 246.705L124.389 251.008V259.625L131.86 263.939L139.32 259.625Z',
  'M154.239 259.625V251.008L146.779 246.705L139.32 251.008V259.625L146.779 263.939L154.239 259.625Z',
  'M109.47 259.625V251.008L102.01 246.705L94.5504 251.008V259.625L102.01 263.939L109.47 259.625Z',
  'M94.5503 259.625V251.008L87.0907 246.705L79.6311 251.008V259.625L87.0907 263.939L94.5503 259.625Z',
  'M79.6312 259.625V251.008L72.1716 246.705L64.712 251.008V259.625L72.1716 263.939L79.6312 259.625Z',
  'M64.7122 259.625V251.008L57.2409 246.705L49.7812 251.008V259.625L57.2409 263.939L64.7122 259.625Z',
  'M49.7811 259.625V251.008L42.3215 246.705L34.8619 251.008V259.625L42.3215 263.939L49.7811 259.625Z',
  'M243.766 259.625V251.008L236.306 246.705L228.846 251.008V259.625L236.306 263.939L243.766 259.625Z',
  'M258.697 259.625V251.008L251.225 246.705L243.766 251.008V259.625L251.225 263.939L258.697 259.625Z',
  'M273.616 259.625V251.008L266.156 246.705L258.697 251.008V259.625L266.156 263.939L273.616 259.625Z',
  'M228.847 259.625V251.008L221.387 246.705L213.927 251.008V259.625L221.387 263.939L228.847 259.625Z',
  'M213.928 259.625V251.008L206.468 246.705L199.008 251.008V259.625L206.468 263.939L213.928 259.625Z',
  'M199.008 259.625V251.008L191.548 246.705L184.077 251.008V259.625L191.548 263.939L199.008 259.625Z',
  'M184.077 259.625V251.008L176.618 246.705L169.158 251.008V259.625L176.618 263.939L184.077 259.625Z',
  'M169.158 259.625V251.008L161.698 246.705L154.239 251.008V259.625L161.698 263.939L169.158 259.625Z',
  'M116.929 246.728V238.111L109.47 233.808L102.01 238.111V246.728L109.47 251.031L116.929 246.728Z',
  'M131.86 246.728V238.111L124.389 233.808L116.929 238.111V246.728L124.389 251.031L131.86 246.728Z',
  'M146.779 246.728V238.111L139.319 233.808L131.86 238.111V246.728L139.319 251.031L146.779 246.728Z',
  'M102.01 246.728V238.111L94.5503 233.808L87.0907 238.111V246.728L94.5503 251.031L102.01 246.728Z',
  'M87.0906 246.728V238.111L79.631 233.808L72.1714 238.111V246.728L79.631 251.031L87.0906 246.728Z',
  'M72.1714 246.728V238.111L64.7118 233.808L57.2405 238.111V246.728L64.7118 251.031L72.1714 246.728Z',
  'M57.2406 246.728V238.111L49.781 233.808L42.3214 238.111V246.728L49.781 251.031L57.2406 246.728Z',
  'M42.3213 246.728V238.111L34.8617 233.808L27.4021 238.111V246.728L34.8617 251.031L42.3213 246.728Z',
  'M236.306 246.728V238.111L228.847 233.808L221.387 238.111V246.728L228.847 251.031L236.306 246.728Z',
  'M251.225 246.728V238.111L243.766 233.808L236.306 238.111V246.728L243.766 251.031L251.225 246.728Z',
  'M266.156 246.728V238.111L258.697 233.808L251.226 238.111V246.728L258.697 251.031L266.156 246.728Z',
  'M221.387 246.728V238.111L213.927 233.808L206.468 238.111V246.728L213.927 251.031L221.387 246.728Z',
  'M206.468 246.728V238.111L199.008 233.808L191.549 238.111V246.728L199.008 251.031L206.468 246.728Z',
  'M191.549 246.728V238.111L184.077 233.808L176.618 238.111V246.728L184.077 251.031L191.549 246.728Z',
  'M176.618 246.728V238.111L169.158 233.808L161.699 238.111V246.728L169.158 251.031L176.618 246.728Z',
  'M161.698 246.728V238.111L154.239 233.808L146.779 238.111V246.728L154.239 251.031L161.698 246.728Z',
  'M109.47 233.691V225.074L102.01 220.772L94.5504 225.074V233.691L102.01 238.006L109.47 233.691Z',
  'M124.389 233.691V225.074L116.929 220.772L109.47 225.074V233.691L116.929 238.006L124.389 233.691Z',
  'M139.32 233.691V225.074L131.86 220.772L124.389 225.074V233.691L131.86 238.006L139.32 233.691Z',
  'M94.5503 233.691V225.074L87.0907 220.772L79.6311 225.074V233.691L87.0907 238.006L94.5503 233.691Z',
  'M79.6312 233.691V225.074L72.1716 220.772L64.712 225.074V233.691L72.1716 238.006L79.6312 233.691Z',
  'M64.7122 233.691V225.074L57.2409 220.772L49.7812 225.074V233.691L57.2409 238.006L64.7122 233.691Z',
  'M49.7811 233.691V225.074L42.3215 220.772L34.8619 225.074V233.691L42.3215 238.006L49.7811 233.691Z',
  'M34.8617 233.691V225.074L27.4021 220.772L19.9425 225.074V233.691L27.4021 238.006L34.8617 233.691Z',
  'M34.8617 259.625V251.008L27.4021 246.705L19.9425 251.008V259.625L27.4021 263.939L34.8617 259.625Z',
  'M27.402 246.728V238.111L19.9424 233.808L12.4828 238.111V246.728L19.9424 251.031L27.402 246.728Z',
  'M19.9426 233.691V225.074L12.483 220.772L5.02344 225.074V233.691L12.483 238.006L19.9426 233.691Z',
  'M228.847 233.691V225.074L221.387 220.772L213.927 225.074V233.691L221.387 238.006L228.847 233.691Z',
  'M243.766 233.691V225.074L236.306 220.772L228.846 225.074V233.691L236.306 238.006L243.766 233.691Z',
  'M258.697 233.691V225.074L251.225 220.772L243.766 225.074V233.691L251.225 238.006L258.697 233.691Z',
  'M213.928 233.691V225.074L206.468 220.772L199.008 225.074V233.691L206.468 238.006L213.928 233.691Z',
  'M199.008 233.691V225.074L191.548 220.772L184.077 225.074V233.691L191.548 238.006L199.008 233.691Z',
  'M184.077 233.691V225.074L176.618 220.772L169.158 225.074V233.691L176.618 238.006L184.077 233.691Z',
  'M169.158 233.691V225.074L161.698 220.772L154.239 225.074V233.691L161.698 238.006L169.158 233.691Z',
  'M154.239 233.691V225.074L146.779 220.772L139.32 225.074V233.691L146.779 238.006L154.239 233.691Z',
  'M101.998 220.795V212.178L94.5386 207.875L87.079 212.178V220.795L94.5386 225.098L101.998 220.795Z',
  'M116.917 220.795V212.178L109.458 207.875L101.998 212.178V220.795L109.458 225.098L116.917 220.795Z',
  'M131.837 220.795V212.178L124.377 207.875L116.917 212.178V220.795L124.377 225.098L131.837 220.795Z',
  'M87.079 220.795V212.178L79.6194 207.875L72.1481 212.178V220.795L79.6194 225.098L87.079 220.795Z',
  'M72.148 220.795V212.178L64.6884 207.875L57.2288 212.178V220.795L64.6884 225.098L72.148 220.795Z',
  'M57.2287 220.795V212.178L49.7691 207.875L42.3094 212.178V220.795L49.7691 225.098L57.2287 220.795Z',
  'M42.3096 220.795V212.178L34.85 207.875L27.3904 212.178V220.795L34.85 225.098L42.3096 220.795Z',
  'M27.3905 220.795V212.178L19.9309 207.875L12.4596 212.178V220.795L19.9309 225.098L27.3905 220.795Z',
  'M221.375 220.795V212.178L213.916 207.875L206.456 212.178V220.795L213.916 225.098L221.375 220.795Z',
  'M206.456 220.795V212.178L198.985 207.875L191.525 212.178V220.795L198.985 225.098L206.456 220.795Z',
  'M191.525 220.795V212.178L184.066 207.875L176.606 212.178V220.795L184.066 225.098L191.525 220.795Z',
  'M176.606 220.795V212.178L169.146 207.875L161.687 212.178V220.795L169.146 225.098L176.606 220.795Z',
  'M161.687 220.795V212.178L154.227 207.875L146.768 212.178V220.795L154.227 225.098L161.687 220.795Z',
  'M146.768 220.795V212.178L139.296 207.875L131.837 212.178V220.795L139.296 225.098L146.768 220.795Z',
  'M94.5388 207.758V199.141L87.0792 194.838L79.6196 199.141V207.758L87.0792 212.061L94.5388 207.758Z',
  'M109.458 207.758V199.141L101.998 194.838L94.5387 199.141V207.758L101.998 212.061L109.458 207.758Z',
  'M124.377 207.758V199.141L116.917 194.838L109.458 199.141V207.758L116.917 212.061L124.377 207.758Z',
  'M79.6195 207.758V199.141L72.1482 194.838L64.6886 199.141V207.758L72.1482 212.061L79.6195 207.758Z',
  'M64.6885 207.758V199.141L57.2289 194.838L49.7693 199.141V207.758L57.2289 212.061L64.6885 207.758Z',
  'M49.7693 207.758V199.141L42.3097 194.838L34.8501 199.141V207.758L42.3097 212.061L49.7693 207.758Z',
  'M34.85 207.758V199.141L27.3904 194.838L19.9308 199.141V207.758L27.3904 212.061L34.85 207.758Z',
  'M19.9309 207.758V199.141L12.4596 194.838L5 199.141V207.758L12.4596 212.061L19.9309 207.758Z',
  'M213.916 207.758V199.141L206.456 194.838L198.985 199.141V207.758L206.456 212.061L213.916 207.758Z',
  'M198.985 207.758V199.141L191.525 194.838L184.065 199.141V207.758L191.525 212.061L198.985 207.758Z',
  'M184.066 207.758V199.141L176.606 194.838L169.146 199.141V207.758L176.606 212.061L184.066 207.758Z',
  'M169.146 207.758V199.141L161.687 194.838L154.227 199.141V207.758L161.687 212.061L169.146 207.758Z',
  'M154.227 207.758V199.141L146.768 194.838L139.297 199.141V207.758L146.768 212.061L154.227 207.758Z',
  'M139.296 207.758V199.141L131.837 194.838L124.377 199.141V207.758L131.837 212.061L139.296 207.758Z',
  'M116.917 194.792V186.174L109.458 181.872L101.998 186.174V194.792L109.458 199.094L116.917 194.792Z',
  'M131.837 194.792V186.174L124.377 181.872L116.917 186.174V194.792L124.377 199.094L131.837 194.792Z',
  'M146.768 194.792V186.174L139.296 181.872L131.837 186.174V194.792L139.296 199.094L146.768 194.792Z',
  'M101.998 194.792V186.174L94.5386 181.872L87.079 186.174V194.792L94.5386 199.094L101.998 194.792Z',
  'M87.079 194.792V186.174L79.6194 181.872L72.1481 186.174V194.792L79.6194 199.094L87.079 194.792Z',
  'M72.148 194.792V186.174L64.6884 181.872L57.2288 186.174V194.792L64.6884 199.094L72.148 194.792Z',
  'M57.2287 194.792V186.174L49.7691 181.872L42.3094 186.174V194.792L49.7691 199.094L57.2287 194.792Z',
  'M42.3096 194.792V186.174L34.85 181.872L27.3904 186.174V194.792L34.85 199.094L42.3096 194.792Z',
  'M221.375 194.792V186.174L213.916 181.872L206.456 186.174V194.792L213.916 199.094L221.375 194.792Z',
  'M206.456 194.792V186.174L198.985 181.872L191.525 186.174V194.792L198.985 199.094L206.456 194.792Z',
  'M191.525 194.792V186.174L184.066 181.872L176.606 186.174V194.792L184.066 199.094L191.525 194.792Z',
  'M176.606 194.792V186.174L169.146 181.872L161.687 186.174V194.792L169.146 199.094L176.606 194.792Z',
  'M161.687 194.792V186.174L154.227 181.872L146.768 186.174V194.792L154.227 199.094L161.687 194.792Z',
  'M109.458 181.895V173.278L101.998 168.975L94.5387 173.278V181.895L101.998 186.198L109.458 181.895Z',
  'M124.377 181.895V173.278L116.917 168.975L109.458 173.278V181.895L116.917 186.198L124.377 181.895Z',
  'M139.296 181.895V173.278L131.837 168.975L124.377 173.278V181.895L131.837 186.198L139.296 181.895Z',
  'M94.5388 181.895V173.278L87.0792 168.975L79.6196 173.278V181.895L87.0792 186.198L94.5388 181.895Z',
  'M79.6195 181.895V173.278L72.1482 168.975L64.6886 173.278V181.895L72.1482 186.198L79.6195 181.895Z',
  'M64.6885 181.895V173.278L57.2289 168.975L49.7693 173.278V181.895L57.2289 186.198L64.6885 181.895Z',
  'M49.7693 181.895V173.278L42.3097 168.975L34.8501 173.278V181.895L42.3097 186.198L49.7693 181.895Z',
  'M34.85 181.895V173.278L27.3904 168.975L19.9308 173.278V181.895L27.3904 186.198L34.85 181.895Z',
  'M213.916 181.895V173.278L206.456 168.975L198.985 173.278V181.895L206.456 186.198L213.916 181.895Z',
  'M198.985 181.895V173.278L191.525 168.975L184.065 173.278V181.895L191.525 186.198L198.985 181.895Z',
  'M184.066 181.895V173.278L176.606 168.975L169.146 173.278V181.895L176.606 186.198L184.066 181.895Z',
  'M169.146 181.895V173.278L161.687 168.975L154.227 173.278V181.895L161.687 186.198L169.146 181.895Z',
  'M154.227 181.895V173.278L146.768 168.975L139.297 173.278V181.895L146.768 186.198L154.227 181.895Z',
  'M101.998 168.858V160.241L94.5386 155.939L87.079 160.241V168.858L94.5386 173.161L101.998 168.858Z',
  'M116.917 168.858V160.241L109.458 155.939L101.998 160.241V168.858L109.458 173.161L116.917 168.858Z',
  'M131.837 168.858V160.241L124.377 155.939L116.917 160.241V168.858L124.377 173.161L131.837 168.858Z',
  'M87.079 168.858V160.241L79.6194 155.939L72.1481 160.241V168.858L79.6194 173.161L87.079 168.858Z',
  'M72.148 168.858V160.241L64.6884 155.939L57.2288 160.241V168.858L64.6884 173.161L72.148 168.858Z',
  'M57.2287 168.858V160.241L49.7691 155.939L42.3094 160.241V168.858L49.7691 173.161L57.2287 168.858Z',
  'M42.3096 168.858V160.241L34.85 155.939L27.3904 160.241V168.858L34.85 173.161L42.3096 168.858Z',
  'M27.3905 168.858V160.241L19.9309 155.939L12.4596 160.241V168.858L19.9309 173.161L27.3905 168.858Z',
  'M27.3905 194.792V186.174L19.9309 181.872L12.4596 186.174V194.792L19.9309 199.094L27.3905 194.792Z',
  'M19.9309 181.895V173.278L12.4596 168.975L5 173.278V181.895L12.4596 186.198L19.9309 181.895Z',
  'M221.375 168.858V160.241L213.916 155.939L206.456 160.241V168.858L213.916 173.161L221.375 168.858Z',
  'M206.456 168.858V160.241L198.985 155.939L191.525 160.241V168.858L198.985 173.161L206.456 168.858Z',
  'M191.525 168.858V160.241L184.066 155.939L176.606 160.241V168.858L184.066 173.161L191.525 168.858Z',
  'M176.606 168.858V160.241L169.146 155.939L161.687 160.241V168.858L169.146 173.161L176.606 168.858Z',
  'M161.687 168.858V160.241L154.227 155.939L146.768 160.241V168.858L154.227 173.161L161.687 168.858Z',
  'M146.768 168.858V160.241L139.296 155.939L131.837 160.241V168.858L139.296 173.161L146.768 168.858Z',
  'M94.3983 155.681V147.076L86.9387 142.762L79.4791 147.076V155.681L86.9387 159.996L94.3983 155.681Z',
  'M109.318 155.681V147.076L101.858 142.762L94.3983 147.076V155.681L101.858 159.996L109.318 155.681Z',
  'M124.237 155.681V147.076L116.777 142.762L109.318 147.076V155.681L116.777 159.996L124.237 155.681Z',
  'M79.479 155.681V147.076L72.0194 142.762L64.5598 147.076V155.681L72.0194 159.996L79.479 155.681Z',
  'M64.5599 155.681V147.076L57.0886 142.762L49.629 147.076V155.681L57.0886 159.996L64.5599 155.681Z',
  'M49.6289 155.681V147.076L42.1693 142.762L34.7097 147.076V155.681L42.1693 159.996L49.6289 155.681Z',
  'M34.7099 155.681V147.076L27.2503 142.762L19.7906 147.076V155.681L27.2503 159.996L34.7099 155.681Z',
  'M213.776 155.681V147.076L206.316 142.762L198.856 147.076V155.681L206.316 159.996L213.776 155.681Z',
  'M198.856 155.681V147.076L191.397 142.762L183.926 147.076V155.681L191.397 159.996L198.856 155.681Z',
  'M183.925 155.681V147.076L176.466 142.762L169.006 147.076V155.681L176.466 159.996L183.925 155.681Z',
  'M169.006 155.681V147.076L161.547 142.762L154.087 147.076V155.681L161.547 159.996L169.006 155.681Z',
  'M154.087 155.681V147.076L146.627 142.762L139.168 147.076V155.681L146.627 159.996L154.087 155.681Z',
  'M139.168 155.681V147.076L131.708 142.762L124.237 147.076V155.681L131.708 159.996L139.168 155.681Z',
  'M86.9389 142.656V134.039L79.4793 129.725L72.0197 134.039V142.656L79.4793 146.959L86.9389 142.656Z',
  'M101.858 142.656V134.039L94.3986 129.725L86.939 134.039V142.656L94.3986 146.959L101.858 142.656Z',
  'M116.777 142.656V134.039L109.318 129.725L101.858 134.039V142.656L109.318 146.959L116.777 142.656Z',
  'M72.0195 142.656V134.039L64.5599 129.725L57.0886 134.039V142.656L64.5599 146.959L72.0195 142.656Z',
  'M57.0886 142.656V134.039L49.629 129.725L42.1694 134.039V142.656L49.629 146.959L57.0886 142.656Z',
  'M42.1696 142.656V134.039L34.71 129.725L27.2504 134.039V142.656L34.71 146.959L42.1696 142.656Z',
  'M206.316 142.656V134.039L198.856 129.725L191.396 134.039V142.656L198.856 146.959L206.316 142.656Z',
  'M221.118 142.656V134.039L213.659 129.725L206.199 134.039V142.656L213.659 146.959L221.118 142.656Z',
  'M191.396 142.656V134.039L183.925 129.725L176.465 134.039V142.656L183.925 146.959L191.396 142.656Z',
  'M176.466 142.656V134.039L169.006 129.725L161.546 134.039V142.656L169.006 146.959L176.466 142.656Z',
  'M161.546 142.656V134.039L154.087 129.725L146.627 134.039V142.656L154.087 146.959L161.546 142.656Z',
  'M146.627 142.656V134.039L139.168 129.725L131.708 134.039V142.656L139.168 146.959L146.627 142.656Z',
  'M131.708 142.656V134.039L124.237 129.725L116.777 134.039V142.656L124.237 146.959L131.708 142.656Z',
  'M109.318 129.69V121.073L101.858 116.758L94.3983 121.073V129.69L101.858 133.992L109.318 129.69Z',
  'M124.237 129.69V121.073L116.777 116.758L109.318 121.073V129.69L116.777 133.992L124.237 129.69Z',
  'M139.168 129.69V121.073L131.708 116.758L124.237 121.073V129.69L131.708 133.992L139.168 129.69Z',
  'M94.3983 129.69V121.073L86.9387 116.758L79.4791 121.073V129.69L86.9387 133.992L94.3983 129.69Z',
  'M79.479 129.69V121.073L72.0194 116.758L64.5598 121.073V129.69L72.0194 133.992L79.479 129.69Z',
  'M64.5599 129.69V121.073L57.0886 116.758L49.629 121.073V129.69L57.0886 133.992L64.5599 129.69Z',
  'M49.6289 129.69V121.073L42.1693 116.758L34.7097 121.073V129.69L42.1693 133.992L49.6289 129.69Z',
  'M213.776 129.69V121.073L206.316 116.758L198.856 121.073V129.69L206.316 133.992L213.776 129.69Z',
  'M198.856 129.69V121.073L191.397 116.758L183.926 121.073V129.69L191.397 133.992L198.856 129.69Z',
  'M183.925 129.69V121.073L176.466 116.758L169.006 121.073V129.69L176.466 133.992L183.925 129.69Z',
  'M169.006 129.69V121.073L161.547 116.758L154.087 121.073V129.69L161.547 133.992L169.006 129.69Z',
  'M154.087 129.69V121.073L146.627 116.758L139.168 121.073V129.69L146.627 133.992L154.087 129.69Z',
  'M101.858 116.781V108.176L94.3986 103.862L86.939 108.176V116.781L94.3986 121.096L101.858 116.781Z',
  'M116.777 116.781V108.176L109.318 103.862L101.858 108.176V116.781L109.318 121.096L116.777 116.781Z',
  'M131.708 116.781V108.176L124.237 103.862L116.777 108.176V116.781L124.237 121.096L131.708 116.781Z',
  'M86.9389 116.781V108.176L79.4793 103.862L72.0197 108.176V116.781L79.4793 121.096L86.9389 116.781Z',
  'M72.0195 116.781V108.176L64.5599 103.862L57.0886 108.176V116.781L64.5599 121.096L72.0195 116.781Z',
  'M57.0886 116.781V108.176L49.629 103.862L42.1694 108.176V116.781L49.629 121.096L57.0886 116.781Z',
  'M206.316 116.781V108.176L198.856 103.862L191.396 108.176V116.781L198.856 121.096L206.316 116.781Z',
  'M221.118 116.781V108.176L213.659 103.862L206.199 108.176V116.781L213.659 121.096L221.118 116.781Z',
  'M191.396 116.781V108.176L183.925 103.862L176.465 108.176V116.781L183.925 121.096L191.396 116.781Z',
  'M176.466 116.781V108.176L169.006 103.862L161.546 108.176V116.781L169.006 121.096L176.466 116.781Z',
  'M161.546 116.781V108.176L154.087 103.862L146.627 108.176V116.781L154.087 121.096L161.546 116.781Z',
  'M146.627 116.781V108.176L139.168 103.862L131.708 108.176V116.781L139.168 121.096L146.627 116.781Z',
  'M94.3983 103.756V95.1391L86.9387 90.8247L79.4791 95.1391V103.756L86.9387 108.059L94.3983 103.756Z',
  'M109.318 103.756V95.1391L101.858 90.8247L94.3983 95.1391V103.756L101.858 108.059L109.318 103.756Z',
  'M124.237 103.756V95.1391L116.777 90.8247L109.318 95.1391V103.756L116.777 108.059L124.237 103.756Z',
  'M79.479 103.756V95.1391L72.0194 90.8247L64.5598 95.1391V103.756L72.0194 108.059L79.479 103.756Z',
  'M213.776 103.756V95.1391L206.316 90.8247L198.856 95.1391V103.756L206.316 108.059L213.776 103.756Z',
  'M198.856 103.756V95.1391L191.397 90.8247L183.926 95.1391V103.756L191.397 108.059L198.856 103.756Z',
  'M183.925 103.756V95.1391L176.466 90.8247L169.006 95.1391V103.756L176.466 108.059L183.925 103.756Z',
  'M169.006 103.756V95.1391L161.547 90.8247L154.087 95.1391V103.756L161.547 108.059L169.006 103.756Z',
  'M154.087 103.756V95.1391L146.627 90.8247L139.168 95.1391V103.756L146.627 108.059L154.087 103.756Z',
  'M139.168 103.756V95.1391L131.708 90.8247L124.237 95.1391V103.756L131.708 108.059L139.168 103.756Z',
  'M295.784 90.7781V82.1609L288.325 77.8582L280.865 82.1609V90.7781L288.325 95.0925L295.784 90.7781Z',
  'M310.715 90.7781V82.1609L303.255 77.8582L295.784 82.1609V90.7781L303.255 95.0925L310.715 90.7781Z',
  'M280.865 90.7781V82.1609L273.405 77.8582L265.946 82.1609V90.7781L273.405 95.0925L280.865 90.7781Z',
  'M265.946 90.7781V82.1609L258.486 77.8582L251.027 82.1609V90.7781L258.486 95.0925L265.946 90.7781Z',
  'M355.473 90.7781V82.1609L348.013 77.8582L340.553 82.1609V90.7781L348.013 95.0925L355.473 90.7781Z',
  'M340.553 90.7781V82.1609L333.094 77.8582L325.634 82.1609V90.7781L333.094 95.0925L340.553 90.7781Z',
  'M325.634 90.7781V82.1609L318.175 77.8582L310.715 82.1609V90.7781L318.175 95.0925L325.634 90.7781Z',
  'M251.026 90.7781V82.1609L243.567 77.8582L236.107 82.1609V90.7781L243.567 95.0925L251.026 90.7781Z',
  'M236.107 90.7781V82.1609L228.636 77.8582L221.176 82.1609V90.7781L228.636 95.0925L236.107 90.7781Z',
  'M303.255 77.8582V69.2411L295.784 64.9267L288.324 69.2411V77.8582L295.784 82.1609L303.255 77.8582Z',
  'M318.175 77.8582V69.2411L310.715 64.9267L303.256 69.2411V77.8582L310.715 82.1609L318.175 77.8582Z',
  'M288.324 77.8582V69.2411L280.865 64.9267L273.405 69.2411V77.8582L280.865 82.1609L288.324 77.8582Z',
  'M273.406 77.8582V69.2411L265.946 64.9267L258.486 69.2411V77.8582L265.946 82.1609L273.406 77.8582Z',
  'M333.094 77.9284V69.3113L325.634 65.0086L318.175 69.3113V77.9284L325.634 82.2428L333.094 77.9284Z',
  'M258.486 77.8582V69.2411L251.026 64.9267L243.567 69.2411V77.8582L251.026 82.1609L258.486 77.8582Z',
  'M243.567 77.8582V69.2411L236.108 64.9267L228.636 69.2411V77.8582L236.108 82.1609L243.567 77.8582Z',
  'M228.496 77.8582V69.2411L221.036 64.9267L213.565 69.2411V77.8582L221.036 82.1609L228.496 77.8582Z',
  'M310.715 64.9149V56.2977L303.255 51.995L295.784 56.2977V64.9149L303.255 69.2176L310.715 64.9149Z',
  'M325.634 64.9149V56.2977L318.175 51.995L310.715 56.2977V64.9149L318.175 69.2176L325.634 64.9149Z',
  'M340.331 64.9149V56.2977L332.872 51.995L325.412 56.2977V64.9149L332.872 69.2176L340.331 64.9149Z',
  'M295.784 64.9149V56.2977L288.325 51.995L280.865 56.2977V64.9149L288.325 69.2176L295.784 64.9149Z',
  'M251.026 64.9149V56.2977L243.567 51.995L236.107 56.2977V64.9149L243.567 69.2176L251.026 64.9149Z',
  'M235.955 64.9149V56.2977L228.496 51.995L221.036 56.2977V64.9149L228.496 69.2176L235.955 64.9149Z',
  'M228.753 51.8431V43.226L221.293 38.9233L213.834 43.226V51.8431L221.293 56.1458L228.753 51.8431Z',
  'M243.298 51.8431V43.226L235.839 38.9233L228.379 43.226V51.8431L235.839 56.1458L243.298 51.8431Z',
  'M332.977 51.8431V43.226L325.517 38.9233L318.058 43.226V51.8431L325.517 56.1458L332.977 51.8431Z',
  'M318.058 51.8431V43.226L310.598 38.9233L303.139 43.226V51.8431L310.598 56.1458L318.058 51.8431Z',
  'M303.139 51.8431V43.226L295.679 38.9233L288.219 43.226V51.8431L295.679 56.1458L303.139 51.8431Z',
  'M280.923 64.9149V56.2977L273.464 51.995L266.004 56.2977V64.9149L273.464 69.2176L280.923 64.9149Z',
  'M288.278 51.8431V43.226L280.807 38.9233L273.347 43.226V51.8431L280.807 56.1458L288.278 51.8431Z',
  'M266.004 64.9149V56.2977L258.545 51.995L251.085 56.2977V64.9149L258.545 69.2176L266.004 64.9149Z',
  'M273.347 51.8431V43.226L265.887 38.9233L258.428 43.226V51.8431L265.887 56.1458L273.347 51.8431Z',
  'M258.439 51.8431V43.226L250.98 38.9233L243.52 43.226V51.8431L250.98 56.1458L258.439 51.8431Z',
  'M86.8569 90.7313V82.1259L79.3973 77.8115L71.9377 82.1259V90.7313L79.3973 95.0457L86.8569 90.7313Z',
  'M101.776 90.7313V82.1259L94.3165 77.8115L86.8569 82.1259V90.7313L94.3165 95.0457L101.776 90.7313Z',
  'M116.707 90.7313V82.1259L109.247 77.8115L101.776 82.1259V90.7313L109.247 95.0457L116.707 90.7313Z',
  'M206.234 90.7313V82.1259L198.775 77.8115L191.315 82.1259V90.7313L198.775 95.0457L206.234 90.7313Z',
  'M221.036 90.7313V82.1259L213.577 77.8115L206.117 82.1259V90.7313L213.577 95.0457L221.036 90.7313Z',
  'M191.315 90.7313V82.1259L183.855 77.8115L176.396 82.1259V90.7313L183.855 95.0457L191.315 90.7313Z',
  'M176.396 90.7313V82.1259L168.924 77.8115L161.465 82.1259V90.7313L168.924 95.0457L176.396 90.7313Z',
  'M161.465 90.7313V82.1259L154.005 77.8115L146.545 82.1259V90.7313L154.005 95.0457L161.465 90.7313Z',
  'M146.546 90.7313V82.1259L139.086 77.8115L131.626 82.1259V90.7313L139.086 95.0457L146.546 90.7313Z',
  'M131.626 90.7313V82.1259L124.167 77.8115L116.707 82.1259V90.7313L124.167 95.0457L131.626 90.7313Z',
  'M109.247 77.7645V69.1591L101.776 64.8447L94.3162 69.1591V77.7645L101.776 82.079L109.247 77.7645Z',
  'M124.167 77.7645V69.1591L116.707 64.8447L109.247 69.1591V77.7645L116.707 82.079L124.167 77.7645Z',
  'M139.086 77.7645V69.1591L131.626 64.8447L124.167 69.1591V77.7645L131.626 82.079L139.086 77.7645Z',
  'M94.3163 77.7645V69.1591L86.8567 64.8447L79.3971 69.1591V77.7645L86.8567 82.079L94.3163 77.7645Z',
  'M213.693 77.7645V69.1591L206.234 64.8447L198.774 69.1591V77.7645L206.234 82.079L213.693 77.7645Z',
  'M198.774 77.7645V69.1591L191.315 64.8447L183.855 69.1591V77.7645L191.315 82.079L198.774 77.7645Z',
  'M183.855 77.7645V69.1591L176.395 64.8447L168.924 69.1591V77.7645L176.395 82.079L183.855 77.7645Z',
  'M168.924 77.7645V69.1591L161.465 64.8447L154.005 69.1591V77.7645L161.465 82.079L168.924 77.7645Z',
  'M154.005 77.7645V69.1591L146.545 64.8447L139.086 69.1591V77.7645L146.545 82.079L154.005 77.7645Z',
  'M101.776 64.8681V56.251L94.3165 51.9482L86.8569 56.251V64.8681L94.3165 69.1825L101.776 64.8681Z',
  'M116.707 64.8681V56.251L109.247 51.9482L101.776 56.251V64.8681L109.247 69.1825L116.707 64.8681Z',
  'M131.626 64.8681V56.251L124.167 51.9482L116.707 56.251V64.8681L124.167 69.1825L131.626 64.8681Z',
  'M206.234 64.8681V56.251L198.775 51.9482L191.315 56.251V64.8681L198.775 69.1825L206.234 64.8681Z',
  'M221.036 64.8681V56.251L213.577 51.9482L206.117 56.251V64.8681L213.577 69.1825L221.036 64.8681Z',
  'M191.315 64.8681V56.251L183.855 51.9482L176.396 56.251V64.8681L183.855 69.1825L191.315 64.8681Z',
  'M176.396 64.8681V56.251L168.924 51.9482L161.465 56.251V64.8681L168.924 69.1825L176.396 64.8681Z',
  'M161.465 64.8681V56.251L154.005 51.9482L146.545 56.251V64.8681L154.005 69.1825L161.465 64.8681Z',
  'M146.546 64.8681V56.251L139.086 51.9482L131.626 56.251V64.8681L139.086 69.1825L146.546 64.8681Z',
  'M124.167 51.8314V43.226L116.707 38.9116L109.247 43.226V51.8314L116.707 56.1458L124.167 51.8314Z',
  'M213.693 51.8314V43.226L206.234 38.9116L198.774 43.226V51.8314L206.234 56.1458L213.693 51.8314Z',
  'M198.774 51.8314V43.226L191.315 38.9116L183.855 43.226V51.8314L191.315 56.1458L198.774 51.8314Z',
  'M183.855 51.8314V43.226L176.395 38.9116L168.924 43.226V51.8314L176.395 56.1458L183.855 51.8314Z',
  'M168.924 51.8314V43.226L161.465 38.9116L154.005 43.226V51.8314L161.465 56.1458L168.924 51.8314Z',
  'M154.005 51.8314V43.226L146.545 38.9116L139.086 43.226V51.8314L146.545 56.1458L154.005 51.8314Z',
  'M139.086 51.8314V43.226L131.626 38.9116L124.167 43.226V51.8314L131.626 56.1458L139.086 51.8314Z',
  'M325.518 38.9232V30.3178L318.058 26.0034L310.598 30.3178V38.9232L318.058 43.2376L325.518 38.9232Z',
  'M340.448 38.9232V30.3178L332.977 26.0034L325.517 30.3178V38.9232L332.977 43.2376L340.448 38.9232Z',
  'M310.598 38.9232V30.3178L303.139 26.0034L295.679 30.3178V38.9232L303.139 43.2376L310.598 38.9232Z',
  'M295.679 38.9232V30.3178L288.219 26.0034L280.76 30.3178V38.9232L288.219 43.2376L295.679 38.9232Z',
  'M280.76 38.9232V30.3178L273.288 26.0034L265.829 30.3178V38.9232L273.288 43.2376L280.76 38.9232Z',
  'M265.829 38.9232V30.3178L258.369 26.0034L250.91 30.3178V38.9232L258.369 43.2376L265.829 38.9232Z',
  'M250.758 38.9232V30.3178L243.298 26.0034L235.838 30.3178V38.9232L243.298 43.2376L250.758 38.9232Z',
  'M332.977 25.9916V17.3745L325.517 13.0717L318.058 17.3745V25.9916L325.517 30.2943L332.977 25.9916Z',
  'M347.908 25.9916V17.3745L340.448 13.0717L332.977 17.3745V25.9916L340.448 30.2943L347.908 25.9916Z',
  'M318.058 25.9916V17.3745L310.598 13.0717L303.139 17.3745V25.9916L310.598 30.2943L318.058 25.9916Z',
  'M273.289 25.9916V17.3745L265.829 13.0717L258.369 17.3745V25.9916L265.829 30.2943L273.289 25.9916Z',
  'M258.229 25.9916V17.3745L250.758 13.0717L243.298 17.3745V25.9916L250.758 30.2943L258.229 25.9916Z',
  'M355.251 12.9198V4.30272L347.791 0L340.332 4.30272V12.9198L347.791 17.2226L355.251 12.9198Z',
  'M340.331 12.9198V4.30272L332.872 0L325.412 4.30272V12.9198L332.872 17.2226L340.331 12.9198Z',
  'M303.197 25.9916V17.3745L295.737 13.0717L288.278 17.3745V25.9916L295.737 30.2943L303.197 25.9916Z',
  'M385.101 38.9232V30.3178L377.63 26.0034L370.17 30.3178V38.9232L377.63 43.2376L385.101 38.9232Z',
  'M400.02 38.9232V30.3178L392.56 26.0034L385.101 30.3178V38.9232L392.56 43.2376L400.02 38.9232Z',
  'M414.939 38.9232V30.3178L407.48 26.0034L400.02 30.3178V38.9232L407.48 43.2376L414.939 38.9232Z',
  'M392.443 51.8431V43.226L384.984 38.9233L377.524 43.226V51.8431L384.984 56.1458L392.443 51.8431Z',
  'M377.524 51.8431V43.226L370.065 38.9233L362.593 43.226V51.8431L370.065 56.1458L377.524 51.8431Z',
  'M370.17 38.9232V30.3178L362.71 26.0034L355.25 30.3178V38.9232L362.71 43.2376L370.17 38.9232Z',
  'M355.251 38.9232V30.3178L347.791 26.0034L340.332 30.3178V38.9232L347.791 43.2376L355.251 38.9232Z',
  'M392.56 25.9916V17.3745L385.101 13.0717L377.629 17.3745V25.9916L385.101 30.2943L392.56 25.9916Z',
  'M407.479 25.9916V17.3745L400.02 13.0717L392.56 17.3745V25.9916L400.02 30.2943L407.479 25.9916Z',
  'M377.629 25.9916V17.3745L370.17 13.0717L362.71 17.3745V25.9916L370.17 30.2943L377.629 25.9916Z',
  'M414.822 12.9198V4.30272L407.363 0L399.903 4.30272V12.9198L407.363 17.2226L414.822 12.9198Z',
  'M422.282 25.9916V17.3745L414.822 13.0717L407.362 17.3745V25.9916L414.822 30.2943L422.282 25.9916Z',
  'M429.636 12.9198V4.30272L422.176 0L414.717 4.30272V12.9198L422.176 17.2226L429.636 12.9198Z',
  'M399.903 12.9198V4.30272L392.443 0L384.984 4.30272V12.9198L392.443 17.2226L399.903 12.9198Z',
  'M384.984 12.9198V4.30272L377.524 0L370.065 4.30272V12.9198L377.524 17.2226L384.984 12.9198Z',
  'M362.769 25.9916V17.3745L355.309 13.0717L347.849 17.3745V25.9916L355.309 30.2943L362.769 25.9916Z',
  'M370.111 12.9198V4.30272L362.652 0L355.192 4.30272V12.9198L362.652 17.2226L370.111 12.9198Z',
  'M288.278 25.9916V17.3745L280.807 13.0717L273.347 17.3745V25.9916L280.807 30.2943L288.278 25.9916Z',
  'M235.967 38.8413V30.2241L228.508 25.9214L221.048 30.2241V38.8413L228.508 43.1557L235.967 38.8413Z',
  'M221.048 38.8413V30.2241L213.588 25.9214L206.117 30.2241V38.8413L213.588 43.1557L221.048 38.8413Z',
  'M206.117 38.8413V30.2241L198.657 25.9214L191.198 30.2241V38.8413L198.657 43.1557L206.117 38.8413Z',
  'M191.198 38.8413V30.2241L183.738 25.9214L176.279 30.2241V38.8413L183.738 43.1557L191.198 38.8413Z',
  'M176.279 38.8413V30.2241L168.819 25.9214L161.359 30.2241V38.8413L168.819 43.1557L176.279 38.8413Z'
]
