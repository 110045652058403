import React from 'react'
import styled from 'styled-components'
import { ToastContainerBox } from '../../../Init'
import { useSelector } from 'react-redux'
import { uuidSelector } from '../../../../modules/deleteAccount/selector'
import { useLocation, Redirect } from 'react-router-dom'
import DeleteAccountNavigation from './DeleteAccountNavigation'

const Container = styled.div`
  > .delete_account__header {
    box-sizing: border-box;
    height: 56px;
    padding: 16px;

    > .delete_account__header_button {
      cursor: pointer;
    }
  }

  .delete_account__wrap {
    box-sizing: border-box;

    margin: 0 auto;
    margin-top: 16px;
    width: 100%;
    max-width: 492px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 1000px) {
      padding: 0 16px;
    }

    p {
      margin: 0;
      padding: 0;
    }

    > .delete_account__title {
      font-size: 24px;
      font-weight: 800;
      line-height: 32px;
      margin: 0;
    }
  }
`

const OTP_REQUEST_PATH_REGEXP = new RegExp(/^\/delete\/account\/(rider|driver)$/)

function DeleteAccountContainer({ title, children, showNavigation, ...props }) {
  const { pathname } = useLocation()
  const uuid = useSelector(uuidSelector)

  if (!OTP_REQUEST_PATH_REGEXP.test(pathname) && !uuid) {
    return <Redirect to="/delete/account/rider" />
  }

  return (
    <Container {...props}>
      {showNavigation && <DeleteAccountNavigation />}
      <div className="delete_account__wrap">
        {title && <h1 className="delete_account__title">{title}</h1>}
        {children}
        <ToastContainerBox
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          style={{
            zIndex: 9999
          }}
          className="toast-box-cls"
          toastClassName="toast-cls"
          bodyClassName="toast-body-cls"
        />
      </div>
    </Container>
  )
}

export default DeleteAccountContainer
