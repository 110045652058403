import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;

  .form_row__label {
    margin-bottom: 4px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
  }

  .form_row__input_group {
    display: flex;
    gap: 8px;
  }
`

function DeleteAccountRow({ label, children }) {
  return (
    <Wrapper>
      {label && <p className="form_row__label">{label}</p>}
      <div className="form_row__input_group">{children}</div>
    </Wrapper>
  )
}

export default DeleteAccountRow
