import styled from 'styled-components'
import { decodeDescription } from '../../utils/MyUtils'

const AnswerHTMLContainer = styled.div`
  margin-top: 12px;
  padding: 0 24px;

  ul,
  ol {
    padding: 0 24px;
  }

  * > li {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #414345;
    &::marker {
      font-size: 10px;
    }
  }

  * > li + li {
    margin-top: 12px;
  }
`

const AnswerTextContainer = styled.div`
  margin-top: 12px;
  padding: 0 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #414345;
`

export function Answer({ content, type }) {
  return type === 'HTML' ? (
    <AnswerHTMLContainer>
      <div
        dangerouslySetInnerHTML={{
          __html: decodeDescription(content)
        }}
      />
    </AnswerHTMLContainer>
  ) : (
    <AnswerTextContainer>{content}</AnswerTextContainer>
  )
}
