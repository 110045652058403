import {
  AVAILABLE_LOCALE_POLICY,
  AVAILABLE_POLICY_REGION,
  COORD_BY_REGION,
  LOCALE_TYPE,
  POLICY_BY_REGION,
  REGION_BY_LOCALE
} from '../constants/constants'

export const getCurrentLocation = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject)
  })

export const getRegionUsingCoords = (lat, lon) =>
  Object.keys(COORD_BY_REGION).find(key => {
    const { SW, NE } = COORD_BY_REGION[key]
    return lat >= SW.lat && lon >= SW.lon && lat <= NE.lat && lon <= NE.lon
  })

export const getCurrentRegion = async () => {
  // catch error when denied Geolocation
  try {
    const { coords: position } = await getCurrentLocation()
    return getRegionUsingCoords(position.latitude, position.longitude)
  } catch (e) {
    return null
  }
}

const validateRegion = region => (AVAILABLE_POLICY_REGION.includes(region) ? region : '')

// policy: POLICY 타입, locale은 en, vi, km, ko, zh-CN, th
export const getPolicy = async (policy, locale, regionParam) => {
  const region = await getCurrentRegion()
  const regionData = validateRegion(regionParam) || region || REGION_BY_LOCALE[locale].at(0) || 'SG'

  // region, locale에 맞는 policy를 지원하지 않을 때 같은 region의 en 버전으로 지원
  if (
    !AVAILABLE_LOCALE_POLICY[policy][regionData].includes(locale) &&
    AVAILABLE_LOCALE_POLICY[policy][regionData].includes(LOCALE_TYPE.EN)
  ) {
    // en policy 지원된다면 같은 region의 en policy 리턴
    return POLICY_BY_REGION[regionData][LOCALE_TYPE.EN][policy]
  }

  // en policy 지원 안된다면 기존처럼 SG policy fallback
  return POLICY_BY_REGION[regionData][locale][policy]
}

export const normalizeLanguage = language => {
  if (['en', 'eng', 'english'].includes(language)) {
    return LOCALE_TYPE.EN
  }

  if (['ko', 'kr', 'kor'].includes(language)) {
    return LOCALE_TYPE.KO
  }

  if (['km', 'kh', 'khmer'].includes(language)) {
    return LOCALE_TYPE.KM
  }

  if (['vi', 'vn', 'viet'].includes(language)) {
    return LOCALE_TYPE.VI
  }

  if (['th', 'thai'].includes(language)) {
    return LOCALE_TYPE.TH
  }

  if (['zh', 'zh_hans', 'hans', 'zh-CN'].includes(language)) {
    return LOCALE_TYPE.ZH_CN
  }

  if (['zh_hant', 'hant', 'zh-TW'].includes(language)) {
    return LOCALE_TYPE.ZH_TW
  }

  return LOCALE_TYPE.EN
}
