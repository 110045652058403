import React, { Component } from 'react'
import styled from 'styled-components'
import { scrollToTop } from '../../utils/MyUtils'
import backgroundImg from '../../assets/images/tada/black-map-2.png'
import coloredBackgroundImg from '../../assets/images/tada/white-map-2-copy.png'
import MyStorage from '../../utils/MyStorage'
import Banner from '../Banner'
import Header from '../Header'
import Footer from '../Footer'
import autoBind from 'react-autobind'
import Init from '../Init'

export const PageDiv = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 100%;
`

export const PageContainer = styled.div`
  ${props =>
    props.isColored ? `background-image: url(${coloredBackgroundImg})` : `background-image: url(${backgroundImg})`};
  background-size: cover;
  background-position: center top;
  width: 100%;
  padding-top: 120px;

  @media (max-width: 1000px) {
    padding-top: 65px;
  }
`

export const BrandingPageContent = styled.div`
  width: 1160px;
  margin: 134px auto;
  display: grid;
  grid-template-columns: 695px auto;
  grid-row-gap: 80px;

  @media (max-width: 1000px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    // grid-template-columns: auto;
    width: 100%;
    > * {
      margin-bottom: 65px;
    }
  }

  @media (max-width: 1260px) and (min-width: 1001px) {
    width: 950px;
    grid-template-columns: 640px auto;
  }
`

export default class BrandingPageContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isBannerShow: false
      // isBannerShow: MyStorage.get('banner_closed') !== 'true'
    }
    autoBind(this)
  }
  componentDidMount () {
    scrollToTop()
  }

  handleCloseBanner () {
    MyStorage.set('banner_closed', 'true', new Date().getTime() + 1000 * 60 * 60 * 24)
    this.setState({
      isBannerShow: false
    })
  }

  render () {
    const { children, isColored } = this.props
    const { isBannerShow } = this.state

    return (
      <Init>
        {isBannerShow && <Banner onCloseBanner={this.handleCloseBanner} />}
        <PageDiv>
          <Header />
          <PageContainer isColored={isColored}>{children}</PageContainer>
          <Footer />
        </PageDiv>
      </Init>
    )
  }
}
