import { withNamespaces } from 'react-i18next'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  ${({ language }) =>
    language === 'km'
      ? `
        *:not([class*=icons]):not([class*=fa-]) {
          font-family: Twemoji Country Flags, battambang, Roboto, Helvetica, Arial, sans-serif !important;
        }
      `
      : `
        *:not([class*=icons]):not([class*=fa-]) {
          font-family: Twemoji Country Flags, Roboto, Helvetica Neue,Helvetica, Arial, Apple SD Gothic Neo,나눔 고딕, Nanum Gothic, 맑은 고딕, Malgun Gothic, dotum , sans-serif !important;
        }
      `}

    *[class^='animation-step-'] {
      position: relative;
      overflow: hidden;

      > div, > img {
        position: absolute;
        display: block;
        transform: translate3d(0, 0, 0);

        /* 위로 올라와서 보여짐 */
        &.ts-switch-in {
          opacity: 0;
          animation-name: switch-in;
          animation-duration: 500ms;
          animation-timing-function: ease-out;
          animation-direction: normal;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        /* 위로 올라가서 사라짐 */
        &.ts-switch-out {
          animation-name: switch-out;
          animation-duration: 500ms;
          animation-timing-function: ease-out;
          animation-direction: normal;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      }
    }

    .ts-fade-in {
      min-height: 1px;
      animation-name: fade-in;
      animation-duration: 500ms;
      animation-timing-function: ease-out;
      animation-direction: normal;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .ts-fade-out {
      min-height: 1px;
      animation-name: fade-out;
      animation-duration: 500ms;
      animation-timing-function: ease-out;
      animation-direction: normal;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .hidden-section {
      position: relative !important;
      width: 100%;
      height: 100%;
      visibility: hidden;
    }

    .animation-step-2 > div {
      animation-delay: 0.05s;
    }
    .animation-step-3 > div {
      animation-delay: 0.1s;
    }
    .animation-step-4 > div {
      animation-delay: 0.15s;
    }
    .animation-step-5 > div {
      animation-delay: 0.2s;
    }
    .animation-step-6 > div {
      animation-delay: 0.25s;
    }
    .animation-step-7 > div {
      animation-delay: 0.3s;
    }
    .animation-step-8 > div {
      animation-delay: 0.35s;
    }
    .animation-step-9 > div {
      animation-delay: 0.4s;
    }
    .animation-step-10 > div {
      animation-delay: 0.45s;
    }

    /* Move in */
    @keyframes switch-in {
      from {
        transform: translate3d(0, 100%, 0);
      }
      to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        color: #fff;
      }
    }

    /* Move out */
    @keyframes switch-out {
      from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        color: #fff;
      }
      to {
        display: none;
        transform: translate3d(0, -100%, 0);
        opacity: 0;
      }
    }

    @keyframes fade-in {
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
    }

    @keyframes fade-out {
      from {
          opacity: 1;
      }
      to {
          opacity: 0;
      }
    }
`

function GlobalStyleContainer({ i18n }) {
  return <GlobalStyle language={i18n.language} />
}

export default withNamespaces()(GlobalStyleContainer)
