import React, { Component } from 'react'
import styled from 'styled-components'
import { scrollToTop } from '../../utils/MyUtils'

import Banner from '../Banner'
import Header from '../Header'
import Footer from '../Footer'
import Article from './Article'
import MyStorage from '../../utils/MyStorage'
import autoBind from 'react-autobind'
import Init from '../Init'

export const PageContainer = styled.div`
  min-height: 100%;
`

export default class DocumentPageContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isBannerShow: false
      // isBannerShow: MyStorage.get('banner_closed') !== 'true'
    }
    autoBind(this)
  }

  componentDidMount () {
    scrollToTop()
  }

  handleCloseBanner () {
    MyStorage.set('banner_closed', 'true', new Date().getTime() + 1000 * 60 * 60 * 24)
    this.setState({
      isBannerShow: false
    })
  }

  render () {
    const { children } = this.props
    const { isBannerShow } = this.state

    return (
      <Init>
        <PageContainer>
          {isBannerShow && <Banner onCloseBanner={this.handleCloseBanner} />}
          <div>
            <Header />
            <Article>{children}</Article>
            <Footer />
          </div>
        </PageContainer>
      </Init>
    )
  }
}
