import React, { useState } from 'react'
import { translate } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { HELP_CENTER_DETAIL_REPORT_HEIGHT, HELP_CENTER_REPORT_MIN_LENGTH } from '../../../../constants/constants'
import { fetchReportRequest, fetchReportSuccess } from '../../../../modules/helpCenter/reducer'
import { useActionSubscribe } from '../../../../store/middleware/actionSubscribe'
import Button from '../../../Common/Button'
import MobileModal from '../../../Common/MobileModal'
import { sanitizeReportParam, sendExitHelpCenterToApp } from '../hooks'

const DetailReport = styled.div`
  height: ${HELP_CENTER_DETAIL_REPORT_HEIGHT}px;

  > .help_center__report {
    height: 310px;
    border-top: 8px solid #edeff1;
    box-sizing: border-box;
    padding: 16px 24px;
    padding-bottom: 20px;

    > p {
      margin: 0;
    }

    > .help_center__report__label {
      font-size: 14px;
      color: #747678;
      margin-bottom: 8px;
    }

    > .help_center__report__input {
      &::placeholder {
        color: rgba(12, 12, 12, 0.3);
      }

      padding: 12px 16px;
      margin-bottom: 8px;

      width: 100%;
      height: 226px;
      resize: none;

      border: 1px solid #dfe1e3;
      border-radius: 6px;

      box-sizing: border-box;

      font-size: 14px;
    }

    > .help_center__report__length {
      text-align: right;
      font-size: 12px;
      color: #747678;
    }
  }

  > .help_center__report_button {
    box-sizing: border-box;
    padding: 16px 24px;
    height: 80px;

    > .help_center_report__button_submit {
      width: 100%;
      height: 100%;

      border-radius: 6px;

      color: #000;

      box-sizing: border-box;
      background: linear-gradient(135deg, #ffd74f 0%, #ffc400 100%);
      ${({ submitDisabled }) => submitDisabled && 'opacity: 45%'};
    }
  }
`

function HelpCenterDetailReport ({ t, topic }) {
  const dispatch = useDispatch()

  const [description, setDescription] = useState('')
  const [submitModal, setSubmitModal] = useState(false)

  const { reportInfo } = useSelector(state => state.helpCenter)

  const handleClickSubmit = () => {
    dispatch(
      fetchReportRequest({
        description,
        topicId: topic.id,
        ...sanitizeReportParam(reportInfo)
      })
    )
  }

  const toggleSubmit = () => {
    setSubmitModal(!submitModal)
  }

  const handleClickOk = () => {
    toggleSubmit()
    sendExitHelpCenterToApp()
  }

  useActionSubscribe(fetchReportSuccess.type, () => {
    toggleSubmit()
  })

  return (
    <DetailReport submitDisabled={description.length < HELP_CENTER_REPORT_MIN_LENGTH}>
      <MobileModal
        isOpen={submitModal}
        isCancelVisible={false}
        title={t('report.title_submit_modal')}
        content={t('report.content_submit_modal')}
        toggle={() => toggleSubmit()}
        onOk={() => handleClickOk()}
      />
      <div className='help_center__report'>
        <p className='help_center__report__label'>{t('report.label_report_an_issue')}</p>
        <textarea
          className='help_center__report__input'
          placeholder={t('report.label_describe_issue')}
          value={description}
          onChange={e => {
            if (e.target.value.length > 500) {
              return
            }
            setDescription(e.target.value)
          }}
        />
        <p className='help_center__report__length'>
          {description.length} / 500 (
          {t('report.label_minimum_character', { minLength: HELP_CENTER_REPORT_MIN_LENGTH })})
        </p>
      </div>
      <div className='help_center__report_button'>
        <Button
          className='help_center_report__button_submit'
          disabled={description.length < HELP_CENTER_REPORT_MIN_LENGTH}
          onClick={handleClickSubmit}
        >
          {t('signup.submit')}
        </Button>
      </div>
    </DetailReport>
  )
}

export default translate()(HelpCenterDetailReport)
