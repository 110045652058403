import styled from 'styled-components'

const Content = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

function DeleteAccountContent(props) {
  return <Content {...props} />
}

export default DeleteAccountContent
