import store from 'store/dist/store.modern'
import _ from 'lodash'
import expirePlugin from 'store/plugins/expire'
import { LANGS } from '../i18n'

store.addPlugin(expirePlugin)

const namespace = 'mvl'

export const KEYS = {
  user: 'user',
  login: 'login',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  selectedLocale: 'selectedLocale'
}

const get = (key) => store.get(`@${namespace}:${key}`)

const set = (key, value, expires) => {
  if (expires) {
    store.set(`@${namespace}:${key}`, value, expires)
  } else {
    store.set(`@${namespace}:${key}`, value)
  }
}

const remove = (key) => store.remove(`@${namespace}:${key}`)

const clearAll = () => store.clearAll()

const MyStorage = {
  get,
  set,
  remove,
  clearAll
}

export default MyStorage

export const getStorageLocale = () => {
  return get(KEYS.selectedLocale)
}

export const setStorageLocale = (locale) => {
  if (_.includes(LANGS, locale)) {
    return set(KEYS.selectedLocale, locale)
  }
  return false
}
