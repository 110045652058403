// !!! Do not edit this file directly.
// !!! 이 파일을 직접 수정하지 말고 sheet 수정 후 script 실행할 것
export default {
  translations: {
    sg_taxi: {
      'ComfortDelgro & Citycab': 'ComfortDelGro & CityCab',
      Premier: 'Premier',
      Prime: 'Prime ',
      'SMRT Cab': 'SMRT',
      'Trans-cab': 'Trans-Cab',
      'Yellow-Top': 'Yellow-Top',
      HDT: 'HDT',
    },
    ph_taxi_plate: '택시 번호판 번호',
    desc_mdt_must: '택시 MDT의 사진이 표시되어야 합니다',
    desc_mdt_name: '기사님 성함',
    desc_mdt_plate: '차량 번호판 번호 ',
    desc_mdt_date: '최근 날짜(가장 최근에 찍은 사진)',
    btn_save_next: '저장 & 다음',
    btn_later: '나중에',
    repeat_password: '비밀번호 재확인',
    phone_number: '휴대폰 번호',
    digit_pin_number: 'PIN번호(4자리)',
    refferal_code: '추천인 코드 ',
    singapore: '싱가포르 ',
    country: '국가',
    signup: {
      introduce1:
        'TADA 운전자 등록 페이지에 오신 것을 환영하오며, TADA의 파트너가 되기 위해 다음과 같은 정보를 입력해주셔야 합니다. 신청서 작성 완료를 위해 다음과 같은 서류를 등록해주시길 바라며, 작성 중 문제 발생 시 문의 부탁드립니다.',
      email: 'hotro@tada.global',
      introduce2: '영업일 기준 1일 이내로 연락 드리겠습니다. 문서는 이미지 또는 pdf 형식으로 등록 할 수 있습니다.',
      submit: '제출',
      login_here: '로그인',
      refferal_code: '추천인 코드(선택사항)',
      pin_number: 'PIN 번호',
      mobile_number: '휴대폰 번호',
      agree: '동의합니다.',
      my_information: '내 정보',
    },
    driver: {
      available_after_complete: '완료 후 사용가능',
      filled_out_zero: '{{totalSteps}} 중 0개의 작성이 완료 되었습니다.',
      my_page: '마이페이지',
      application: '기사 앱',
      application_taxi: '택시기사 앱',
      application_tuktuk: '뚝뚝 운전자 앱',
      application_bike: '자전거 운전사 신청',
      application_not_complete: '신청서 작성이 완료되지 않았습니다.',
      filled_out: '{{totalSteps}} 중 {{doneSteps}} 작성이 완료 되었습니다.',
      being_reviewed: '현재 신청서를 검토중 입니다',
      information: '개인 정보',
      name: '이름',
      mobile_number: '휴대폰 번호 ',
      refferal_code: '추천인 코드 ',
      logout: '로그아웃',
      application_complete: '신청서 작성 완료되었으며, 확인 이메일 발송해드렸습니다.',
      application_not_succesful: '신청서가 승인불가 처리 되었습니다. 이메일 확인 후, 명시된 문서를 다시 제출해 주세요.',
    },
    judicial_record: '범죄 이력 ',
    vehicle_registration: '차량 등록증',
    tuktuk_registration_card: '툭툭 등록 카드',
    certificate_of_registry: '등록 증명서\n',
    commercial_insurance_certificate: '상업 보험 증서',
    contract_car_badge: '계약 자동차 배지',
    national_id: '주민등록번호',
    identity_card_number: '신분증 번호\n',
    license_plate_number: '자동차 등록 번호 ',
    car_make: '차량제조사',
    car_make_ex: '차량제조사 (예: BMW)',
    car_model_ex: '차량제조사 (예: QM3)',
    bike_make: '오토바이제조사',
    bike_model: '오토바이모델',
    desc_sharp_clear_photo: '선명한 사진으로 등록해 주세요.',
    valid_driving_license: '유효한 운전 면허증을 등록해야합니다. (만료되지않은 운전 면허증)',
    valid_document: '유효한 문서를 등록해야합니다. (만료되지않은 문서)',
    valid_nric: '유효한 NRIC를 업로드해야합니다 (예 : 만료되지 않음).',
    valid_registration_card: '유효한 등록 카드를 업로드해야합니다 (만료되지 않은 등록 카드)\n',
    waring_acra_form: '자영업자이고 소득세를 목적으로 등록 된 회사가 있음을 보여주는 문서의 처음 3 페이지를 업로드하십시오.',
    waring_bike_form: '번호판은 모든 서류와 일치해야합니다.',
    acra_form_skip: '렌트카로 운전할 계획 인 분은이 단계를 거치지 않아도됩니다.',
    upload_the_backside: '차량정보가 포함된 뒷면을 등록해주세요.',
    gps_tracking_device: 'GPS 추적 장치가 포함된 Commercial type',
    license_plate: 'commercial insurance에 기입되있는 자동차 번호판 번호는, 등록하려는 번호와 일치하여야 합니다.',
    national_id_uploaded: '기한 만료 전의 유효한 National ID를 등록해주세요.',
    upload_driving_license_front: '운전면허증(앞면)',
    upload_driving_license_back: '운전면허증(뒷면)',
    upload_judicial_record: '범죄 이력 서류 (앞면)',
    upload_vehicle_registration: '차량 등록 업로드 (뒷면)\n',
    upload_certificate_of_registry: '레지스트리 인증서 업로드 (뒤로)\n',
    upload_insurance: '보험 서류(앞면)',
    upload_insurance_certificate: '보험 증서 업로드 (뒷면)\n',
    upload_nric_front: 'NRIC(앞면)',
    upload_nric_back: 'NRIC(뒷면)',
    upload_file: '파일 업로드',
    upload_pdvl_front: 'PDVL(앞면)',
    upload_decal: '개인차량 데칼',
    upload_car_contract_badge: '자동차 계약 배지(앞면)',
    upload_national_id: 'National ID 등록 (앞면)',
    upload_identity_card_number_front: '신분 카드 번호 업로드 (앞면)\n',
    upload_identity_card_number_back: '신분 카드 번호 업로드 (뒷면)',
    upload_bike_picture_front: '전체 자전거 사진 업로드',
    upload_bike_picture_back: '자전거의 절반 이상을 보여주는 다른 자전거 그림을 번호판과 함께 업로드하십시오.',
    uploaded_profile_picture: '프로필 사진',
    upload_tuktuk_registration_card: 'Tuk Tuk 등록 카드 업로드 (앞면)\n',
    upload_profile_picture: '이 단계를 건너뛸 경우, 탑승 수락 시작 전에 프로필사진 등록 메시지가 뜹니다. \n',
    no_wearing_of_accessories: '당신의 얼굴을 정확히 인식하기 위해 악세서리 착용을 하지 마세요 (예: 모자, 선글라스 등)',
    please_white_background: '배경은 흰색이어야 합니다. ',
    please_upload_photo: '선명한 사진이 필요합니다. ',
    taxi_license_plate_num: '택시 번호판 번호',
    section_taxi_details: '택시 세부 정보',
    desc_upload_taxi_front: '택시 정면 사진 ',
    desc_upload_mdt: '택시 MDT 사진',
    reset_password_success: '계정({{email}})의 비밀번호가 안전하게 변경되었습니다.',
    change_password: '비밀번호 변경',
    forgot_password: '비밀번호 찾기',
    reset_password: '비밀번호 재설정',
    error_password_length: '비밀번호는 6~20자여야 합니다.',
    faq: 'FAQ',
    login: '로그인',
    login_required: '로그인이 필요합니다.',
    logout: '로그아웃',
    sign_up: '가입하기',
    taxi_sign_up: '택시 기사 등록 ',
    my_page: 'My Page',
    email: '이메일 ',
    email_placeholder: '이메일 주소를 입력하세요.',
    email_used: '{{email}}은 이미 사용 중인 이메일 주소입니다.',
    password: '비밀번호 ',
    password_placeholder: '비밀번호를 입력하세요.',
    password_confirm: '비밀번호 확인',
    password_confirm_placeholder: '비밀번호를 재입력해 주세요.',
    new_password_placeholder: '새로운 비밀번호를 입력해 주세요.',
    verify_mail_title: '인증 메일 확인 요청',
    verify_mail_desc: '등록된 이메일 주소({{email}})로 인증 메일이 전송되었으니 확인해주세요',
    privacy_policy: '개인정보보호정책',
    terms_and_conditions: '이용약관',
    button_confirm: '확인',
    button_cancel: '취소',
    locale: {
      en: 'English',
      ko: '한국어',
      zh_hans: '简体中文',
      zh_hant: '繁體中文',
      vi: 'Tiếng Việt',
      km: 'ភាសាខ្មែរ',
      th: 'ภาษาไทย',
      'zh-CN': '중국어 간체',
      'zh-TW': '중국어 번체',
      jp: '日本語',
    },
    btn_save: '저장',
    first_name: '이름 (예: 영철)',
    last_name: '성 (예: 김)',
    phone_placeholder: '전화번호 ',
    wrong_number_request: '{{driverPhone}} 잘못된 번호입니다. 다른 번호를 입력하십시오.\n',
    photo_file_format: '.jpg, .png',
    delete_image: '이미지 삭제하기',
    upload_image: '이미지 등록하기 ',
    agree_policy_please: '이용약관에 동의해주세요.',
    button_resend_email_verify: '인증메일 재전송',
    success_resend_email_verify: '인증메일을 전송하였습니다.',
    optional_brackets: '(선택 사항)',
    error_time_out: '연결시간이 초과되었습니다. 다시 시도해주세요.',
    error_captcha_not_valid: 'Captcha 인증을 해주세요.',
    car_model: '차량 모델',
    car_photo_warn: '차량 번호판이 선명히 보이도록 차량 정면 사진을 등록해 주세요.',
    plate_number: '차량 번호',
    banking_details: '은행 정보',
    bank_account_holder_name_failed: '다시 시도해 주세요. 특수문자 및 번호는 사용 불가합니다.',
    bank_account_holder_name: '계좌 소유자명',
    bank_select_name: '은행명',
    bank_account_number: '계좌번호',
    bank_branch: '은행 지점',
    bank_skip_warn: '이 단계를 건너 뛸 경우, 출금 요청시 은행 계좌 정보 재입력 메시지가 뜹니다.\n',
    warning_when_leaving_without_saving: '이 페이지를 나가시겠습니까? ',
    upload_bank_picture: '은행 명세서 사진 등록하기',
    bank_photo_warn: '계좌 이름과 은행 계좌 번호가 표시된 계좌 정보 사진 (ATM 영수증 또는 은행 계좌 명세서)을 업로드하십시오.\n',
    profile_picture: '프로필 사진',
    nric: 'NRIC',
    driving_license: '운전면허증',
    acra_bizfile: 'ACRA Bizfile',
    insurance_certificate: '보험증',
    error_password_confirm: '비밀번호가 맞지 않습니다.',
    phone_used: '이미 사용중인 전화번호입니다.',
    send_pin: '핀번호 보내기 ',
    edit: '수정',
    tada: {
      home:
        '<p>TADA에서는<br/>\b친절한 서비스를 공정한 가격으로<br/>만날 수 있습니다.</p><p> TADA에 오신 것을 환영합니다.</p><p>TADA는 드라이버에게 수수료를 청구하지 않으며,<br/>안전하고 친절한 서비스로 승객을 모시는 드라이버에게 인센티브를 지급합니다.</ p> <p>승객들은 합리적인 이용요금을 지불하고, <br/>언제 어디서나 안전하게 탑승하며, <br/>사용 후기를 남겨 인센티브를 지급 받습니다.</ p> <p>TADA는 드라이버와 승객이 신뢰를 기반으로 연결되는 플랫폼입니다.</ p> <p> 새로운 라이드 헤일링 시대에 오신 것을 환영합니다.</ p>',
      driver: {
        app: {
          name_1: '매칭',
          name_2: '픽업',
          name_3: '주행',
          name_4: '주행 완료 & 결제',
          waiting: '현재 TADA의 iOS 버젼을 검토중입니다. 빠른 시일 내에 iOS 앱에서 다운로드 되도록 만들겠습니다!',
        },
        info: {
          title_1: '제로 커미션',
          content_1: 'TADA는 수수료가 없습니다. 카드 수수료를 제외한 수익 전액은 운전자의 정당한 몫이며, 저희는 이 원칙을 계속 지켜갈 것입니다.',
          title_2: '간편한 드라이버 등록',
          content_2:
            '등록 정보를 간소화하여 드라이버 등록에 편의를 더했습니다. 또한 Lite 버전의 등록 프로세스를 별도로 마련하여 불필요한 수고를 줄였습니다.',
          title_3: '정밀한 매칭 시스템',
          content_3:
            'TADA는 빅데이터 처리 기술을 바탕으로 운전자의 서비스 제공 패턴을 정밀하게 분석한 뒤, 더 많은 고객들과 매칭 될 수 있는 개인화된 배차 시스템을 제공합니다.',
          title_4: '매끄러운 고객 연결',
          content_4: '승객의 목적지와 가까워지면 새로운 승객의 호출을 미리 확인할 수 있습니다. 지체하는 시간 없이 빠르게 탑승객을 맞이하세요.',
          title_5: '추가 리워드 : 안전 운행 보상',
          content_5: '고객에게 안전하고 만족도 높은 서비스를 제공할 경우, 서비스 요금과 별도로 TADA에서 제공하는 포인트를 적립해드립니다.',
          title_6: '보상:\n안전운전에 대한 인센티브',
          content_6: '승객에게 더 좋은 서비스를 제공 시, 이용 요금과 별개로 MVL 포인트를 추가로 제공합니다.\n(2018년 4분기부터 서비스 시작)',
        },
        driverappdownload: '드라이버 앱 다운로드',
      },
      rider: {
        app: {
          name_1: '차량 호출',
          name_2: '드라이버 매칭',
          name_3: '목적지로 운행 ',
          name_4: '결제 및 리뷰 ',
        },
        info: {
          title_1: '\b승객을 위한 빠른 라이드',
          content_1: '뛰어난 매칭 기술로 드라이버와 빠르게 연결합니다. 또한 엄선된 운전자를 기반으로 친절하고 안전한 주행 서비스를 제공합니다.',
          title_2: '보다 합리적인 가격',
          content_2: '택시를 잡기 어려운 출퇴근시간이나 궂은 날씨의 환경에서도 TADA는 빠르게 운전자를 찾아 당신과 매칭해 줍니다.\n',
          title_3: '피크타임에도 빠른 매칭',
          content_3: '택시를 잡기 어려운 출퇴근시간이나 궂은 날씨의 환경에서도 TADA는 빠르게 운전자를 찾아 당신과 매칭해 줍니다.\n',
          title_4: '넓은 서비스 커버리지',
          content_4: 'TADA는 위치를 가리지 않습니다. 당신이 싱가포르 \n어디에 있든지, 조건에 맞는 운전자를 정확하게 찾아 \n연결시켜줍니다.',
          title_5: '빠르고 쉬운 결제',
          content_5: '현금과 신용카드 등 다양한 결제 수단을 지원하며 당신이 원하는 방식으로 간편하게 결제할 수 있습니다. \n',
          title_6: '\b꼼꼼한 리뷰 관리 ',
          content_6: '\b사용자의 피드백을 기반으로한 운전자 관리 시스템으로, 신뢰할 수 있는\n운전자와의 매칭으로 서비스 만족도를 높여줍니다. ',
        },
        riderappdownload: '승객용 앱 다운로드',
      },
      contact: {
        contact_us: '문의',
        name: {
          label: '이름 또는 닉네임(필수)',
          placeholder: '(최대 30자)',
        },
        email: {
          label: '이메일 주소 (필수)',
          example: '(예: mvler@mvlchain.io)',
          placeholder: {
            name: 'MVLER',
            domain: 'mvlchain.io',
          },
        },
        title: {
          label: '제목(필수)',
          placeholder: '(최대 30자)',
        },
        content: {
          label: '내용(필수)',
          placeholder:
            "[공지]  \\n\\n최대 2000자 이내\\n-자세한 내용을 적어주세요. \\n-욕설은 자제해주세요. \\n-개인 정보 (ID, 비밀번호, 연락처 정보 등)를 입력하지마세요. \\n-추가 정보 필요시 연락드리겠습니다. \\n-아래 '제출'버튼을 누르면 위치, IP, OS 등의 데이터 공개에 동의하게됩니다.",
        },
        submit: {
          request: {
            submit: '제출',
            title: '제출하시겠습니까?',
            desc: '(이메일 주소:',
            review: '후기',
          },
          confirm: {
            title: '등록이 완료되었습니다.',
            desc: '검토 후 답변 드리겠습니다.',
            confirm: '확인',
          },
        },
      },
    },
    title: {
      code_of_contact: '운전자 행동 수칙',
    },
    driver_safety_and_accident0_question: '사고가 발생하였습니다. ',
    driver_safety_and_accident1_question: '승객의 언어적 및 신체적 폭력',
    driver_safety_and_accident2_question: '성적 학대/성희롱',
    driver_safety_and_accident3_question: '차량 고장, 정비',
    driver_safety_and_accident4_question: '코로나19 대책',
    rider_safety_and_accident0_question: '제가 타고 있는 차량이 사고가 났습니다. ',
    rider_safety_and_accident1_question: '신체적/언어적 폭력',
    rider_safety_and_accident2_question: '성적 학대/성희롱',
    rider_safety_and_accident3_question: '위험한 운전 습관',
    rider_safety_and_accident4_question: '코로나 19 대책',
    rider_cancellation_table_content:
      '긍정적인 승차 호출 생태계를 구축하기 위해서는 운전사와 승객 모두의 노력이 필요합니다. 따라서 운전사가 여러분의 예약을 수락한 후에는 그에 대한 책임을 더욱 강화하기 위해 운전사와 함께 취소 정책을 시행하게 되었습니다.',
    th_driver_safety_and_accident0_question: '사고가 발생하였습니다. ',
    title_otp_request: '계정 삭제를 위해 전화번호를 인증해주세요.',
    label_mobile_number: '휴대폰 번호',
    label_code_number: '코드번호',
    btn_send: '보내기',
    btn_continue: '계속하기',
    error_verification_code: '인증 코드가 일치하지 않습니다. ',
    label_account_deletion: '계정 삭제',
    label_reason_delete_account: '계정 삭제 사유',
    hint_min_character: '최소 {{ }} 글자 ',
    btn_submit: '제출',
    dialog_account_deletion_desc: '지금부터 TADA 서비스를 이용하실 수 없습니다. ',
    apply_account_deletion_title: '귀하의 계정을 삭제하고 있습니다. ',
    apply_account_deletion_desc: '계정이 완벽히 삭제되는데 며칠의 시간이 소요됩니다. ',
    dialog_account_deletion_failure_title: '계정 삭제 실패',
    content_delete_account_error_balance_remaining:
      '계정 삭제를 위해 잔액은 0이어야 합니다. 남은 금액을 인출 후 재시도해주시고, 도움이 필요한 경우 CS 센터로 이메일을 보내주세요. ',
    content_delete_account_error_exist_rider_penalty_fee:
      '귀하의 계정에 패널티가 있어 계정 삭제를 진행할 수 없습니다. TADA CS 센터로 email을 보내주시면 계정 삭제를 도와드리겠습니다. ',
    content_delete_account_error_exist_active_ride:
      '귀하는 현재 진행 중인 예약 건이 있습니다. 예약 이후 혹은 예약 취소 후에 계적 삭제가 가능합니다. ',
    text_contact_description:
      '아시아 태평양 지역 전역에서 운영되는 저희는 싱가포르, 캄보디아, 태국, 베트남, 홍콩에 사무소를 두고 있으며, 언제든지 각 지점에서 도움을 드릴 준비가 되어 있습니다.',
    text_contact_empty_description: '지도에 있는 귀사의 오피스를 클릭하여 자세한 내용을 확인하세요. ',
    car_type_economy: '이코노미',
    car_type_economy_large: '이코노미 대형',
    car_type_premium: '프리미엄',
    car_type_premium_large: '프리미엄 대형',
    car_type_taxi: '스탠다드 택시 ',
    car_type_taxi_large: '스탠다드 택시 대형 ',
    car_type_premium_taxi: '프리미엄 택시 ',
    car_type_premium_taxi_large: '프리미엄 택시 대형 ',
    car_type_tuktuk: 'Tuk Tuk',
    car_type_bike: '오토바이 ',
    car_type_remorque: 'Remorque',
    car_type_delivery_van: 'Delivery Van',
    car_type_onion_tuktuk: 'E-Tuk Tuk',
    car_type_delivery_bike: 'Delivery Bike',
    product_type_ph: '개인',
    product_type_taxi: '택시 ',
    product_type_anytada: 'AnyTADA',
    product_type_ev: 'iONTADA',
    product_seat_count: '최대 {{seat}} 석',
    economy_cm: 'Car',
    economy_large_cm: 'SUV',
    car_type_bus: 'Bus',
    car_type_mini_bus: 'Mini Bus',
    ride_option_pet_carrier: 'TADA Pets (Carrier)',
    ride_option_pet_leashed: 'TADA Pets (Leashed) ',
    fare_type_app_metered_fare: 'App Metered',
    fare_type_car_metered_fare: 'Car Metered',
    fare_type_fixed_fare: 'Fixed',
    car_type_win_bike: 'Win Bike',
    car_type_premium_bike: 'TADA Bike Plus',
    car_junior_type: 'TADA Junior',
    car_junior_large_type: 'TADA Junior Large',
    car_wheelchair_space_type: 'Wheelchair Space',
  },
};
