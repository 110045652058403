import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
  box-sizing: border-box;
  padding: 13px 12px;
  height: 48px;
  width: 100%;

  font-size: 16px;
  font-weight: 600;
  border-radius: 12px;

  border: 1px solid #b3b3b3;
  outline: none;

  &::placeholder {
    color: #b3b3b3;
  }

  &:focus {
    border-color: #ffc400;
  }

  &:not(:placeholder-shown) {
    border-color: #000;
  }
`

function DeleteAccountInput(props) {
  return <Input {...props} />
}

export default DeleteAccountInput
