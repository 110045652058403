import React, { Component } from 'react'
import { AppBannerContainer, AppIcon, AppTitle } from '../../../AppBanner/index'
import car from '../../../../assets/images/tada/car@2x.png'
import appIcon from '../../../../assets/images/tada/app-icon-driver@2x.png'
import styled from 'styled-components'

const DriverAppBannerContainer = styled(AppBannerContainer)`
  width: 520px;
  &:after,
  &::after {
    position: absolute;
    background-image: url(${car});
    background-size: contain;
    background-repeat: no-repeat;
    top: 13px;
    right: 0;
    width: 76px;
    height: 82px;
    content: ' ';
  }

  @media (max-width: 1000px) {
    height: 250px;

    &:after,
    &::after {
      left: calc(50% + 100px);
      bottom: calc(50% + 11px);
      // left: 100px;
      // bottom: 61px;
      top: auto;
      height: 60px;
    }
  }
`

export default class DriverAppBanner extends Component {
  render () {
    return (
      <DriverAppBannerContainer>
        <AppIcon src={appIcon} />
        <AppTitle>
          TADA for
          <br />
          <span>DRIVER</span>
        </AppTitle>
      </DriverAppBannerContainer>
    )
  }
}
