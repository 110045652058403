import React, { useEffect, useState } from 'react'
import MobileWebViewContainer from '../../Common/MobileWebViewContainer'
import { useSearch } from '../../../hooks/router'
import { getSupportPageInfo } from './util'
import { decodeDescription } from '../../../utils/MyUtils'
import styled from 'styled-components'
import Loading from '../../Common/Loading'

const SupportPageDescription = styled.div`
  margin-bottom: 24px;
  padding: 0 24px;
  overflow-wrap: break-word;
`

function SupportPage() {
  const search = useSearch()
  const [contents, setContents] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const { region, city, appAuthority, locale } = search

        const content = await getSupportPageInfo(region, city, appAuthority, locale)

        setContents(content.contents)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [search])

  if (isLoading) {
    return <Loading />
  }

  return (
    <MobileWebViewContainer isNavigator={false} headerSize={0} isLoading={false}>
      {contents?.map(content => (
        <SupportPageDescription
          dangerouslySetInnerHTML={{ __html: decodeDescription(content.description) }}
          key={content.id}
        />
      ))}
    </MobileWebViewContainer>
  )
}

export default SupportPage
