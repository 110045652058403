import React, { Component } from 'react'
import styled from 'styled-components'
import { getLocale } from '../../selectors'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'

const AppInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;

  @media (max-width: 1000px) {
    padding: 0 17px;
  }
`

const AppInfoSection = styled.section`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  color: #0a3142;
  & + & {
    margin-top: 60px;
  }
`

const AppInfoTitle = styled.h3`
  margin: 0 0 15px;
  padding: 0;
  font-size: 28px;
  font-weight: bold;
  color: #0a3142;
`

export class AppInfo extends Component {
  render () {
    const { t, type } = this.props

    return (
      <AppInfoContainer>
        {[1, 2, 3, 4, 5, 6].map(index => (
          <AppInfoSection key={index}>
            <AppInfoTitle>{t(`tada.${type}.info.title_${index}`)}</AppInfoTitle>
            {t(`tada.${type}.info.content_${index}`)}
          </AppInfoSection>
        ))}
      </AppInfoContainer>
    )
  }
}

const mapStateToProps = state => ({
  selectedLocale: getLocale(state)
})

const enhance = compose(
  connect(mapStateToProps),
  translate()
)

export default enhance(AppInfo)
