import React from 'react'

function HelpCenterQuestionIcon () {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='8' cy='8' r='8' fill='url(#paint0_linear_34329_52998)' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7 5.33008L7.49902 10.416H8.58594L9.07812 5.33008V2.97852H7V5.33008ZM7.08203 11.0791V13H9.00293V11.0791H7.08203Z'
        fill='white'
      />
      <defs>
        <linearGradient id='paint0_linear_34329_52998' x1='-8' y1='8' x2='8' y2='24' gradientUnits='userSpaceOnUse'>
          <stop stop-color='#FFD74F' />
          <stop offset='1' stop-color='#FFC400' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default HelpCenterQuestionIcon
