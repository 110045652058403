import React from 'react'
import { useSearch } from '../../hooks/router'
import MobileWebViewContainer from '../Common/MobileWebViewContainer'
import { decodeDescription } from '../../utils/MyUtils'
import styled from 'styled-components'
import { Question } from './Question'
import { Answer } from './Answer'

const TopicContent = styled.div`
  padding: 0 24px;
  margin-bottom: 16px;
`

const QuestionWrapper = styled.div`
  & + & {
    margin-top: 28px;
  }
`

export function AliasWebview({ topicData, questionData, questionList }) {
  const search = useSearch()

  const topicContent = topicData?.contents.at(0)

  return (
    <MobileWebViewContainer isLoading={!topicData} isNavigator={false} isHiddenHeader>
      {/* aliasKey의 topic content */}
      {topicContent && topicContent.dataType === 'HTML' ? (
        <TopicContent
          dangerouslySetInnerHTML={{
            __html: decodeDescription(topicContent.description)
          }}
        />
      ) : (
        topicContent.description && <TopicContent>{topicContent.description}</TopicContent>
      )}
      {/* aliasKey의 동일한 questionId가 있는 경우 questionData로 하나만 노출, 없는 경우 동일한 topicId의 question list들을 노출  */}
      {search.questionId
        ? questionData?.contents.map(content => (
            <QuestionWrapper key={content.id}>
              <Question content={content.title} />
              <Answer content={content.description} type={content.dataType} />
            </QuestionWrapper>
          ))
        : questionList.map((content, contentIdx) => {
            const data = content.contents.at(0)

            return (
              <QuestionWrapper key={contentIdx}>
                <Question content={data.title} />
                <Answer content={data.description} type={data.dataType} />
              </QuestionWrapper>
            )
          })}
    </MobileWebViewContainer>
  )
}
