import { REGION } from '../../../constants/constants'

export const SERVICE_COUNTRY_CODES = [
  {
    name: 'Cambodia',
    number: '855',
    code: 'KH'
  },
  {
    name: 'Singapore',
    number: '65',
    code: 'SG'
  },
  {
    name: 'Vietnam',
    number: '84',
    code: 'VN'
  },
  {
    name: 'Thailand',
    number: '66',
    code: 'TH'
  }
]

export const DELETE_ACCOUNT_REASON_MIN_LENGTH = 5

export const DELETE_ACCOUNT_REASON_MAX_LENGTH = 200

export const REGION_BY_COUNTRY_CODE = {
  855: REGION.KH,
  65: REGION.SG,
  84: REGION.VN,
  66: REGION.TH
}
