import React from 'react'
import Responsive from 'react-responsive'
import { generateMedia } from 'styled-media-query'

export const media = generateMedia({
  xs: '320px',
  md: '1004px',
  lg: '1000px',
  hg: '1420px'
})

export const HugeDesktop = (props) => <Responsive {...props} minWidth={1421} />
export const HugeMobile = (props) => <Responsive {...props} maxWidth={1420} />
export const Desktop = (props) => <Responsive {...props} minWidth={1001} />
export const Mobile = (props) => <Responsive {...props} maxWidth={1000} />
export const MediumDesktop = (props) => <Responsive {...props} minWidth={1005} />
export const MediumMobile = (props) => <Responsive {...props} maxWidth={1004} />
