import { TOKEN_KEYS, USER_MODEL, USER_MODEL_BY_PAGE_SECTION } from '../../../constants/constants'
import { get } from '../../../utils/api'

export const getUserModelFromSection = section => USER_MODEL_BY_PAGE_SECTION[section] || USER_MODEL.Rider

export const fetchSafetyTopicByAlias = async (params, option) => {
  try {
    const result = await get(
      `/cssvc/v1/safetyAccident/topic/find-by-alias/${params}`,
      undefined,
      TOKEN_KEYS.faq,
      undefined,
      option
    )

    return result
  } catch (e) {
    return null
  }
}

export const fetchSafetyQuestionByAlias = async (params, option) => {
  try {
    const result = await get(
      `/cssvc/v1/safetyAccident/question/find-by-alias/${params}`,
      undefined,
      TOKEN_KEYS.faq,
      undefined,
      option
    )

    return result
  } catch (e) {
    return null
  }
}
