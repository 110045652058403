import { getUrlWithQuery, useSWRData } from '../../../utils/swr'

/*
interface ContactInfoListQuery {
  region: string; // SG, VN, KH...
  city?: string; // SIN, HAN, HCM...
  appAuthority: string; // RIDER_APP, DRIVER_APP, WEB_HOMEPAGE
  locale: string; // LocaleType
}

interface SupportPageDTO {
  id: number | null;
  region: REGION;
  city: CITY | null;
  appAuthority: APP_AUTHORITY
  enabled: boolean;
  contents: SupportPageContentDTO[];
  createdAt: string | null;
  updatedAt: string | null;
}

interface SupportPageContentDTO {
  id: string | null;
  locale: string; // LocaleType
  description: string;
}
*/

export const useContactInfoList = (query, skip) => {
  const url = getUrlWithQuery('/cssvc/supportpage/find', query)
  const { data, isLoading, mutate } = useSWRData(url, skip, {
    errorRetryCount: 0
  })

  return { data, isLoading, mutate }
}
