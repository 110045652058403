import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  min-height: 65px;
  border-bottom: 1px solid #dfe1e3;

  box-sizing: border-box;

  &:last-child {
    border: none;
  }

  > .help_center__row_content {
    word-break: break-all;
    font-size: 16px;
  }
`

function HelpCenterRow ({ topic }) {
  const history = useHistory()

  const handleClickRow = () => {
    history.push({
      pathname: `/help-center/${topic.id}`,
      state: {
        topic
      }
    })
  }

  return (
    <Row className='help_center__row' onClick={handleClickRow}>
      <p className='help_center__row_content'>{topic.contents[0].topicName}</p>
      <div>
        <svg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M0.700012 1L6.70001 7L0.700012 13' stroke='black' stroke-width='1.25' />
        </svg>
      </div>
    </Row>
  )
}

export default HelpCenterRow
