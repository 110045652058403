import React, { Component } from 'react'
import styled from 'styled-components'
import Link from '../Common/Link'
import A from '../Common/A'
import HeaderDropdown from './HeaderDropdown/index'
import HeaderDrawerMenu from './HeaderDrawerMenu/index'
import TadaLogo from '../../assets/images/tada/tada-header-logo@2x.png'
import { Desktop, Mobile } from '../Common/ByScreen'
import autoBind from 'react-autobind'
import { DOMAIN_TYPE, getDomainType } from '../../constants/constants'
import { translate } from 'react-i18next'

const HeaderContainer = styled.nav`
  position: fixed;
  z-index: 999;
  width: 100%;
  box-sizing: border-box;
  height: 120px;
  background-color: #fff;
  margin: 0;
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

  @media (max-width: 1000px) {
    height: 65px;
    top: ${props => (props.isHidden ? '-65px' : '0px')};
    transition: 250ms top ease-in-out;
    padding: 0 18px;
  }
`

const HeaderLogo = styled.img`
  display: block;
  max-width: 106px;
  max-height: 40px;
  width: auto;
  height: auto;

  @media (max-width: 1000px) {
    max-width: 72px;
    max-height: 24px;
  }
`

const HeaderUl = styled.ul`
  display: flex;
`

const HeaderLi = styled.li`
  display: inline-flex;
  align-items: center;
  & + & {
    margin-left: 50px;
  }
`

const HeaderLink = styled(Link)`
  color: #0a3142;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    color: #eaba28;
  }
`

const HeaderToMVL = styled(A)`
  color: #0a3142;
  font-size: 16px;
  font-weight: bold;
  margin-right: 30px;
  &:hover {
    color: #eaba28;
  }
  & > span {
    font-size: 12px;
    font-weight: 300;
  }
`

class Header extends Component {
  constructor (props) {
    super(props)
    autoBind(this)
    this.state = {
      isHidden: false,
      prevScrollY: 0
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll (event) {
    const scrollY = window.scrollY
    // console.log(scrollY)
    this.setState(prevState => ({
      isHidden: scrollY > prevState.prevScrollY && scrollY > 65,
      prevScrollY: scrollY
    }))
  }

  render () {
    const { isHidden } = this.state

    return (
      <HeaderContainer isHidden={isHidden}>
        <Link to={'/'}>
          <HeaderLogo src={TadaLogo} alt='TadaLogo' />
        </Link>
        <Desktop>
          <HeaderUl>
            {getDomainType() === DOMAIN_TYPE.TADA_DOMAIN && (
              <HeaderLi>
                <HeaderLink to={'/safety'}>{this.props.t('safety')}</HeaderLink>
              </HeaderLi>
            )}
            <HeaderLi>
              <HeaderLink to={'/'}>{this.props.t('title.about')}</HeaderLink>
            </HeaderLi>
            <HeaderLi>
              <HeaderLink to={'/app/driver'}>{this.props.t('title.driver')}</HeaderLink>
            </HeaderLi>
            <HeaderLi>
              <HeaderLink to={'/app/rider'}>{this.props.t('title.rider')}</HeaderLink>
            </HeaderLi>
            <HeaderLi>
              <HeaderLink to={'/contact'}>{this.props.t('title.contact')}</HeaderLink>
            </HeaderLi>
            <HeaderLi>
              <HeaderLink to={'/faq'}>{this.props.t('title.faq')}</HeaderLink>
            </HeaderLi>
            <HeaderLi>
              <HeaderToMVL
                target='_blank'
                href='https://mvlchain.io'
                dangerouslySetInnerHTML={{ __html: this.props.t('title.powerd_by_mvl') }}
              />
            </HeaderLi>
            {getDomainType() === DOMAIN_TYPE.TADA_DOMAIN && (
              <HeaderLi>
                <HeaderDropdown />
              </HeaderLi>
            )}
          </HeaderUl>
        </Desktop>
        <Mobile>
          <HeaderDrawerMenu />
        </Mobile>
      </HeaderContainer>
    )
  }
}

export default translate()(Header)
