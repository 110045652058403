import { SettingActions, SettingTypes } from './modules/settings/SettingState'
import { DriverActions, DriverTypes } from './modules/driver/DriverState'
import { DocumentActions, DocumentTypes } from './modules/driver_document/DriverDocumentState'
import { ContactActions, ContactTypes } from './modules/contact/ContactState'

export { DriverTypes, SettingTypes, DocumentTypes, ContactTypes }

export default {
  ...DriverActions,
  ...SettingActions,
  ...DocumentActions,
  ...ContactActions
}
