import i18n from 'i18next'
import _ from 'lodash'

import en from './en'
import ko from './ko'
import zhHans from './zh_hans'
import vi from './vi'
import km from './km'
import th from './th'
import zh_TW from './zh-TW'
import jp from './jp'
import { LOCALE_TYPE } from '../constants/constants'

const whichBackend = () => 'memory'
const options = {
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: '.',
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },
  react: {
    wait: true
  },
  resources: {}
}

const isDev = process.env.NODE_ENV === 'development'

if (isDev) {
  _.merge(options, {
    debug: true
  })
}

switch (whichBackend()) {
  case 'memory':
  default:
    options.resources = {
      ko,
      en,
      [LOCALE_TYPE.ZH_CN]: zhHans,
      [LOCALE_TYPE.ZH_TW]: zh_TW,
      vi,
      km,
      th,
      [LOCALE_TYPE.JP]: jp
    }
}

i18n.init(options)

export default i18n

export const LANGS = ['en', 'ko', LOCALE_TYPE.ZH_CN, LOCALE_TYPE.ZH_TW, 'vi', 'km', 'th', LOCALE_TYPE.JP]
