import MvlToast from '../components/Common/MvlToast'
import i18next from 'i18next'

/**
 * Rejects a promise after `ms` number of milliseconds, it is still pending
 */

export default function timeout (promise, ms) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      MvlToast(i18next.t('error_time_out'), 'error')
      reject(new Error('timeout'))
    }, ms)
    promise
      .then(response => {
        clearTimeout(timer)
        resolve(response)
      })
      .catch(reject)
  })
}
