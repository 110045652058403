import { SWRConfig } from 'swr'

import { fetcher } from './swr'

function SWRProvider({ children }) {
  return (
    <SWRConfig
      value={{
        fetcher,
        onError: err => {
          err && console.error(err)
        },
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
        refreshWhenOffline: true,
        refreshWhenHidden: true
      }}
    >
      {children}
    </SWRConfig>
  )
}

export default SWRProvider
