import { useSelector } from 'react-redux'
import DeleteAccountContainer from './parts/DeleteAccountContainer'
import DeleteAccountContent from './parts/DeleteAccountContent'
import { errorCodeSelector, phoneSelector } from '../../../modules/deleteAccount/selector'
import { translate } from 'react-i18next'
import DeleteAccountCSCenter from './parts/DeleteAccountCSCenter'

function DeleteAccountError({ t }) {
  const errorCode = useSelector(errorCodeSelector)

  return (
    <DeleteAccountContainer title={t('dialog_account_deletion_failure_title')} showNavigation>
      <DeleteAccountContent>{t(`content_delete_account_error_${errorCode}`)}</DeleteAccountContent>
      <DeleteAccountCSCenter />
    </DeleteAccountContainer>
  )
}

export default translate()(DeleteAccountError)
