import React from 'react'
import { getCarImage, getCarName } from './utils'
import { translate } from 'react-i18next'
import { FARE_TYPE_STRINGS } from '../../../constants/constants';

function CarInformation({ item, t }) {
  return (
    <div className="ride_product__car_info">
      <div className='ride_product__car_info_category'>
        {t(FARE_TYPE_STRINGS[item.product.fareType])}
      </div>
      {item.content.image ? (
        <img src={item.content.image} className="ride_product__car_info_marketing_image" />
      ) : (
        <img src={getCarImage(item.product)} className="ride_product__car_info_image" />
      )}
      <h2 className="ride_product__car_info_car_type">{t(getCarName(item.product))}</h2>
      <p className="ride_product__car_info_person">{t('product_seat_count', { seat: item.product.seatCount })}</p>
    </div>
  )
}

export default translate()(CarInformation)
