import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { translate } from 'react-i18next'
import { Mobile } from '../Common/ByScreen'
import noticeIcon from '../../assets/images/tada/notice@2x.png'
import xIcon from '../../assets/images/tada/banner-x-big.png'
import Button from '../Common/Button'
// import bannerImg from '../../assets/images/tada/Group@2x.png'

const BannerBehinder = styled.div`
  width: 100%;
  height: 150px;

  @media (max-width: 1260px) {
    z-index: 1000;
    position: fixed;
    top: -50%;
    left: 0;
    height: 200%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`

const BannerContainer = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  height: 150px;
  background-color: #face0c;
  display: flex;
  flex-direction: column;
  align-content: center;
  z-index: 1500;

  @media (max-width: 1260px) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 15px;
    width: 321px;
    height: 365px;
  }
`

const BannerInner = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 1140px;
  height: 150px;
  font-weight: bold;
  color: #000;
  text-align: center;
  background-repeat: no-repeat;
  background-position: 0 center;
  background-image: url(${noticeIcon});
  background-size: 96px 56px;

  @media (max-width: 1260px) {
    width: 100%;
    height: 100%;
    background-position: center 20%;
    // background-size: 80px;
  }
`

const BannerTitle = styled.h2`
  margin: 0;
  font-size: 24px;
  position: absolute;
  text-align: left;
  left: 120px;
  bottom: 50px;
  width: 900px;
  // bottom: 10px;

  @media (max-width: 1260px) {
    // margin: 50% 40px 0;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px 0;
    box-sizing: border-box;
    left: 0;
    top: 148px;
    bottom: auto;
    // font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    // color: #5d4600;

    font-size: 18px;
    color: #032b3a;
  }
`

const BannerBtn = styled(Button)`
  position: absolute;
  width: 200px;
  height: 50px;
  top: 50px;
  line-height: 50px;
  background-color: #032b3a;
  left: 45%;
  border-radius: 42px;
  color: #fff;

  @media (max-width: 1260px) {
    background-color: #face0c;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 15px 15px;
    border-top: solid 1px #e5e5e5;

    :hover {
      background-color: #032b3a;
    }
  }
`

const CloseBtn = styled(Button)`
  position: absolute;
  top: 50px;
  right: 0;
  content: ' ';
  width: 50px;
  height: 56px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${xIcon});

  @media (max-width: 1260px) {
    top: 18px;
    right: 18px;
    width: 18px;
    height: 18px;
  }
`

export const BannerImg = styled.img`
  position: absolute;
  // top: 0;
  bottom: 0;
  right: 40px;
  max-height: 200px;
  z-index: -1;

  @media (max-width: 1260px) {
    bottom: 50px;
    right: auto;
    max-width: 100%;
  }
`

export class Banner extends Component {
  render () {
    const { onCloseBanner } = this.props

    return (
      <Fragment>
        <BannerBehinder />
        <BannerContainer>
          <BannerInner>
            <BannerTitle>
              Please be informed that the credit card validation process deducts 1 USD from your registered credit card.
              This will be refunded back to your account immediately upon successful verification of your credit card.
            </BannerTitle>
            {/* <BannerDesc>Currently, there is an issue with searching the destination on the IOS TADA app. It will be fixed and updated soon! We apoligize for the inconvenience again.</BannerDesc> */}
            {/* <BannerDesc>MVL's First On-Demand Service.</BannerDesc> */}
            {/* <BannerBtn href="https://tada.global">Discover more</BannerBtn> */}
            <Mobile>
              <BannerBtn onClick={onCloseBanner}>OK</BannerBtn>
            </Mobile>
            <CloseBtn onClick={onCloseBanner} />
            {/* <BannerImg src={bannerImg} /> */}
          </BannerInner>
        </BannerContainer>
      </Fragment>
    )
  }
}

const enhance = compose(translate())

export default enhance(Banner)
