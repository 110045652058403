import qs from 'qs'
import useSWR, { useSWRConfig } from 'swr'

import { get } from './api'
import { TOKEN_KEYS } from '../constants/constants'

export const getUrlWithQuery = (url, query) => `${url}?${query ? qs.stringify(query, { arrayFormat: 'repeat' }) : ''}`

export const useSWRData = (url, skip, config) => {
  const swrResponse = useSWR(() => (!skip ? url : null), config)

  return swrResponse
}

export const useSWRCache = url => {
  const { cache } = useSWRConfig()

  const data = cache.get(url)

  return { data }
}

export const fetcher = async url => {
  const res = await get(url, undefined, TOKEN_KEYS.faq)

  return res
}

export const formatSWRData = (data, error) => ({
  data,
  format: {
    totalCount: data?.data.totalElements ?? 0,
    data: data?.data.content ?? [],
    page: {
      total: data?.data.totalPages ?? 1,
      current: (data?.data.number ?? 0) + 1,
      limit: data?.data.size ?? 20
    }
  },
  isLoading: !error && !data
})
