import { translate } from 'react-i18next'
import DeleteAccountContainer from './parts/DeleteAccountContainer'
import DeleteAccountContent from './parts/DeleteAccountContent'

function DeleteAccountSuccess({ t }) {
  return (
    <DeleteAccountContainer title={t('apply_account_deletion_title')} showNavigation>
      <DeleteAccountContent>{t('apply_account_deletion_desc')}</DeleteAccountContent>
    </DeleteAccountContainer>
  )
}

export default translate()(DeleteAccountSuccess)
