import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import DocumentPageContainer from '../../Common/DocumentPageContainer'
import { translate } from 'react-i18next'
import ContactInfo from './parts/ContactInfo'
import { useContactInfoList } from './hooks'
import { APP_AUTHORITY, REGION, SUPPORT_REGION_POLYGON_PATHS, SUPPORT_REGIONS } from '../../../constants/constants'
import { ContactPolygonMap } from './parts/ContactPolygonMap'
import { getCurrentRegion } from '../../../utils/location'

const ContactContainer = styled.div`
  height: 100%;
  padding-bottom: 224px;

  @media (max-width: 1000px) {
    padding-bottom: 40px;
  }

  > .contact-wrapper {
    display: flex;
    flex-direction: column;

    > .contact-description {
      margin-top: 14px;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      color: #0a3142;

      @media (max-width: 1000px) {
        display: flex;
        height: 100%;
        min-height: 200px;
        margin: 0 -18px;
        padding: 0 18px;
        text-align: center;
        z-index: 1;
        background: linear-gradient(180deg, #f7f7f7 68%, rgba(247, 247, 247, 0) 100%);
      }
    }
    > .contact-body {
      display: flex;
      flex-direction: row;
      margin-top: 44px;

      @media (max-width: 1000px) {
        display: flex;
        width: 100vw;
        margin-left: -19px;
        margin-top: -30px;
        flex-direction: column;
      }
    }
  }
`

const TadaContactPageTitle = styled.h1`
  padding: 0;
  font-size: 72px;
  font-weight: bold;
  color: #4a4a4a;

  @media (max-width: 1000px) {
    margin-bottom: 41px;
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    color: #0a3142;
  }
`

function Contact({ t, i18n: { language } }) {
  const [regionId, setRegionId] = useState(null)

  const regionPolygon = SUPPORT_REGION_POLYGON_PATHS.find(poly => poly.regionIdx === regionId)
  const query = {
    region: regionPolygon?.region ?? REGION.SG,
    appAuthority: APP_AUTHORITY.WEB_HOMEPAGE,
    locale: language
  }
  const skip = !query.region || !query.appAuthority || !query.locale || regionId === null

  const { data, isLoading } = useContactInfoList(query, skip)

  const handleChangeRegion = idx => {
    setRegionId(idx)
  }

  useEffect(() => {
    const setInitialRegion = async () => {
      const region = await getCurrentRegion()

      if (!region) {
        return
      }

      const regionIdx = SUPPORT_REGIONS.findIndex(supportRegion => supportRegion === region)
      setRegionId(regionIdx)
    }

    setInitialRegion()
  }, [])

  return (
    <DocumentPageContainer>
      <ContactContainer>
        <TadaContactPageTitle>{t('tada.contact.contact_us')}</TadaContactPageTitle>
        <div className="contact-wrapper">
          <div className="contact-description">{t('text_contact_description')}</div>
          <div className="contact-body">
            <ContactPolygonMap regionId={regionId} onChangeRegion={handleChangeRegion} />
            <ContactInfo data={data} regionId={regionId} isLoading={isLoading} onChangeRegion={handleChangeRegion} />
          </div>
        </div>
      </ContactContainer>
    </DocumentPageContainer>
  )
}

export default translate()(Contact)
