import React, { Component } from 'react'
import Link from '../../Common/Link'
import BrandingPageContainer, { BrandingPageContent } from '../../Common/BrandingPageContainer'
import AppInfo from '../../AppInfo/index'
import DriverAppBanner from './DriverAppBanner/index'
import AppDownload from '../../AppDownload/index'
import AppCarousel from '../../AppCarousel/index'
import { compose } from 'recompose'
import { translate } from 'react-i18next'
import { getDriver, getLocale } from '../../../selectors'
import triangle from '../../../assets/images/tada/triangle.png'
import vIconWhite from '../../../assets/images/tada/v_white.png'
import { connect } from 'react-redux'
import styled from 'styled-components'

export const DriveWithTadaLink = styled(Link)`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #0a3142;
  border-radius: 30px;
  border: solid 2px #0a3142;
  margin-bottom: 15px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.45);
    border-color: #4a4a4a;
    color: #fff;

    &:after,
    &::after {
      background-image: url(${vIconWhite});
    }
  }
  &:after,
  &::after {
    background-image: url(${triangle});
    content: ' ';
    position: absolute;
    right: 14px;
    top: 20px;
    width: 17px;
    height: 10px;
    transform: rotate(270deg);
  }
  @media (max-width: 1000px) {
    border-width: 1px;
  }
`
export class TadaDriverPage extends Component {
  render () {
    return (
      <BrandingPageContainer isColored>
        <BrandingPageContent>
          <DriverAppBanner />
          <AppDownload
            iosDownloadLink={'https://itunes.apple.com/app/tada-driver/id1414948666?mt=8'}
            androidDownloadLink={'https://play.google.com/store/apps/details?id=io.mvlchain.tada.driver'}
            // androidApkDownloadLink={'https://files.tada.global/apk/tada-driver-2.2.5-self.apk'}
            isDriver
          />
          <AppCarousel type='driver' />
          <AppInfo type='driver' />
        </BrandingPageContent>
      </BrandingPageContainer>
    )
  }
}

const mapStateToProps = state => ({
  driver: getDriver(state),
  selectedLocale: getLocale(state)
})

const enhance = compose(
  translate(),
  connect(mapStateToProps)
)

export default enhance(TadaDriverPage)
